import { Modal } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QrReader } from 'react-qr-reader';
import './QRCodeScanner.less';

interface Props {
  title: string;
  visible: boolean;
  onComplete: (values: any) => void;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const QRCodeScanner = ({ title, visible, onComplete, setVisible }: Props) =>{
  const intl = useIntl();
  const [scanDelay, setScanDelay] = useState<any>(500);

  const onCancel = () => {
    setScanDelay(false);
    setVisible(false);
  }

  const onScanComplete = (text: any) => {
    onCancel();
    onComplete(text);
  }

  return (
    <Modal
      open={visible}
      title={title} 
      onCancel={onCancel}
      bodyStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
      footer={null}
      cancelText={intl.formatMessage({ id: "BTN_CANCEL" })}>
        <QrReader
          scanDelay={scanDelay}
          onResult={(result: any, error: any) => {
            if (!!result) {
              onScanComplete(result?.text);
            }
            if (!!error) {
              //console.log('#error', error);
            }
          }}
          className='qr-code-container'
          // videoContainerStyle={{ borderRadius: '30px', border: '1px solid' }}
          // containerStyle={{ width: "200px", heigth: "200px"}}
          constraints={{ facingMode: 'environment' }}
        />
        <span><FormattedMessage id="NO_QR_CODE_DETECTED" /></span>
      </Modal>
    );
}

export default QRCodeScanner;
import { FormattedMessage, useIntl } from "react-intl";
import "./Topup.less";
import { Alert, Button, Card, Form } from 'antd';
import { ArrowDownOutlined, InfoCircleFilled, MinusOutlined, PlusOutlined, WalletOutlined } from "@ant-design/icons";
import { NumberFormatter } from "../../../../shared/helpers/number-format.helper";
import { useLazyQuery, useQuery } from "@apollo/client";
import { SET_CURRENT_USER, useAuth } from "../../../../hooks/auth/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NumericInput from "../../../../shared/components/numeric-input";
import { GET_CONFIGURATION } from "../../../../shared/gql/configuration.gql";
import PageHeader from "../../../../shared/components/page-header/PageHeader";
import useNotification from "../../../../hooks/layout/useNotification";
import { GET_USER_PROFILE } from "../../../../shared/gql/profile.gql";

const Topup = () => {
    // const numRegex = new RegExp(/^([1-9]\d{3,}(\.?[0-9]?[0-9]?)?)$/);
    // Temporary changed to minimum 2 digits
    const intl = useIntl();
    const navigate = useNavigate();
    const { authState, authDispatch } = useAuth();
    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState<string>();
    const { setErrorNotification } = useNotification();
    const [topUpAmount, setTopUpAmount] = useState<any>();
    const [minTopUpAmount, setMinTopUpAmount] = useState(100);
    const [cashWalletBal, setCashWalletBal] = useState(0);
    const [cardWalletBal, setCardWalletBal] = useState(0);
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const [getUserProfile, { data, called, refetch }] = useLazyQuery(
        GET_USER_PROFILE, { fetchPolicy: "no-cache", errorPolicy: "all", }
      );
    const [topUpSelections, setTopUpSelections] = useState<number[]>([]);
    const [exportAccMinTrxAmount, setExportAccMinTrxAmount] = useState(60);
    const { data: configurationData } = useQuery(GET_CONFIGURATION, {
        fetchPolicy: "cache-and-network", variables: {
            id: "burse_min_top_up_amount"
        }
    });

    const { data: topUpSelectionsData } = useQuery(GET_CONFIGURATION, {
        fetchPolicy: "cache-and-network", variables: {
            id: "burse_top_up_amount_selections"
        }
    });

    const { data: exportAccMinTrxData } = useQuery(GET_CONFIGURATION, {
        fetchPolicy: "cache-and-network", variables: {
            id: "burse_export_acc_min_trx"
        }
    });

    useEffect(() => {
        if (data) {
          authDispatch({ type: SET_CURRENT_USER, payload: data });
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data]);

    useEffect(() => {
        if (topUpSelectionsData && topUpSelectionsData.configuration && topUpSelectionsData.configuration.value) {
            let selectionsArray = topUpSelectionsData.configuration.value.split(",");
            if (selectionsArray.length > 0) {
                setTopUpSelections(selectionsArray.map((val: any) => {
                    return parseInt(val);
                }))
                form.setFieldValue('topup_amount', Number(+selectionsArray[0]));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topUpSelectionsData])

    useEffect(() => {
        if (configurationData && configurationData.configuration && configurationData.configuration.value) {
            setMinTopUpAmount(+configurationData.configuration.value);
        }
    }, [configurationData])

    useEffect(() => {
        if (exportAccMinTrxData && exportAccMinTrxData.configuration && exportAccMinTrxData.configuration.value) {
            setExportAccMinTrxAmount(+exportAccMinTrxData.configuration.value);
        }
    }, [exportAccMinTrxData])

    useEffect(() => {
        if (authState.cardAccount) {
            setCardWalletBal(authState.cardAccount?.totalCardBalance ?? 0)
        }
        if (authState.walletAccount) {
            setCashWalletBal(authState.walletAccount?.totalWalletBalance ?? 0)
        }
    }, [authState.cardAccount, authState.walletAccount]);

    const patchValue = (amount: any) => {
        let currentValue = form.getFieldValue('topup_amount');

        if (!currentValue) {
            currentValue = 0;
        }

        if (amount > 100) {
            form.setFieldValue('topup_amount', Number(amount));
        }

        if (amount === 100) {
            const patchValue = Number(currentValue) + 100;
            form.setFieldValue('topup_amount', Number(patchValue));
        } else if (amount < 100 && currentValue > minTopUpAmount) {
            const patchValue = Number(currentValue) - 100;
            form.setFieldValue('topup_amount', Number(patchValue));
        }
        form.validateFields()
    };

    const setTopUpFieldsValue = (amount: any) => {
        form.setFieldValue('topup_amount', Number(amount));
    }

    const onFinish = () => {
        if(authState && authState.userProfile?.isExported === true && authState.userProfile.trxBalance < exportAccMinTrxAmount){
            setErrorNotification(intl.formatMessage({id:'EXPORT_ACC_INSUFFICIENT_TRX'},{trxAmount:exportAccMinTrxAmount}));
        }else{
            const currentValue = form.getFieldValue('topup_amount');
            if (!!!currentValue) {
                setErrorMessage(intl.formatMessage({ id: 'AMOUNT_REQUIRED' }))
            } else if (+currentValue < minTopUpAmount) {
                setErrorMessage(intl.formatMessage({ id: 'INVALID_AMOUNT' }, { minTopUpAmount }))
            }
            else {
                navigate("/card/confirmation", { state: { currentValue, cashWalletBal, cardWalletBal } })
            }
        }
    };

    const breadcrumbs = [
        {
            text: <FormattedMessage id="CARD" />,
            path: "/card/overview",
        },
        {
            text: <FormattedMessage id="RELOAD" />,
        },
    ];

    const reloadBalance = () => {
        setRefreshDisabled(true);
        if(called){ 
            refetch() 
        }
        else{
            getUserProfile();
        }
        setTimeout(() => {
            setRefreshDisabled(false);
        }, 5000);
    };


    return (
        <>
            <PageHeader breadcrumbs={breadcrumbs} ></PageHeader>
            <div className="topup-info-container">
                <Card className="rule-box disclaimer-box">
                    <div className="disclaimer-title">
                        <InfoCircleFilled className="rule-icon" /> <FormattedMessage id="DISCLAIMER" />
                    </div>
                    <div className="disclaimer-content">
                        <FormattedMessage id="TOPUP_DISCLAIMER" />
                        {
                                authState && authState.userProfile?.isExported === true &&
                                <>
                                <br/>
                                <FormattedMessage id="EXPORT_ACC_MIN_TRX" values={{trxAmount:exportAccMinTrxAmount}} />
                                </>
                        }
                    </div>
                </Card>
                <div className="form-box">
                    <div className="title-bar">
                        <h2><FormattedMessage id="RELOAD" /></h2>
                        <Button type="primary" onClick={reloadBalance} loading={refreshDisabled} style={{ backgroundColor: '#13C2C2' }}
                        disabled={refreshDisabled}><FormattedMessage id="REFRESH" /></Button>
                    </div>
                    <Card hoverable size="small" className="wallet-card">
                        <div style={{ marginRight: 15, fontSize: 20 }}>
                            <ArrowDownOutlined />
                        </div>
                        <div style={{ width: '100%' }}>
                            <h4><FormattedMessage id="FROM_EWALLET" /></h4>
                            <div className="wallet-desc">
                                <span><FormattedMessage id="AVAILABLE_BAL" /></span>
                                <span>{NumberFormatter(cashWalletBal, 'en-EN', 'USD')}</span>
                            </div>
                            {
                                authState && authState.userProfile?.isExported === true &&
                                <div className="wallet-desc">
                                    <span><FormattedMessage id="TRX_BALANCE" /></span>
                                    <span>{authState.userProfile.trxBalance+ " "}{intl.formatMessage({ id: "TRX" })}</span>
                                </div>
                            }
                        </div>
                    </Card>
                    <Card hoverable size="small" className="wallet-card">
                        <div style={{ marginRight: 15, fontSize: 20 }}>
                            <WalletOutlined />
                        </div>
                        <div style={{ width: '100%' }}>
                            <h4><FormattedMessage id="TO_CARD" /></h4>
                            <div className="wallet-desc">
                                <span><FormattedMessage id="AVAILABLE_BAL" /></span>
                                <span>{NumberFormatter(cardWalletBal, 'en-EN', 'USD')}</span>
                            </div>
                        </div>
                    </Card>
                    <div>
                        <h2><FormattedMessage id="TOP_UP_AMT" /></h2>
                    </div>
                    <div>
                        <Form
                            form={form}
                            className="topup-input"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            {errorMessage && <Alert style={{ marginBottom: 15 }} message={errorMessage} type="error" showIcon />}
                            <div className="amount-input">
                                <Button onClick={() => patchValue(-100)} type="default" shape="circle" icon={<MinusOutlined />} />
                                <Form.Item
                                    name="topup_amount"
                                >
                                    <NumericInput style={{ textAlign: 'center', width: "80%" }} value={topUpAmount} onChange={setTopUpAmount} />
                                </Form.Item>
                                <Button onClick={() => patchValue(100)} type="default" shape="circle" icon={<PlusOutlined />} />
                            </div>
                            <div className="flex-top-up-buttons">
                                {topUpSelections && topUpSelections.length > 0 ? topUpSelections.map((val) => {
                                    return <Button key={val} className="top-up-button" onClick={() => setTopUpFieldsValue(val)} type="default">
                                        {NumberFormatter(val ?? 0, 'en-EN', 'USD')}
                                    </Button>
                                }) : null
                                }
                            </div>
                            <Button className="action-button" type="primary" block htmlType="submit">
                                <div><FormattedMessage id="CONTINUE" /></div>
                            </Button>
                        </Form>
                    </div>

                </div>
            </div>
        </>
    )
};

export default Topup
import { Form, InputNumber, Button, Alert } from "antd";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
    onFinish: (values: any) => void;
    countryPrefix: any;
    error: string;
};

const SignUpMobile = ({ onFinish, countryPrefix, error }: Props) => {
    const intl = useIntl();

    return (
        <>
            <div className="auth-container auth-signup">
                <div className="auth-title">
                    <FormattedMessage id="SIGNUP_TITLE" />
                    <div className="sm-title">
                        <FormattedMessage id="SIGNUP_SUBTITLE" />
                    </div>
                </div>
                <Form
                    name="sign_up"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                     {error ? (
                        <Alert
                            className="login-error"
                            message={intl.formatMessage({ id: `${error}` })}
                            type="error"
                        ></Alert>
                    ) : null}
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: "VALIDATION_REQUIRED" }),
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder={intl.formatMessage({ id: "PHONE" })} addonBefore={countryPrefix} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                        >
                            <FormattedMessage id="BTN_NEXT" />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
};

export default SignUpMobile
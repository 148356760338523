import { Form, Button, Input, Select } from "antd";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
    userKyc: any;
    editable: boolean;
    current: number;
    setCurrent: Dispatch<SetStateAction<any>>;
    kycInfo: any;
    setKycInfo: Dispatch<SetStateAction<any>>;
};

const KYCEmploymentDetails = ({ userKyc, editable, current, setCurrent, kycInfo, setKycInfo }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    const employmentStatusOptions = [
        { label: intl.formatMessage({ id: 'KYC_EMPLOYED' }), value: "EMPLOYED" },
        { label: intl.formatMessage({ id: 'KYC_SELF_EMPLOYED' }), value: "SELF_EMPLOYED" },
        // { label: "Unemployed", value: "UNEMPLOYED" },
        // { label: "Student", value: "STUDENT" },
        { label: intl.formatMessage({ id: 'KYC_RETIRED' }), value: "RETIRED" },
    ];

    const natureOfBusinessOptions = [
        { label: intl.formatMessage({ id: 'KYC_SOLE_PROPRIETORSHIP' }), value: "SOLE_PROPRIETORSHIP" },
        { label: intl.formatMessage({ id: 'KYC_PARTNERSHIP' }), value: "PARTNERSHIP" },
        { label: intl.formatMessage({ id: 'KYC_PRIVATE_LIMITED_COMPANY' }), value: "PRIVATE_LIMITED_COMPANY" },
        { label: intl.formatMessage({ id: 'KYC_PUBLIC_LIMITED_COMPANY' }), value: "PUBLIC_LIMITED_COMPANY" },
        { label: intl.formatMessage({ id: 'KYC_UNLIMITED_COMPANIES' }), value: "UNLIMITED_COMPANIES" },
        { label: intl.formatMessage({ id: 'KYC_FOREIGN_COMPANY' }), value: "FOREIGN_COMPANY" },
        { label: intl.formatMessage({ id: 'KYC_LIMITED_LIABILITY_PARTNERSHIP' }), value: "LIMITED_LIABILITY_PARTNERSHIP" },
    ];

    const annualIncomeOptions = [
        { label: "< 10,000", value: "< 10,000" },
        { label: "10,000 - 25,000", value: "10,000 - 25,000" },
        { label: "25,001 - 50,000", value: "25,001 - 50,000" },
        { label: "50,001 - 100,000", value: "50,001 - 100,000" },
        { label: "100,001 - 150,000", value: "100,001 - 150,000" },
        { label: "150,001 - 200,000", value: "150,001 - 200,000" },
        { label: "> 200,000", value: "> 200,000" },
    ];

    const onFinish = (values: any) => {
        setKycInfo({
            ...kycInfo,
            employmentStatus: values.employmentStatus,
            companyName: values.companyName,
            jobTitle: values.jobTitle,
            natureOfBusiness: values.natureOfBusiness,
            annualIncome: values.annualIncome,
        })

        if (values.buttonClicked === 'back') {
            setCurrent(current - 1);
        } else if (values.buttonClicked === 'next') {
            form.validateFields().then(() => {
                setCurrent(current + 1);
            }).catch((errorInfo: any) => {
            });
        }
    }

    useEffect(() => {
        if (userKyc && !kycInfo.employmentStatus) {
            const formValue = {
                employmentStatus: userKyc.employmentStatus,
                companyName: userKyc.companyName,
                jobTitle: userKyc.jobTitle,
                natureOfBusiness: userKyc.natureOfBusiness,
                annualIncome: userKyc.annualIncome,
            }
            form.setFieldsValue(formValue);
            handleEmploymentStatusChange(userKyc.employmentStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userKyc]);

    useEffect(() => {
        if (current === 1 && kycInfo && kycInfo.employmentStatus) {
            const formValue = {
                employmentStatus: kycInfo.employmentStatus ? kycInfo.employmentStatus : 'EMPLOYED',
                companyName: kycInfo.companyName,
                jobTitle: kycInfo.jobTitle,
                natureOfBusiness: kycInfo.natureOfBusiness,
                annualIncome: kycInfo.annualIncome,
            }
            form.setFieldsValue(formValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    const handleEmploymentStatusChange = (value: any) => {
        // Conditionally hide/show the companyName, jobTitle, natureOfBusiness field based on the employment status
        if (value === 'SELF_EMPLOYED' || value === 'RETIRED') {
            form.setFieldsValue({ companyName: null, jobTitle: null, natureOfBusiness: null }); // Reset the field value when hidden
            setKycInfo({ ...kycInfo, employVisible: false });
        } else {
            setKycInfo({ ...kycInfo, employVisible: true });
        }

    };


    return (
        <>
            <Form
                initialValues={{ employmentStatus: 'EMPLOYED' }}
                onFinish={onFinish}
                name="kyc-employment-details"
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    name="employmentStatus"
                    label={intl.formatMessage({ id: "KYC_EMPLOYMENT_STATUS" })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) }]}  >
                    <Select
                        options={employmentStatusOptions}
                        placeholder={intl.formatMessage({ id: "KYC_EMPSTAT_PH" })}
                        onChange={handleEmploymentStatusChange}
                    />
                </Form.Item>

                {kycInfo && kycInfo.employVisible && (
                    <>
                        <Form.Item
                            name="companyName"
                            label={intl.formatMessage({ id: "KYC_COMPANY_NAME" })}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) }]}  >
                            <Input
                                disabled={!editable} id="companyName" placeholder={intl.formatMessage({ id: "KYC_COMPANY_NAME" })}></Input>
                        </Form.Item>
                        <Form.Item
                            name="jobTitle"
                            label={intl.formatMessage({ id: "KYC_JOB_TITLE" })}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) }]}  >
                            <Input
                                disabled={!editable} id="jobTitle" placeholder={intl.formatMessage({ id: "KYC_JOB_TITLE" })}></Input>
                        </Form.Item>
                        <Form.Item
                            name="natureOfBusiness"
                            label={intl.formatMessage({ id: "KYC_NATURE_OF_BUSINESS" })}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) }]}  >
                            <Select
                                options={natureOfBusinessOptions}
                                placeholder={intl.formatMessage({ id: "KYC_NATURAL_BUSSINESS_PH" })}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    name="annualIncome"
                    label={intl.formatMessage({ id: "KYC_ANNUAL_INCOME" })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) }]}  >
                    <Select
                        options={annualIncomeOptions}
                        placeholder={intl.formatMessage({ id: "KYC_ANNUAL_INCOME_PH" })}
                    />
                </Form.Item>

                <Form.Item>
                    <Button className={"btn-back"} type={"default"}
                        onClick={() => onFinish({ ...form.getFieldsValue(), buttonClicked: 'back' })}
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="BTN_BACK" />
                    </Button>
                    <Button disabled={!editable} className={"form-button"} type={"primary"}
                        onClick={() => onFinish({ ...form.getFieldsValue(), buttonClicked: 'next' })}
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="BTN_NEXT" />
                    </Button>
                </Form.Item>

            </Form>
        </>
    )
};

export default KYCEmploymentDetails
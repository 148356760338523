import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import "./NewsDetails.less";
import { GET_NEWS_DTL } from "../news.gql";
import { useLayout } from "../../../hooks/layout/LayoutContext";

const NewsDetails = () => {
    const { layoutState } = useLayout();
    const navigate = useNavigate();
    const { id } = useParams();
    const [news, setNews] = useState<any>();
    const { data } = useQuery(GET_NEWS_DTL, {
        fetchPolicy: "cache-and-network",
        variables: {
            newsId: id
        }
    });

    useEffect(() => {
        if (data && data.newsDetails) {
            const newsData = {
                id: data.newsDetails.id,
                title: JSON.parse(data.newsDetails.title),
                content: JSON.parse(data.newsDetails.content),
                pictureUrl: data.newsDetails.pictureUrl,
                createdAt: data.newsDetails.createdAt,
            }

            setNews(newsData);
        }
    }, [data]);

    const navBack = () => {
        navigate("/");
    };

    return (
        <>
            {
                news ?
                    <div className="page-scroll news-container">
                        <div className="news-cover">
                            <img className="news-img" alt="news" src={news['pictureUrl']} />
                            <Button className="news-btn" icon={<ArrowLeftOutlined />} onClick={navBack} />
                        </div>
                        <div className="news-content">
                            <div className="news-header">
                                <div className="news-title">
                                    {news['title'][layoutState.locale ?? 'en']}
                                </div>
                                <div className="news-subtitle">
                                    <div><FormattedMessage id="POSTED_ON" /> {format(new Date(news['createdAt']), "dd MMM yyyy")}</div>
                                </div>
                            </div>
                            <div className="news-desc">
                                {news['content'][layoutState.locale ?? 'en']}
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    );
};

export default NewsDetails;
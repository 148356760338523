import { gql } from "@apollo/client";

export const GET_ALL_COUNTRIES = gql`
  query Countries($queryInput: CountriesQueryInput!) {
    countries(queryInput: $queryInput) {
      id
      name
      dialCode
    }
  }
`;

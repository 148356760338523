import { FormattedMessage } from "react-intl";
import "./Deposit.less";
import { Card, Input, QRCode, Tooltip, Button } from 'antd';
import { NumberFormatter } from "../../../../shared/helpers/number-format.helper";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../../hooks/auth/AuthContext";
import { useEffect, useState } from "react";
import { GET_WALLET_BALANCE } from "../CashWallet.gql";
import { CopyOutlined, InfoCircleFilled } from "@ant-design/icons"
import PageHeader from "../../../../shared/components/page-header/PageHeader";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Deposit = () => {
    const { authState } = useAuth();
    const [usdtAdd, setUsdtAdd] = useState('');
    const [walletBal, setWalletBal] = useState(0);
    const { data: walletBalanceData } = useQuery(GET_WALLET_BALANCE, {
        fetchPolicy: "cache-and-network", variables: {
            id: authState.userProfile?.id
        }
    });

    useEffect(() => {
        if (walletBalanceData && walletBalanceData.walletBalance) {
            setWalletBal(walletBalanceData?.walletBalance?.balance)
            setUsdtAdd(walletBalanceData?.walletBalance?.usdtAddress)
        }
    }, [walletBalanceData]);

    const breadcrumbs = [
        {
            text: <FormattedMessage id="E_WALLET" />,
            path: "/e-wallet/overview",
        },
        {
            text: <FormattedMessage id="RECEIVE" />,
        },
    ];

    return (
        <>
            <PageHeader breadcrumbs={breadcrumbs} ></PageHeader>
            <div className="content-container deposit-container">
                <Card className="rule-box disclaimer-box">
                    <div className="disclaimer-title">
                        <InfoCircleFilled className="rule-icon" /> <FormattedMessage id="DISCLAIMER" />
                    </div>
                    <div className="disclaimer-content">
                        <FormattedMessage id="DEPOSIT_DISCLAIMER" />
                    </div>
                </Card>
                <div className="form-box">
                    <div>
                        <h2><FormattedMessage id="RECEIVE" /></h2>
                    </div>
                    <Card size="small" className="balance-info-box">
                        <div>
                            <h4><FormattedMessage id="TO" /> <FormattedMessage id="E_WALLET" /></h4>
                            <div className="wallet-desc">
                                <span><FormattedMessage id="AVAILABLE_BAL" /></span>
                                <span>{NumberFormatter(walletBal, 'en-EN', 'USD')}</span>
                            </div>
                        </div>
                    </Card>
                    <div>
                        <h2><FormattedMessage id="WALLET_ADDRESS" /></h2>
                    </div>
                    <Card size="small">
                        <Card.Grid className="grid-card">
                            {usdtAdd && <QRCode
                                errorLevel="H"
                                value={usdtAdd}
                            />}
                        </Card.Grid>
                        <Card.Grid className="grid-card">
                            <Input.Group compact>
                                <Input
                                    readOnly
                                    style={{ width: '85%' }}
                                    value={usdtAdd}
                                />
                                <Tooltip title={<FormattedMessage id="COPY" />}>
                                    <CopyToClipboard text={usdtAdd} >
                                        <Button icon={<CopyOutlined />} />
                                    </CopyToClipboard>
                                </Tooltip>
                            </Input.Group>
                        </Card.Grid>
                    </Card>
                </div>
            </div>
        </>
    )
};

export default Deposit
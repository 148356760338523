import { gql } from "@apollo/client";

export const GET_ANNOUNCEMENT = gql`
query Announcement{
    announcement {
      id
      message
      displayType
    }
  }
`;
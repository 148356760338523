import { Form, Button, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
    userKyc: any;
    editable: boolean;
    current: number;
    setCurrent: Dispatch<SetStateAction<any>>;
    kycInfo: any;
    setKycInfo: Dispatch<SetStateAction<any>>;
};

const KYCPersonalParticulars = ({ userKyc, editable, current, setCurrent, kycInfo, setKycInfo }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        setKycInfo({
            ...kycInfo,
            name: values.name,
            passportNo: values.passportNo,
            kycCategory: "PASSPORT",
            dateOfBirth: new Date(dayjs(values.dob).startOf('day').toString()),
            address1: values.address1,
            address2: values.address2 ?? "",
            state: values.state,
            city: values.city,
            postcode: values.postcode,
            employNext: true,
        })
        setCurrent(current + 1)
    }

    useEffect(() => {
        if (userKyc && !kycInfo.name) {
            const formValue = {
                name: userKyc.name,
                dob: dayjs(userKyc.dateOfBirth),
                passportNo: userKyc.passportNo,
                category: userKyc.kycCategory,
                address1: userKyc.address1,
                address2: userKyc.address2,
                city: userKyc.city,
                postcode: userKyc.postcode,
                state: userKyc.state,
            }
            form.setFieldsValue(formValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userKyc]);

    useEffect(() => {
        if (current === 0 && kycInfo && kycInfo.name) {
            const formValue = {
                name: kycInfo.name,
                dob: kycInfo.dateOfBirth ? dayjs(kycInfo.dateOfBirth) : '',
                passportNo: kycInfo.passportNo,
                category: kycInfo.kycCategory ? kycInfo.kycCategory : "PASSPORT",
                address1: kycInfo.address1,
                address2: kycInfo.address2,
                city: kycInfo.city,
                postcode: kycInfo.postcode,
                state: kycInfo.state,
            }
            form.setFieldsValue(formValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, kycInfo]);


    return (
        <>
            <Form
                initialValues={{ category: 'PASSPORT' }}
                onFinish={onFinish}
                name="kyc-personal-particulars"
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    name="category"
                    label={intl.formatMessage({ id: "KYC_CATEGORY" })}   >
                    <Input
                        disabled={true} id="category"></Input>
                </Form.Item>
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) }]}
                    label={intl.formatMessage({ id: "KYC_FULLNAME_LABEL" })}   >
                    <Input
                        disabled={!editable} id="name" placeholder={intl.formatMessage({ id: "KYC_FULLNAME_PH" })}></Input>
                </Form.Item>
                <Form.Item
                    name="dob"
                    rules={[{ required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) }]}
                    label={intl.formatMessage({ id: "KYC_DATE_OF_BIRTH_LABEL" })}   >
                    <DatePicker disabled={!editable} format={"YYYY-MM-DD"} placeholder={intl.formatMessage({ id: "KYC_DATE_PH" })}/>
                </Form.Item>
                <Form.Item
                    name="passportNo"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) },
                        { pattern: new RegExp(/^[a-zA-Z0-9]+$/), message: intl.formatMessage({ id: 'PASSPORT_NO_INVALID' }) }
                    ]}
                    label={intl.formatMessage({ id: "PASSPORT_NO" })}>
                    <Input disabled={!editable} id="passport-no" placeholder={intl.formatMessage({ id: "KYC_PASSPORT_PH" })}></Input>
                </Form.Item>
                <Form.Item
                    name="address1"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) },
                    ]}
                    label={intl.formatMessage({ id: "KYC_ADDRESS_ONE_LABEL" })}>
                    <Input disabled={!editable} className="address-text-field" id="address-1" placeholder={intl.formatMessage({ id: "KYC_ADDR1_PH" })} />
                </Form.Item>
                <Form.Item
                    name="address2"
                    label={intl.formatMessage({ id: "KYC_ADDRESS_TWO_LABEL" })}>
                    <Input disabled={!editable} className="address-text-field" id="address-2" placeholder={intl.formatMessage({ id: "KYC_ADDRESS_TWO_LABEL" })}></Input>
                </Form.Item>
                <Form.Item
                    name="city"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) },
                    ]}
                    label={intl.formatMessage({ id: "KYC_CITY_LABEL" })}>
                    <Input disabled={!editable} className="address-text-field" id="city" placeholder={intl.formatMessage({ id: "KYC_CITY_LABEL" })}></Input>
                </Form.Item>
                <Form.Item
                    name="postcode"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) },
                    ]}
                    label={intl.formatMessage({ id: "KYC_POSTCODE_LABEL" })}>
                    <Input disabled={!editable} className="address-text-field" id="postcode" placeholder={intl.formatMessage({ id: "KYC_POSTCODE_LABEL" })}></Input>
                </Form.Item>
                <Form.Item
                    name="state"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) },
                    ]}
                    label={intl.formatMessage({ id: "KYC_STATE_LABEL" })}>
                    <Input disabled={!editable} className="address-text-field" id="state" placeholder={intl.formatMessage({ id: "KYC_STATE_LABEL" })}></Input>
                </Form.Item>
                <Form.Item>
                    <Button disabled={true} className={"btn-back"} type={"default"}
                        onClick={() => setCurrent(current - 1)}
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="BTN_BACK" />
                    </Button>
                    <Button disabled={!editable} className={"form-button"} type={"primary"} htmlType="submit"
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="BTN_NEXT" />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
};

export default KYCPersonalParticulars
import React from "react";

export interface AnonymousRouteInfo {
    name: string,
    path: string,
    component: any
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages

const ResetPasswordSuccess = ReactLazyPreload(() => import("./pages/auth/ResetPasswordSuccess"));
ResetPasswordSuccess.preload();

export const anonymousRoutes: AnonymousRouteInfo[] = [

    {
        name: 'ResetPasswordSuccess',
        path: '/auth/reset-password-success',
        component: ResetPasswordSuccess
    },
];
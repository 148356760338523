import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getAllTokenCookie = () => {
  const allCookies = cookies.getAll();
  return allCookies;
};

export const setTokenCookie = (key: string, value: string) => {
  const isProd = process.env.NODE_ENV === "production";
  cookies.set(key, value, {
    // maxAge:
    //   key === (process.env.REACT_APP_ACCESS_TOKEN || "bf-at") ? 3600 : 18000, //refresh token keep for a week
    maxAge: 1800,
    domain: isProd ? process.env.REACT_APP_COOKIE_DOMAIN : "localhost",
    secure: isProd ? true : undefined,
    sameSite: isProd ? "lax" : undefined,
    path: "/",
  });
};

export const clearAllTokens = () => {
  cookies.remove(
    process.env.REACT_APP_ACCESS_TOKEN || "bf-at",
    removeCookieToptions()
  );
  cookies.remove(
    process.env.REACT_APP_REFRESH_TOKEN || "bf-rt",
    removeCookieToptions()
  );
  cookies.remove("bf-sid", removeCookieToptions());
  clearCooldownRequest();
};

const clearCooldownRequest = () => {
  cookies.remove(`c-cooldown-change-pin`, removeCookieToptions());
  cookies.remove(`c-cooldown-email-verify`, removeCookieToptions());
}

export const removeCookieToptions = () => {
  const isProd = process.env.NODE_ENV === "production";
  return {
    expires: new Date(Date.now() + 500),
    domain: isProd ? process.env.REACT_APP_COOKIE_DOMAIN : "localhost",
    secure: isProd ? true : undefined,
    path: "/",
  };
};

import { FormattedMessage, useIntl } from "react-intl";
import "./MyProfile.less"
import { useEffect, useState } from 'react';
import { Upload, Form, Input, Button, DatePicker } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { GET_MY_PROFILE, UPDATE_PROFILE } from "./MyProfile.gql";
import useNotification from "../../../hooks/layout/useNotification";
import imageCompression from 'browser-image-compression';
import { forEach } from "lodash";
import moment from "moment";
import { UploadFile } from "antd/lib/upload/interface";
import PreviewImage from "./PreviewImage";
import { UPDATE_CURRENT_USER_PROFILE, useAuth } from "../../../hooks/auth/AuthContext";
import { format, endOfDay } from "date-fns";
import dayjs from "dayjs";
import TextArea from "rc-textarea";
import EmailVerifyModal from "../../layouts/email-verify-modal/EmailVerifyModal";
import { useLocation } from "react-router-dom";

const MyProfile = () => {
  const { authState, authDispatch } = useAuth();
  const [avatarImg, setAvatarImg] = useState<UploadFile<any>[]>([]);
  const intl = useIntl();;
  const { setSuccessNotification, setErrorNotification } = useNotification();
  const [updateProfile, { loading, data: updateProfileData }] = useMutation(UPDATE_PROFILE, { refetchQueries: [{ query: GET_MY_PROFILE }] });
  const { data } = useQuery(GET_MY_PROFILE, { fetchPolicy: "cache-and-network" });
  const [form] = Form.useForm();
  const [displayImg, setDisplayImg] = useState({
    previewVisible: false, previewImage: '', previewTitle: ''
  });
  const [visible, setVisible] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const state = location.state || {};
    const shouldShowEmailVerifyModal = state.showVerifyModal === true;
    if (shouldShowEmailVerifyModal) {
      setVisible(true);
    }
  }, [location.state]);

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setDisplayImg({
      previewVisible: true,
      previewImage: file.url || file.preview,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    });
  }

  const normFile = async (e: any) => {
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 768,
      useWebWorker: true
    }
    if (e.fileList.length > 0) {
      const compressed = await imageCompression(e.fileList[0].originFileObj, options);
      e.fileList[0].originFileObj = compressed;
    }

    setAvatarImg(e.fileList)
    return e && e.fileList;
  };

  useEffect(() => {
    if (updateProfileData && updateProfileData.editUserProfile && updateProfileData.editUserProfile.status === "SUCCESS") {
      authDispatch({
        type: UPDATE_CURRENT_USER_PROFILE,
        payload: {
          userProfile: { ...authState.userProfile }
        }
      });
      setSuccessNotification(intl.formatMessage({ id: "SUCCESSFULLY_UPDATED_PROFILE" }));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileData]);

  useEffect(() => {
    if (data && data.userProfile) {
      const _userData = data ? forEach((data.userProfile), (val: any) => {
        if (val) {
          return {
            ...val,
            key: val?.id,
          }
        }
      }) : [];

      const userData = {
        name: _userData?.name,
        email: _userData?.email,
        mobileNo: _userData?.phone,
        passportNo: _userData?.passportNo,
        address: _userData?.address
      }

      if (_userData && _userData?.dateOfBirth) {
        form.setFieldsValue({
          dob: dayjs(_userData?.dateOfBirth)
        })
      }
      if (_userData && _userData?.photoUrl) {
        setAvatarImg([{
          uid: '-1',
          name: 'avatar.png',
          status: 'done',
          url: `${_userData?.photoUrl}?t=${moment().valueOf()}`,
        }]);
      }
      form.setFieldsValue(userData);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  const onFinish = (value: any) => {
    const editUserProfileInput: any = {
      name: value.name
    }

    if (value.dob) {
      editUserProfileInput.dateOfBirth = moment(format(endOfDay(new Date(value.dob)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")).toDate()
    }

    if (avatarImg.length > 0 && avatarImg[0].originFileObj) {
      editUserProfileInput.imageFile = avatarImg[0].originFileObj
    }

    updateProfile({
      variables: {
        editUserProfileInput
      }
    })
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        <FormattedMessage id="UPLOAD_BUTTON" />
      </div>
    </div>
  );


  const checkFileType = (e: any) => {
    if (e.type === 'image/jpeg' || e.type === 'image/png' || e.type === 'application/pdf') {
      return false;
    }
    setErrorNotification(`Only .jpg, .jpeg, .png and .pdf file format are accepted!`);
    return Upload.LIST_IGNORE;
  }

  return (
    <>
      <div className="outlet-wrap">
        <div className="title-header-container">
          <div className="title"><FormattedMessage id="MY_PROFILE" /></div>
        </div>
        <div className="content-container">
          <div className="profile-container">
            <div className="form-box-large">
              <div>
                <h2><FormattedMessage id="MY_PROFILE" /></h2>
              </div>
              <div className="subtitle">
                <FormattedMessage id="MY_PROFILE_SUBTITLE" />
              </div>
              <div className="profile-content-container">
                <Form
                  onFinish={onFinish}
                  name="my-profile"
                  layout="vertical"
                  // labelCol={{ span: 12 }}
                  // wrapperCol={{ span: 12 }}
                  form={form}
                >
                  <div className="avatar-container">
                    <div className="title-avatar"><FormattedMessage id="PROFILE_AVATAR" /></div>
                    <Form.Item getValueFromEvent={normFile} name="avatarUrl">
                      <Upload fileList={avatarImg} name="logo" action="" listType='picture-circle' maxCount={1} beforeUpload={checkFileType}
                        onPreview={handlePreview}>
                        {avatarImg.length === 1 ? null : uploadButton}
                      </Upload>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="name"
                    label={intl.formatMessage({ id: "FULL_NAME_AS_PASSPORT" })}>
                    <Input disabled={true} id="name"></Input>
                  </Form.Item>
                  <Form.Item
                    name={"email"}
                    label={intl.formatMessage({ id: "EMAIL_ADDRESS" })}
                    rules={[{ type: "email", message: intl.formatMessage({ id: "VALID_EMAIL" }) }]}>
                    <Input disabled={true} id="email" addonAfter={authState.userProfile?.emailVerified ?
                      <CheckCircleOutlined style={{ color: 'green' }} /> : <Button size="small" className="verify-button" onClick={() => setVisible(true)}><FormattedMessage id="VERIFY_NOW" /></Button>}></Input>
                  </Form.Item>
                  <Form.Item
                    name={"mobileNo"}
                    label={intl.formatMessage({ id: "PHONE" })}
                    rules={[
                      { min: 8, message: intl.formatMessage({ id: "MIN_PHONE_LENGTH" }) }
                    ]}>
                    <Input disabled={true} id="mobile" addonAfter={authState.userProfile?.phoneVerified ?
                      <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}>
                    </Input>
                  </Form.Item>
                  {authState.userProfile?.kycApproved ?
                    <>
                      <Form.Item
                        name={"passportNo"}
                        label={intl.formatMessage({ id: "PASSPORT_NO" })}>
                        <Input disabled={true} id="passportNo"></Input>
                      </Form.Item>
                      <Form.Item
                        name={"address"}
                        label={intl.formatMessage({ id: "KYC_RESIDENTIAL_ADDRESS_LABEL" })}>
                        <TextArea disabled={true} rows={6} id="address" className="text-area"></TextArea>
                      </Form.Item>
                    </> : null
                  }
                  {authState.userProfile?.uplineName && authState.userProfile?.uplinePhone ?
                    <Form.Item
                      label={intl.formatMessage({ id: "AGENT_NAME" })}>
                    <Input disabled={true} id="upline-phone" value={authState.userProfile?.uplineName}></Input>
                    </Form.Item>
                    : null
                  }
                  <Form.Item
                    name={"dob"}
                    label={intl.formatMessage({ id: "DOB" })}>
                    <DatePicker />
                  </Form.Item>
                  <Form.Item>
                    <Button loading={loading} className="form-button" type={"primary"} htmlType="submit"><FormattedMessage id="UPDATE" /></Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviewImage displayImg={displayImg} setDisplayImg={setDisplayImg} />

      <EmailVerifyModal
        visible={visible}
        setVisible={setVisible}
        title={intl.formatMessage({ id: "VERIFY_EMAIL" })}
        content={intl.formatMessage({ id: "VERIFY_EMAIL_CONTENT" })} />
    </>
  );
}

export default MyProfile;

import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
mutation EditUserProfile($editUserProfileInput: EditUserProfileInput){
    editUserProfile(editUserProfileInput:$editUserProfileInput){
        status
    }
}`

export const GET_MY_PROFILE = gql`
query UserProfile {
    userProfile {
        id, 
        name,
        photoUrl,
        phone,
        email,
        dateOfBirth,
        phoneVerified,
        emailVerified
        passportNo
        address1
        address2
        state
        city
        postcode,
        address
    }
}`
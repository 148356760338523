import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import OtpInput from "react-otp-input";
import './OTP.less';

interface Props {
    handleOTP: (otp: any) => void;
    resendOTP: () => void;
    isOTPError: any;
    OTP: any;
    isResendDisabled: any;
}

const RequestOTP = ({ handleOTP, resendOTP, isOTPError, OTP, isResendDisabled }: Props) => {
    return (
        <>
            <div className="otp-box">
                <OtpInput className="otp-input"
                    errorStyle={
                        {
                            borderColor: '#EE4B2B'
                        }
                    }
                    hasErrored={isOTPError}
                    value={OTP}
                    onChange={handleOTP}
                    numInputs={6}
                    separator={<span>-</span>}
                    isInputNum={true}
                />
                <div className="otp-help-box">
                    <div><FormattedMessage id="OTP_HELP"></FormattedMessage>
                    </div>
                    <div>
                        <Button onClick={resendOTP} disabled={isResendDisabled} type="link">
                            <FormattedMessage id="OTP_RESEND"></FormattedMessage>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RequestOTP;
import { CopyOutlined } from "@ant-design/icons";
import { Button, Card, Col, Popover, Row, Statistic, Skeleton } from "antd";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Lock from '../../../assets/icons/lock.svg';
import CashWalletBG from '../../../assets/images/cash-wallet-bg.svg';
import NoCardBG from '../../../assets/images/default-no-card.svg';
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { NumberFormatter } from "../../helpers/number-format.helper";
import { useNavigate } from "react-router-dom";
import "./WalletCard.less";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
    type: String;
    cardData?: any;
    isLockCard?: boolean;
    isSkeleton?: boolean;
};

const WalletCard = ({ type, cardData, isLockCard, isSkeleton }: Props) => {
    const defaultCardSize = {
        button: { left: 250, top: 15 },
        validThru: { left: 180, top: 155 },
        cardNumber: { left: 30, top: 120 },
        cardName: { left: 30, top: 155 },
    };
    const smallCardSize = {
        button: { left: 180, top: 10 },
        validThru: { left: 130, top: 113 },
        cardNumber: { left: 20, top: 87 },
        cardName: { left: 20, top: 113 },
    };
    const emptyCardText = {
        text: { left: 30, top: 135 }
    }

    const [backgroundImg, setBackgroundImg] = useState<any>();
    const [fullCardNumber, setFullCardNumber] = useState<any>();
    const [cardNumber, setCardNumber] = useState<any>();
    const { layoutState } = useLayout();
    const navigate = useNavigate();

    useEffect(() => {
        if (type === 'empty') {
            setBackgroundImg(NoCardBG);
        }
        if (type === 'cash') {
            setBackgroundImg(CashWalletBG);
        }
        if (type === 'card') {
            const cardValue = cardData.cardNumber
                .replace(/\D/g, "")
                .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
            let showNumber;
            let fullNumber;
            if (cardValue) {
                showNumber = `****  ****  ****  ${cardValue[4]}`;
                fullNumber = `${cardValue[1]} ${cardValue[2]} ${cardValue[3]} ${cardValue[4]}`;
                setCardNumber(showNumber);
                setFullCardNumber(fullNumber);
            }
            setBackgroundImg(cardData.cardTemplateUrl);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const copy = (e: any, value: any) => {
        e.stopPropagation();
    };

    const content = (
        <>
            {
                type === 'card' ?
                    <div key={cardData.id} id={cardData.id}>
                        <Row>
                            <Col span={24}>
                                <span style={{ fontSize: 14 }}>{fullCardNumber}</span>
                                <CopyToClipboard text={cardData.cardNumber} >
                                    <Button type="text" style={{ marginLeft: 5 }} icon={<CopyOutlined />} onClick={(evt: any) => copy(evt, cardData.cardNumber)} />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Statistic className="card-info" title={<FormattedMessage id="EXPIRY_DATE" />} value={cardData.validThru} />
                            </Col>
                            <Col span={12}>
                                <Statistic className="card-info" title={<FormattedMessage id="CVC2" />} value={cardData.cvv} />
                            </Col>
                        </Row>
                    </div>
                    : null
            }

        </>
    );

    // const position = (evt: any) => {
    //     console.log('x', evt.nativeEvent.layerX);
    //     console.log('y', evt.nativeEvent.layerY);
    // }

    return (
        <>
            <div className="wallet-card-container" key={type === 'card' ? cardData.id : type === 'cash' ? cardData.walletId : 1} id={type === 'card' ? cardData.id : type === 'cash' ? cardData.walletId : 1}>
                {/* Cash Wallet Layout */}
                {
                    type === 'cash' ?
                        <Card
                            onClick={() => {
                                if (window.location.pathname !== "/e-wallet/overview") {
                                    navigate("/e-wallet/overview")
                                }
                            }}
                            bordered={false}
                            hoverable
                            key={cardData.walletId}
                            id={cardData.walletId}
                            className='wallet-card-background'
                            style={{ backgroundImage: `url(${backgroundImg})` }}
                        >

                            <div className="card-content">
                                <div className="upper-content">
                                    <div className="emphasis-font">
                                        <FormattedMessage id="E_WALLET" />
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <div className="upper-box">
                                        <div className="small-font"><FormattedMessage id="RECENT_TXN" /></div>
                                        <hr className="card-hr" />
                                    </div>
                                    <div className="lower-box">
                                        <div className="balance-box">
                                            {
                                                cardData.lastTxnDate && cardData.lastTxnType ?
                                                    <>
                                                        <div className="focus-font">{cardData.lastTxnType ? <FormattedMessage id={cardData.lastTxnType} /> : ''}</div>
                                                        <div className="small-font">{format(new Date(cardData.lastTxnDate) ?? new Date(), "yyyy-MM-dd")}</div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="small-font">-</div>
                                                    </>}
                                        </div>
                                        <div className="focus-font">
                                            {NumberFormatter(cardData.amount ?? 0, 'en-EN', 'USD')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Card>
                        :
                        type === 'card' ?
                            <>
                                {isLockCard ? <div className="lock-card-wrapper">
                                    <div className="lock-card-icon-wrapper">
                                        <img alt="lock-card" src={Lock} />
                                    </div>
                                    <FormattedMessage id="CARD_LOCKED" />
                                </div> : null
                                }
                                <Card
                                    onClick={() => {
                                        if (window.location.pathname !== "/card/overview" && !isLockCard) {
                                            navigate("/card/overview")
                                        }
                                    }}
                                    bordered={false}
                                    hoverable
                                    key={cardData.id}
                                    id={cardData.id}
                                    className={isLockCard ?
                                        'visa-card-container-blur' : 'visa-card-container'}
                                    style={{ backgroundImage: `url(${backgroundImg})` }}
                                >
                                    <div className="visa-card-content">
                                        <Popover content={content} trigger="click">
                                            <Button onClick={(evt: any) => evt.stopPropagation()} style={{ position: 'absolute', left: layoutState.dimension?.width <= 360 ? smallCardSize.button['left'] : defaultCardSize.button['left'], top: layoutState.dimension?.width <= 360 ? smallCardSize.button['top'] : defaultCardSize.button['top'], color: 'white' }} type="text">
                                                <FormattedMessage id="VIEW_INFO" />
                                            </Button>
                                        </Popover>
                                        <span style={{ position: 'absolute', left: layoutState.dimension?.width <= 360 ? smallCardSize.validThru['left'] : defaultCardSize.validThru['left'], top: layoutState.dimension?.width <= 360 ? smallCardSize.validThru['top'] : defaultCardSize.validThru['top'], color: 'white' }}>
                                            {cardData.validThru}
                                        </span>
                                        <span style={{ position: 'absolute', left: layoutState.dimension?.width <= 360 ? smallCardSize.cardNumber['left'] : defaultCardSize.cardNumber['left'], top: layoutState.dimension?.width <= 360 ? smallCardSize.cardNumber['top'] : defaultCardSize.cardNumber['top'], color: 'white' }}>
                                            {cardNumber}
                                        </span>
                                        <span className="name-break" style={{
                                            position: 'absolute', left: layoutState.dimension?.width <= 360 ? smallCardSize.cardName['left'] : defaultCardSize.cardName['left'], top: layoutState.dimension?.width <= 360 ? smallCardSize.cardName['top'] : defaultCardSize.cardName['top'],
                                            color: 'white', width: 110, display: '-webkit-box', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'
                                        }}>
                                            {cardData.cardName}
                                        </span>
                                    </div>
                                </Card>
                            </>
                            :
                            <>
                                <Card
                                    bordered={false}
                                    hoverable
                                    className='no-card-container'
                                    style={{ backgroundImage: `url(${backgroundImg})`, marginTop: 15 }}
                                >
                                    <div className="no-card-content">
                                        <span className="name-break" style={{
                                            position: 'absolute', left: layoutState.dimension?.width <= 360 ? emptyCardText.text['left'] - 15 : emptyCardText.text['left'], top: layoutState.dimension?.width <= 360 ? emptyCardText.text['top'] - 35 : emptyCardText.text['top'],
                                            color: 'black', fontWeight: 700, width: layoutState.dimension?.width <= 360 ? 210 : 300, display: '-webkit-box', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'
                                        }}>
                                            <Skeleton loading={isSkeleton} active={isSkeleton} paragraph={false} className="card-skele">
                                                <FormattedMessage id="EMPTY_CARD_TITLE" />
                                            </Skeleton>
                                        </span>
                                    </div>
                                </Card>
                            </>
                }

            </div>
        </>
    )
};

export default WalletCard
import Marquee from "react-fast-marquee";
import "./Announcements.less";
import iconAnnouncement from '../../../assets/images/icon-announcement.png';
import { setDBLanguageToGet } from "../../../shared/helpers/language.helper";
import { useLayout } from "../../../hooks/layout/LayoutContext";

const MarqueeAnnouncements = ({ marqueeData }: any) => {
    const { layoutState } = useLayout();

    return (
        <div className="marquee-elements">
            <img alt={"announcement-icon"} className="announcement-icon" src={iconAnnouncement} />
            <Marquee
                speed={40}
                pauseOnHover
            >
                {
                    marqueeData.map((marquee: any) => {
                        return <div key={marquee.id} className="home-marquee-content" onClick={() => { }}>
                            {marquee.message[setDBLanguageToGet(layoutState.locale)]}
                        </div>
                    })
                }
            </Marquee>
        </div>

    )
}

export default MarqueeAnnouncements;
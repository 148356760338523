import { FormattedMessage, useIntl } from "react-intl";
import "./TopupConfirmation.less";
import { Button, Divider, Statistic } from 'antd';
import { NumberFormatter } from "../../../../shared/helpers/number-format.helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CARD_TOP_UP } from "../Card.gql";
import useNotification from "../../../../hooks/layout/useNotification";
import { CheckCircleTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { GET_CONFIGURATION } from "../../../../shared/gql/configuration.gql";
import { useAuth } from "../../../../hooks/auth/AuthContext";
import { GET_ESTIMATED_GAS_FEE } from "../../../../shared/gql/estimated-gas-fee.gql";

const TopupConfirmation = () => {
    const { authState } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const { state }: any = location;
    const intl = useIntl();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [topUp, { data: topUpRet, error: topUpError, loading: topUpLoading }] = useMutation(CARD_TOP_UP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [topUpFlag, setTopUpFlag] = useState<boolean>(false);
    const { data: configurationData } = useQuery(GET_CONFIGURATION, {
        fetchPolicy: "cache-and-network", variables: {
            id: "burse_service_charges"
        }
    });
    const [getEstimatedGasFee, { data, loading }]= useLazyQuery(GET_ESTIMATED_GAS_FEE, { fetchPolicy: "no-cache", errorPolicy: "all", });
    const [topUpData, setTopUpData] = useState<any>();
    const excludeTopUpCharges = authState && authState.userProfile && authState.userProfile?.VipMember && authState.userProfile.VipMember?.excludeTopUpCharges;
    const [serviceChargePercentage, setServiceChargePercentage]= useState<number>(0);
    const [estimatedGasFee, setEstimatedGasFee]= useState<number>();

    useEffect(() => {
        if (state) {
            setTopUpData(state);
            getEstimatedGasFee({
                variables: {
                    queryInput:{
                        amount:state?.currentValue 
                    }
                }
            })
        } else {
            navigate("/card/top-up");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(()=>{
        if(data && data.estimatedGasFee && data.estimatedGasFee.estimatedGasFee){
            setEstimatedGasFee(+data.estimatedGasFee.estimatedGasFee);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    useEffect(()=>{
        if(configurationData && configurationData.configuration && configurationData.configuration.value){
            setServiceChargePercentage(+configurationData.configuration.value);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    },[configurationData]);

    useEffect(() => {
        if (topUpRet && topUpRet.cardTopUp && topUpRet.cardTopUp.status === "SUCCESS") {
            setSuccessNotification(intl.formatMessage({ id: 'SUCCESSFULLY_TOP_UP' }));
            setTopUpFlag(true);
        } else if (topUpError) {
            console.log("[Top Up Error]", topUpError);
            setErrorNotification(intl.formatMessage({ id: 'TOP_UP_FAILED' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topUpRet, topUpError]);

    const topUpFunc = () => {
        topUp(
            {
                variables: {
                    source: "UTGL",
                    amount: +topUpData?.currentValue,
                },
            }
        );
    };

    const navigateFunc = ()=>{
        navigate("/card/overview");
    }

    return (
        <>
            <div className="topup-info-container">
                <div className="form-box">
                    <div className="top-up-title">
                        <h2><FormattedMessage id= {topUpFlag  ? "SUCCESSFUL":"CONFIRMATION" }/> </h2>
                        {topUpFlag && <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor="#52c41a"/>}
                    </div>
                    <div>
                        <h4><FormattedMessage id="DETAILS" /></h4>
                        <Statistic title={<FormattedMessage id="TOP_UP_AMT" />} style={{fontWeight:'bold',margin:0}} value={NumberFormatter(topUpData?.currentValue, 'en-EN', 'USD')}/>
                        <Statistic title={<FormattedMessage id="PROCESSING_FEE" />} value={NumberFormatter(excludeTopUpCharges ? 0 : topUpData?.currentValue * serviceChargePercentage, 'en-EN', 'USD')} />
                        <Statistic title={<FormattedMessage id="NETT_AMOUNT_TO_CARD" />} value={NumberFormatter(excludeTopUpCharges ? topUpData?.currentValue : topUpData?.currentValue - (topUpData?.currentValue * serviceChargePercentage), 'en-EN', 'USD')} />
                        { authState && authState.userProfile?.isExported && <Statistic title={<FormattedMessage id="ESTIMATED_GAS_FEE" />} value={estimatedGasFee} suffix={"TRX"} precision={6} loading={loading} className="trx-statistic"/>}
                    </div>
                    <Divider dashed style={{ borderColor: '#D3D3D3', borderWidth: '2px 0 0' }} />
                    <div className="transfer-info-wrap">
                        <span>{!topUpFlag && <FormattedMessage id="FROM" />}</span>
                        <div className="transfer-info-right">
                            <span><FormattedMessage id="E_WALLET" /></span>
                            <span style={{ marginTop: 10 }}><FormattedMessage id="BALANCE" />: {NumberFormatter(topUpFlag ? +topUpData?.cashWalletBal - (topUpData?.currentValue): topUpData?.cashWalletBal, 'en-EN', 'USD')}</span>
                        </div>
                    </div>
                    <div className="transfer-info-wrap" style={{ marginTop: 20 }}>
                        <span>{!topUpFlag && <FormattedMessage id="TO" />}</span>
                        <div className="transfer-info-right">
                            <span><FormattedMessage id="CARD" /></span>
                            <span style={{ marginTop: 10 }}><FormattedMessage id="BALANCE" />: {NumberFormatter(topUpFlag ? +topUpData?.cardWalletBal + (topUpData?.currentValue - (topUpData?.currentValue * serviceChargePercentage)): topUpData?.cardWalletBal,'en-EN', 'USD')}</span>
                        </div>
                    </div>
                    <Divider dashed style={{ borderColor: '#D3D3D3', borderWidth: '2px 0 0' }} />
                    {!topUpFlag &&  <h4 style={{marginBottom:15}}><InfoCircleOutlined /> <FormattedMessage id="SERVICE_CHARGE_INFO" values={{serviceChargePercentage:(serviceChargePercentage * 100).toFixed(2)}}/></h4>}
                    <Button loading={topUpLoading} className="action-button" type="primary" block onClick={topUpFlag ? navigateFunc: topUpFunc}>
                        <div><FormattedMessage id={topUpFlag ? "BTN_DONE": "BTN_CONFIRM"} /></div>
                    </Button>

                </div>
            </div>
        </>
    )
};

export default TopupConfirmation
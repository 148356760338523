import { gql } from "@apollo/client";

export const LOGOUT = gql`
  mutation LOGOUT {
    logout {
      status
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn($signInInput: signInInput!) {
    signIn(signInInput: $signInInput) {
      a,
      r,
      sid,
    }
  }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($userName: String!, $newPassword: String!, $confirmNewPassword: String!){
        resetPassword(userName: $userName, newPassword: $newPassword, confirmNewPassword: $confirmNewPassword){
            status
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($userName: String!){
        forgotPassword(userName: $userName){
            status
        }
    }
`;

export const SIGN_UP = gql`
  mutation SignUp($registerInput: registerInput!) {
    registerNewUser(registerInput: $registerInput) {
      phone,
      status,
      a,
      r,
      sid
    }
  }
`;

export const CAPTCHA_CODE = gql`
mutation CaptchaCode($sessionId:String!){
    captchaCode(sessionId:$sessionId){
        id,
        status
    }
}
`;

export const VERIFY_SESSION = gql`
mutation VerifySession($sessionId:String!){
verifySession(sessionId:$sessionId){
        status
    }
}
`;

export const SET_PASSWORD = gql`
mutation SetPassword($password:String!){
    setPassword(password:$password){
        status,
        id,
    }
}
`;
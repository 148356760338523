import { Button, Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";
import OtpInput from "react-otp-input";
import './PhoneVerifyDialog.less'

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    handleOTP: (otp: any) => void;
    resendOTP: () => void;
    registerPhoneNo: any;
    isOTPError: any;
    OTP: any;
    isResendDisabled: any;
}

const PhoneVerifyDialog = ({ modalVisible, handleOTP, resendOTP, registerPhoneNo, isOTPError, OTP, isResendDisabled   }: Props) => {
    return (
        <Modal centered open={modalVisible.visible || false} closable={false} footer={null}>
            <div className="phone-verify-content">
                <div className="phone-verify-title">
                <FormattedMessage id="PHONE_VERIFICATION" />
                </div>
                <div className="phone-verify-subtitle">
                    <FormattedMessage id="VERIFICATION_SUBTITLE" /> {registerPhoneNo ?? "-"}
                </div>
                <div className="otp-box">
                    <OtpInput 
                    className="otp-input"
                        errorStyle={
                            {
                                borderColor: '#EE4B2B'
                            }
                        }
                        hasErrored={isOTPError}
                        value={OTP}
                        onChange={handleOTP}
                        numInputs={6}
                        separator={<span>-</span>}
                        isInputNum={true}
                    />
                    <div className="otp-help-box">
                        <div><FormattedMessage id="OTP_HELP"/>
                        </div>
                        <div>
                            <Button onClick={resendOTP} disabled={isResendDisabled} type="link">
                                <FormattedMessage id="OTP_RESEND"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default PhoneVerifyDialog;
import React, { createContext, useContext, useReducer } from "react";

interface AuthState {
    isLogin: boolean;
    loginStatus: any;
    requiredPasswordChange: boolean;
    userProfile: null | {
        id?: string,
        name?: string,
        userName?: string,
        photoUrl?: string,
        phone?: string,
        email?: boolean,
        rankName?: string,
        isActive?: boolean,
        phoneVerified?: boolean,
        emailVerified?: boolean,
        kycApproved?: boolean
        kycIndicator?: boolean
        memberId?: string,
        numberOfWallets?: number,
        cashWalletId?: string,
        trxWalletId?: string,
        phoneCountry?: any
        uplineName?: string,
        uplinePhone?: string,
        isExported?: any,
        trxBalance?: any,
        VipMember?: any
    };
    agent: null | {
        id?: string,
        level?: number,
        sharesPercentage?: any,
        applicationFeeCommission?: any
    },
    cardAccount: null | {
        isLoading?: boolean,
        cards?: Array<any>,
        totalCardBalance?: number,
        netAssetValue?: number,
        totalSpending?: number,
    },
    walletAccount: null | {
        isLoading?: boolean,
        wallets?: Array<any>,
        totalWalletBalance?: number
    }
}

const INITIAL_STATE: AuthState = {
    isLogin: false,
    loginStatus: null,
    requiredPasswordChange: false,
    userProfile: {},
    agent: null,
    cardAccount: {},
    walletAccount: {}
};

export const SET_LOGIN_STATUS = "[AUTH] SET_LOGIN_STATUS";
export const SET_LOGIN_ERROR = "[AUTH] SET_LOGIN_ERROR";
export const SET_CURRENT_USER = "[AUTH] SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "[AUTH] CLEAR_CURRENT_USER";
export const SET_RPC = "[AUTH] SET_REQUIRED_PASSWORD_CHANGE";
export const UPDATE_CURRENT_USER_PROFILE = "[AUTH] UPDATE_CURRENT_USER_PROFILE";
export const UPDATE_CHANGE_PASSWORD_STATUS = "[AUTH] UPDATE_CHANGE_PASSWORD_STATUS"
export const SET_CARD_ACCS = "[AUTH] SET_CARD_ACCS";
export const SET_WALLET_ACCS = "[AUTH] SET_WALLET_ACCS";
export const UPDATE_CARD_ACCS = "[AUTH] UPDATE_CARD_ACCS";
export const UPDATE_WALLET_ACCS = "[AUTH] UPDATE_WALLET_ACCS";
export const SET_AGENT = "[AUTH] SET_AGENT";
export const UPDATE_PHONE_VERIFY = "[AUTH] UPDATE_PHONE_VERIFY"


const reducer = (state: AuthState, action: { type: string, payload: any }): AuthState => {
    const { type, payload } = action;
    switch (type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                userProfile: { ...payload['userProfile'], rankName: payload.userProfile.rank?.name },
                requiredPasswordChange: payload['userProfile'].requiredPasswordChange
            }
        case UPDATE_CURRENT_USER_PROFILE:
            return {
                ...state,
                userProfile: { ...payload['userProfile'] },
                requiredPasswordChange: payload['userProfile'].requiredPasswordChange
            }
        case SET_CARD_ACCS:
            return {
                ...state,
                cardAccount: { ...payload['cardAccount'] }
            }
        case UPDATE_CARD_ACCS:
            return {
                ...state,
                cardAccount: { ...payload['cardAccount'] }
            }
        case SET_WALLET_ACCS:
            return {
                ...state,
                walletAccount: { ...payload['walletAccount'] }
            }
        case UPDATE_WALLET_ACCS:
            return {
                ...state,
                walletAccount: { ...payload['walletAccount'] }
            }
        case SET_AGENT:
            return {
                ...state,
                agent: {
                    ...payload
                }
            }
        case SET_RPC:
            return {
                ...state,
                requiredPasswordChange: payload
            }
        case UPDATE_PHONE_VERIFY:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    phoneVerified: payload
                }
            }
        case UPDATE_CHANGE_PASSWORD_STATUS:
            return { ...state, requiredPasswordChange: false };
        case SET_LOGIN_STATUS:
            return { ...state, isLogin: payload };
        case CLEAR_CURRENT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
};

const authContext = createContext<{ authState: AuthState; authDispatch: React.Dispatch<any>; }>
    ({ authState: INITIAL_STATE, authDispatch: () => null });

export const AuthProvider = ({ children }: any) => {
    const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
    return (
        <authContext.Provider value={{ authState, authDispatch }} >
            {children}
        </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
}
import { gql } from "@apollo/client";

export const OTP_VERIFICATION = gql`
  mutation OtpVerification(
    $otp: String!
    $phone: String!
    $isLoggedIn: Boolean!
  ) {
    otpVerification(otp: $otp, phone: $phone, isLoggedIn: $isLoggedIn) {
      status
    }
  }
`;

export const GET_OTP = gql`
  mutation RequestOTP($phone: String!, $isPhoneCheck: Boolean!) {
    requestOTP(phone: $phone, isPhoneCheck: $isPhoneCheck) {
      status
    }
  }
`;

export const GET_EMAIL_OTP = gql`
  mutation RequestEmailOTP($email: String) {
    requestEmailOTP(email: $email) {
      status
    }
  }
`;

export const EMAIL_OTP_VERIFICATION = gql`
  mutation EmailOtpVerification($otp: String!) {
    emailOtpVerification(otp: $otp) {
      status
    }
  }
`;
import { gql } from "@apollo/client";

export const GET_CONFIGURATION = gql`
query Configuration($id: String!){
    configuration(id: $id) {
        id
        name 
        value
    }
}
`;

import { Suspense, useEffect } from 'react';
import './App.less';
import { IntlProvider } from 'react-intl';
import { SET_SCREENSIZE, useLayout } from './hooks/layout/LayoutContext';
import { Layout, Spin } from 'antd';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NoMatch from './NoMatch';
import AppProtected from './AppProtected';
import { anonymousRoutes } from './routes-anonymous';
import Auth from './pages/auth/Auth';
import { protectedRoutes, RouteInfo } from './routes-protected';
import ForgotPassword from './pages/auth/ForgotPassword';
import PasswordRecover from './pages/auth/ResetPasswordSuccess';
import jwtDecode from 'jwt-decode';
import { CLEAR_CURRENT_USER, SET_LOGIN_STATUS, SET_RPC, useAuth } from './hooks/auth/AuthContext';
import { useCookies } from 'react-cookie';
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import Home from './pages/home/Home';
import LoginComponent from './pages/auth/LoginComponent';
import SignUpComponent from './pages/auth/SignUpComponent';
import KYC from './pages/settings/kyc/KYC';
import MyProfile from './pages/settings/my-profile/MyProfile';
import ChangePassword from './pages/settings/change-password/ChangePassword';
import Topup from './pages/wallets/card/topup/Topup';
import TopupConfirmation from './pages/wallets/card/topup-confirmation/TopupConfirmation';
import Card from './pages/wallets/card/Card';
import CardTransactions from './pages/wallets/card/card-transactions/CardTransactions';
import CashWallet from './pages/wallets/cash-wallet/CashWallet';
import CashWalletTransactions from './pages/wallets/cash-wallet/cash-wallet-transactions/CashWalletTransactions';
import Deposit from './pages/wallets/cash-wallet/deposit/Deposit';
import { useMutation } from '@apollo/client';
import { VERIFY_SESSION } from './pages/auth/Auth.gql';
import { clearAllTokens } from './shared/helpers/set-token-cookie.helper';
import { client } from './apollo-client';
import NewsDetails from './pages/news/news-details/NewsDetails';
import TransferOut from './pages/wallets/cash-wallet/transfer-out/TransferOut';
import TransferOutConfirmation from './pages/wallets/cash-wallet/transfer-out-confirmation/TransferOutConfirmation';
import AgentProtectedRoute from './AgentProtectedRoute';

const App = () => {
  const { layoutState, layoutDispatch } = useLayout();
  const { authState, authDispatch } = useAuth();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const location: any = useLocation();
  const { pathname } = location || { pathname: '/' };
  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN
  };
  const [verifySession, { data, error }] = useMutation(VERIFY_SESSION, {
    errorPolicy: "all"
  });


  useEffect(() => {
    window.addEventListener("resize", () => {
      const dimension = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      layoutDispatch({ type: SET_SCREENSIZE, payload: dimension });
    })// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  //First load
  useEffect(() => {
    const token = cookies[process.env.REACT_APP_REFRESH_TOKEN || 'ngf-rt'];
    if (token) {
      const { isActive, rpc, sid }: any = jwtDecode(token);
      if (isActive) {
        verifySession({ variables: { sessionId: sid } })
        authDispatch({ type: SET_RPC, payload: rpc });
        authDispatch({ type: SET_LOGIN_STATUS, payload: token ? true : false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error || (data && data.verifySession &&
      data.verifySession.status && data.verifySession.status === 'FAIL')) {
      clearAllTokens();
      authDispatch({ type: CLEAR_CURRENT_USER });
      authDispatch({ type: SET_LOGIN_STATUS, payload: false });
      if (client) {
        client.cache.reset();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (authState.isLogin) {
      navigate(pathname.indexOf('/auth') >= 0 ? '/' : pathname, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLogin]);


  const renderProtectedRoutes = protectedRoutes.map((route: RouteInfo) => {
      if (route.children) {
        const childrenRoute = route.children.map((childRoute: RouteInfo) => {
          return (<Route key={childRoute.id} path={`${route.path}${childRoute.path}`} element={<childRoute.component />} />)
        });
        return [...childrenRoute];
      } else {
        return (route.agentProtected ? <Route key={route.id} path={route.path} element={
          <AgentProtectedRoute><route.component /></AgentProtectedRoute> } />: <Route key={route.id} path={route.path} element={
              <route.component />} /> )
      }
    });

  return (
    <IntlProvider locale={layoutState.locale} messages={messages[layoutState.locale]}>
      <Layout className='app-container'>
        <Suspense fallback={<Spin />}>
          <Routes>
            <Route path="/auth" element={<Auth />}>
              <Route index element={<LoginComponent />} />
              <Route path="login" element={<LoginComponent />} />
              <Route path="sign-up" element={<SignUpComponent />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="password-recover" element={<PasswordRecover />} />
              {anonymousRoutes.map((a) => <Route key={a.name} path={a.path} element={<a.component />} />)}
            </Route>
            <Route path="/" element={<AppProtected />}>
              <Route index element={<Home />} />
              <Route path="kyc" element={<KYC />} />
              <Route path="card" element={<Card />}>
                <Route path="top-up" element={<Topup />} />
                <Route path="overview" element={<CardTransactions />} />
                <Route path="confirmation" element={<TopupConfirmation />} />
              </Route>
              <Route path="e-wallet" element={<CashWallet />}>
                <Route path="deposit" element={<Deposit />} />
                <Route path="transfer-out" element={<TransferOut />} />
                <Route path="confirmation" element={<TransferOutConfirmation />} />
                <Route path="overview" element={<CashWalletTransactions />} />
              </Route>
              <Route path="my-profile" element={<MyProfile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="news-details/:id" element={<NewsDetails />} />
              {renderProtectedRoutes}
            </Route>
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Suspense>
      </Layout>
    </IntlProvider >);
}

export default App;
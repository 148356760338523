import React from "react";

export interface RouteInfo {
  id: string;
  name: string;
  path: string;
  component?: any;
  children?: RouteInfo[];
  agentProtected?:boolean;
}

const ReactLazyPreload = (importStatement: any) => {
  const Component: any = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

//preload pages
const MyProfile = ReactLazyPreload(
  () => import("./pages/settings/my-profile/MyProfile")
);
MyProfile.preload();

const ChangePassword = ReactLazyPreload(
  () => import("./pages/settings/change-password/ChangePassword")
);
ChangePassword.preload();

const KnowYourClient = ReactLazyPreload(
  () => import("./pages/settings/kyc/KYC")
);
KnowYourClient.preload();

const EWalletDeposit = ReactLazyPreload(
  () => import("./pages/wallets/cash-wallet/deposit/Deposit")
);
EWalletDeposit.preload();

const CashWallet = ReactLazyPreload(
  () => import("./pages/wallets/cash-wallet/CashWallet")
);
CashWallet.preload();

const Card = ReactLazyPreload(() => import("./pages/wallets/card/Card"));
Card.preload();

const CardTransaction = ReactLazyPreload(
  () => import("./pages/wallets/card/card-transactions/CardTransactions")
);
CardTransaction.preload();

const TopUp = ReactLazyPreload(
  () => import("./pages/wallets/card/topup/Topup")
);
TopUp.preload();

const TopupConfirmation = ReactLazyPreload(
  () => import("./pages/wallets/card/topup-confirmation/TopupConfirmation")
);
TopupConfirmation.preload();

const FullWalletTransactions = ReactLazyPreload(
  () => import("./pages/wallets/full-transactions/FullWalletTransactions")
);
FullWalletTransactions.preload();

const FullCardTransactions = ReactLazyPreload(
  () => import("./pages/wallets/full-transactions/FullCardTransactions")
);
FullCardTransactions.preload();

const TransferTransactions = ReactLazyPreload(
  () => import("./pages/wallets/full-transactions/TransferTransactions")
);
TransferTransactions.preload();

const NewsDetails = ReactLazyPreload(
  () => import("./pages/news/news-details/NewsDetails")
);
NewsDetails.preload();

const Agent = ReactLazyPreload(
  () => import("./pages/agent/Agent")
);
Agent.preload();

const AddMemberPage = ReactLazyPreload(
  () => import("./pages/agent/add-member/AddMemberPage")
);
AddMemberPage.preload();

const AddAgentPage = ReactLazyPreload(
  () => import("./pages/agent/add-agent/AddAgentPage")
);
AddAgentPage.preload();

const AgentCommissions = ReactLazyPreload(
  () => import("./pages/agent/agent-commission/AgentCommissions")
);
AgentCommissions.preload();

const TransferOut = ReactLazyPreload(
  () => import("./pages/wallets/cash-wallet/transfer-out/TransferOut")
);
TransferOut.preload();

const TransferOutConfirmation = ReactLazyPreload(
  () => import("./pages/wallets/cash-wallet/transfer-out-confirmation/TransferOutConfirmation")
);
TransferOutConfirmation.preload();

const AgentPayout = ReactLazyPreload(
  ()=> import("./pages/agent/agent-payout/AgentPayout")
)
AgentPayout.preload();

const GiftcardDetails = ReactLazyPreload(
  () => import("./pages/giftcard/giftcard-details/GiftcardDetails")
);
GiftcardDetails.preload();

const ExportAcc = ReactLazyPreload(
  () => import("./pages/settings/export-account/ExportAcc")
);
ExportAcc.preload();

export const protectedRoutes: RouteInfo[] = [
  {
    id: "",
    name: "My Profile",
    path: "/settings/my-profile",
    component: MyProfile,
  },
  {
    id: "",
    name: "Change Password",
    path: "/settings/change-password",
    component: ChangePassword,
  },
  {
    id: "",
    name: "KYC",
    path: "/settings/kyc",
    component: KnowYourClient,
  },
  {
    id: "",
    name: "Deposit to E-wallet",
    path: "/e-wallet/deposit",
    component: EWalletDeposit,
  },
  {
    id: "",
    name: "Transfer Out",
    path: "/e-wallet/transfer-out",
    component: TransferOut,
  },
  {
    id: "",
    name: "Transfer Out - Confirmation",
    path: "/e-wallet/confirmation",
    component: TransferOutConfirmation,
  },
  {
    id: "",
    name: "Cash Wallet",
    path: "/e-wallet/overview",
    component: CashWallet,
  },
  {
    id: "",
    name: "Card",
    path: "/card",
    component: Card,
  },
  {
    id: "",
    name: "Card Transactions",
    path: "/card/overview",
    component: CardTransaction,
  },
  {
    id: "",
    name: "Top Up",
    path: "/card/top-up",
    component: TopUp,
  },
  {
    id: "",
    name: "Top Up - Confirmation",
    path: "/card/confirmation",
    component: TopupConfirmation,
  },
  {
    id: "",
    name: "E-Wallet Transaction History",
    path: "/e-wallet-transaction-history",
    component: FullWalletTransactions,
  },
  {
    id: "",
    name: "Card Transaction History",
    path: "/card-transaction-history",
    component: FullCardTransactions,
  },
  {
    id: "",
    name: "Transfer Transaction History",
    path: "/transfer-transaction-history",
    component: TransferTransactions,
  },
  {
    id: "",
    name: "News Details",
		path: "/news-details/:id",
    component: NewsDetails,
  },
  {
    id: "",
    name: "Gift Card Details",
		path: "/giftcard-details/:id",
    component: GiftcardDetails,
  },
  {
    id: "",
    name: "Export Account",
		path: "/export-account",
    component: ExportAcc,
  },
  {
    id:"",
    name:"Agent",
    path:"/agent",
    component:Agent,
    agentProtected:true
  },
  {
    id:"",
    name:"AddMemberPage",
    path:"/agent/add-member",
    component:AddMemberPage,
    agentProtected:true
  },
  {
    id:"",
    name:"AddAgentPage",
    path:"/agent/add-agent",
    component:AddAgentPage,
    agentProtected:true
  },
  {
    id:"",
    name:"AgentCommissions",
    path:"/agent-commissions",
    component:AgentCommissions,
    agentProtected:true
  },
  {
    id:"",
    name:"AgentPayout",
    path:"/agent-payout",
    component:AgentPayout,
    agentProtected:true
  }
];

import { EditOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button } from "antd";
import { forEach } from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UPDATE_CURRENT_USER_PROFILE, useAuth } from "../../../hooks/auth/AuthContext";
import useNotification from "../../../hooks/layout/useNotification";
import ConfirmationDialog from "../../../shared/components/confirmation-modal/ConfirmationModal";
import { LOCK_CARD } from "./CardActionButtons.gql";
import "./CardActionButtons.less";
import ChangeATMPINModal from "./ChangeATMPINModal";

interface Props {
    setIsLockCard: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardActionButtons = ({ setIsLockCard }: Props) => {
    const intl = useIntl();
    const { authState, authDispatch } = useAuth();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [cardId, setCardId] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [cardAccount, setCardAccount] = useState<any>({});
    const [showLockCardModal, setShowLockCardModal] = useState<boolean>(false);
    const [showChangeATMPINModal, setShowChangeATMPINModal] = useState<boolean>(false);
    const [lockCard, { data, error, loading }] = useMutation(LOCK_CARD, {
        errorPolicy: "all"
    });

    useEffect(() => {
        if (error) {
            setErrorNotification(intl.formatMessage({ id: status === 'ACTIVE' ? 'FAIL_TO_LOCK_CARD_ERROR' : 'FAIL_TO_UNLOCK_CARD_ERROR' }));
        } else if (data) {
            if (data.lockCard && data.lockCard.status === "SUCCESS") {
                const _cardAccounts = authState?.cardAccount?.cards
                forEach(_cardAccounts, (val) => {
                    if (val.cardId === cardId) {
                        const status = val.status;
                        val.status = status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
                        val.statusText = status === 'ACTIVE' ? 'LOCKED' : 'ACTIVE';
                        setIsLockCard(status === 'ACTIVE' ? true : false)
                    }
                });

                authDispatch({
                    type: UPDATE_CURRENT_USER_PROFILE,
                    payload: {
                        userProfile: { ...authState.userProfile, cardAccount: _cardAccounts }
                    }
                });

                setSuccessNotification(intl.formatMessage({ id: status === 'ACTIVE' ? 'LOCK_CARD_SUCCESS' : 'UNLOCK_CARD_SUCCESS' }));
            } else {
                setErrorNotification(intl.formatMessage({ id: status === 'ACTIVE' ? 'FAIL_TO_LOCK_CARD_ERROR' : 'FAIL_TO_UNLOCK_CARD_ERROR' }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        setCardAccount(authState?.cardAccount && authState?.cardAccount?.cards &&
            authState?.cardAccount?.cards.length > 0 ? authState?.cardAccount?.cards[0] : {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.cardAccount])

    const changeATMPin = () => {
        if (cardAccount && cardAccount?.id) {
            setCardId(cardAccount?.cardId);
            setShowChangeATMPINModal(true);
        }
    }

    const lockCardAction = () => {
        if (cardAccount && cardAccount.id) {
            setCardId(cardAccount?.cardId);
            setStatus(cardAccount?.status);
            setShowLockCardModal(true);
        }
    }

    return (
        <div className="card-action-buttons">
            <Button onClick={changeATMPin} type="primary" className="default-btn-class" icon={<EditOutlined />}>
                <> </><FormattedMessage id="CHANGE_ATM_PIN"></FormattedMessage>
            </Button>
            <Button loading={loading} onClick={lockCardAction} type="primary" className="default-btn-class" icon={
                cardAccount && cardAccount?.statusText && cardAccount?.statusText === 'LOCKED' ? <UnlockOutlined /> : <LockOutlined />}>
                <> </>{cardAccount && cardAccount?.statusText && cardAccount?.statusText === 'LOCKED' ?
                    intl.formatMessage({ id: 'UNLOCK_CARD' }) : intl.formatMessage({ id: 'LOCK_CARD' })}
            </Button>

            <ConfirmationDialog showModal={showLockCardModal} title={intl.formatMessage({ id: 'BTN_CONFIRM' })}
                confirmationMessage={intl.formatMessage({ id: status === 'ACTIVE' ? "LOCK_CARD_CONFIRMATION_MESSAGE" : "UNLOCK_CARD_CONFIRMATION_MESSAGE" })}
                setShowModal={setShowLockCardModal}
                action={() =>
                    lockCard(
                        {
                            variables: {
                                cardId,
                            },
                        }
                    )
                } />
            <ChangeATMPINModal visible={showChangeATMPINModal} setVisible={setShowChangeATMPINModal}
                cardAccount={authState?.cardAccount && authState?.cardAccount.cards && authState?.cardAccount.cards.length > 0 ?
                    authState?.cardAccount.cards[0] : {}} />
        </div>
    );
}

export default CardActionButtons;
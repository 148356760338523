import { Button, Modal } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
    showModal: boolean;
    title: string;
    confirmationMessage: string;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    action: () => void;
    cancelText?: string;
    actionText?: string;
    cancelAction?: () => void;
}

const ConfirmationDialog = ({ showModal, title, confirmationMessage, setShowModal,
    action, cancelText, actionText, cancelAction }: Props) => {
    const intl = useIntl();

    const hideModal = () => {
        setShowModal(false);
    };

    return (
        <Modal
            title={title ? title : intl.formatMessage({ id: 'CONFIRMATION' })}
            open={showModal}
            onOk={() => { action(); setShowModal(false); }}
            onCancel={cancelAction ?? hideModal}
            footer={
                <>
                    <Button key="btn-cancel-confirm-dialog" className="btn-cancel-verify" onClick={cancelAction ?? hideModal} >
                        {cancelText ? cancelText : intl.formatMessage({ id: "BTN_CANCEL" })}
                    </Button>
                    <Button key="btn-submit-confirm-dialog" className="btn-submit-verify" type="primary" onClick={() => { action(); setShowModal(false); }} >
                        {actionText ? actionText : intl.formatMessage({ id: "BTN_SUBMIT" })}
                    </Button>
                </>
            }
        >
            <span>{confirmationMessage}</span>
        </Modal>
    )
}

export default ConfirmationDialog;
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { UPDATE_CURRENT_USER_PROFILE, useAuth } from "../../../hooks/auth/AuthContext";
import useNotification from "../../../hooks/layout/useNotification";
import { GET_USER_PROFILE } from "../../../shared/gql/profile.gql";
import { EMAIL_OTP_VERIFICATION } from "../../../shared/gql/request-otp.gql";
import RequestEmailOTP from "../request-otp/RequestEmailOTP";

interface Props {
    title: string;
    content: string;
    okButton?: string;
    cancelButton?: string;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

const EmailVerifyModal = ({ title, content, okButton, cancelButton, visible, setVisible }: Props) => {
    const intl = useIntl();
    const { authState, authDispatch } = useAuth();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [otp, setOtp] = useState<string>('');
    const [otpVerification, { data, error, loading }] = useMutation(EMAIL_OTP_VERIFICATION, {
        refetchQueries: [{ query: GET_USER_PROFILE }]
    });
    
    useEffect(() => {
        if (error) {
            setErrorNotification(intl.formatMessage({ id: 'INVALID_OTP_TOKEN_TRANSFER' }));
        } else if (data) {
            if (data.emailOtpVerification && data.emailOtpVerification.status === "SUCCESS") {
                setVisible(false);
                authDispatch({ type: UPDATE_CURRENT_USER_PROFILE, 
                    payload: { 
                        userProfile: { ...authState.userProfile, emailVerified: true }
                    } 
                 });
                setSuccessNotification(intl.formatMessage({ id: 'VERIFY_EMAIL_SUCCESS' }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    const handleAction = (isOk: boolean) => {
        if(!isOk) {
            setVisible(false);
        } else if (otp && isOk) {
            otpVerification({ variables: { otp } });
        }
    }

    return (
        <Modal
            open={visible}
            title={title}
            onCancel={() => handleAction(false)}
            footer={
                <>
                    <Button key="btn-cancel-email-verify" className="btn-cancel-verify" onClick={() => handleAction(false)} loading={loading}>
                        {cancelButton ? cancelButton : intl.formatMessage({ id: "BTN_CANCEL" })}
                    </Button>
                    <Button key="btn-submit-email-verify" className="btn-submit-verify" type="primary" onClick={() => handleAction(true)} loading={loading}>
                        {okButton ? okButton : intl.formatMessage({ id: "BTN_SUBMIT" })}
                    </Button>
                </>
            }
        >
           <p>{content}</p>
           <RequestEmailOTP module={'email-verify'} setOtp={setOtp} otp={otp} />
        </Modal>
    )
}

export default EmailVerifyModal;
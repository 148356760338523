import { Layout, notification } from "antd";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CLEAR_NOTIFICATION, useLayout } from "./hooks/layout/LayoutContext";
import "./App.less"
import { SET_AGENT, SET_CARD_ACCS, SET_CURRENT_USER, SET_LOGIN_STATUS, SET_WALLET_ACCS, UPDATE_CARD_ACCS, UPDATE_WALLET_ACCS, useAuth } from "./hooks/auth/AuthContext";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PLATFORM_PROFILE, GET_USER_PROFILE, GET_WALLET_PROFILE } from "./shared/gql/profile.gql";
import { Cookies } from "react-cookie";
import { CHECK_DEPOSIT } from "./shared/gql/check-deposit.gql";
import { OUTWARD_TRANSACTION } from "./shared/gql/outward-transaction.gql";
import { Header } from "antd/es/layout/layout";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import FullLogo from './assets/logo/logo_frame.svg';
import SideNav from "./pages/layouts/side-nav/SideNav";
import DefaultProfilePic from "../src/assets/icons/default-profile-avatar.png"
import moment from "moment";
import LanguageSwitcher from "./shared/widget/language-switcher/LanguageSwitcher";

const { Content } = Layout;

const AppProtected = () => {
  const cookies = new Cookies();
  const { authState, authDispatch } = useAuth();
  const navigate = useNavigate();
  const [getUserProfile, { data, error, called, refetch }] = useLazyQuery(
    GET_USER_PROFILE, { fetchPolicy: "no-cache", errorPolicy: "all", }
  );
  const [getPlatformProfile, { data: platformData, loading: platformLoad, called: platformCalled, refetch: platformRefetch }] = useLazyQuery(
    GET_PLATFORM_PROFILE, { fetchPolicy: "no-cache", errorPolicy: "all", }
  );
  const [getWalletProfile, { data: walletData, loading: walletLoad, called: walletCalled, refetch: walletRefetch }] = useLazyQuery(
    GET_WALLET_PROFILE, { fetchPolicy: "no-cache", errorPolicy: "all", }
  );
  useQuery(CHECK_DEPOSIT, { fetchPolicy: "no-cache", errorPolicy: "all", pollInterval: 120000 });
  useQuery(OUTWARD_TRANSACTION, { fetchPolicy: "no-cache", errorPolicy: "all", pollInterval: 120000 });
  const { layoutState, layoutDispatch } = useLayout();
  const [collapsed, setCollapsed] = useState(true);
  const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN || "bf-rt";
  const toggleSideNav = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    authDispatch({
      type: UPDATE_CARD_ACCS,
      payload: {
        cardAccount: { ...authState.cardAccount, isLoading: platformLoad }
      }
    }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformLoad]);

  useEffect(() => {
    authDispatch({
      type: UPDATE_WALLET_ACCS,
      payload: {
        walletAccount: { ...authState.walletAccount, isLoading: walletLoad }
      }
    }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletLoad]);

  useEffect(() => {
    if (data && !error) {
      authDispatch({ type: SET_CURRENT_USER, payload: data });
      if (data.userProfile.agent && data.userProfile.agent.level.level) {
        authDispatch({
          type: SET_AGENT, payload: {
            id: data.userProfile.agent.id,
            level: data.userProfile.agent.level.level,
            sharesPercentage: data.userProfile.agent.sharesPercentage,
            applicationFeeCommission: data.userProfile.agent.applicationFeeCommission,
          }
        });
      }else{
        authDispatch({
          type: SET_AGENT, payload: {}
        });
      }
    } else if (error) {
      authDispatch({ type: SET_LOGIN_STATUS, payload: false });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (walletData && walletData.length !== 0) {
      authDispatch({
        type: SET_WALLET_ACCS,
        payload: {
          walletAccount: { ...authState.walletAccount, isLoading: false, totalWalletBalance: walletData?.walletProfile?.totalWalletBalance, wallets: walletData?.walletProfile?.walletAccount }
        }
      });
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletData]);

  useEffect(() => {
    if (platformData && platformData.length !== 0) {
      authDispatch({
        type: SET_CARD_ACCS,
        payload: {
          cardAccount: { ...authState.cardAccount, isLoading: false, netAssetValue: platformData?.platformProfile?.netAssetValue, totalSpending: platformData?.platformProfile?.totalSpending, totalCardBalance: platformData?.platformProfile?.totalCardBalance, cards: platformData?.platformProfile?.cardAccount }
        }
      });
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformData]);

  useEffect(() => {
    if (layoutState.showNotification && layoutState.showNotification.message) {
      openNotificationWithIcon();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutState.showNotification]);


  useEffect(() => {
    if (!cookies.get(refreshTokenKey)) {
      authDispatch({ type: SET_LOGIN_STATUS, payload: false });
      navigate("/auth", { replace: true });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.get(refreshTokenKey)]);

  useEffect(() => {
    if (authState.isLogin) {
      called && refetch ? refetch() : getUserProfile();
      platformCalled ? platformRefetch() : getPlatformProfile();
      walletCalled ? walletRefetch() : getWalletProfile();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLogin]);


  const openNotificationWithIcon = () => {
    notification[layoutState?.showNotification?.type]({
      message: layoutState?.showNotification.message,
      description: layoutState?.showNotification?.description,
    });
    layoutDispatch({ type: CLEAR_NOTIFICATION })
  };

  const avatarClick = () => {
    navigate("/my-profile");
  }

  //Testing
  const headerStyle: React.CSSProperties = {
    color: '#fff',// font-color as white
    height: 64,
    paddingInline: 20,
    lineHeight: '64px',
    backgroundColor: "#1d39c4",
    fontSize: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between"
  };

  return (
    <Layout style={{ overflow: 'hidden' }}>
      <SideNav collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <Header style={headerStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {window.innerWidth < 769 ? collapsed ? <MenuUnfoldOutlined onClick={toggleSideNav} /> : <MenuFoldOutlined onClick={toggleSideNav} /> : null}
            {window.innerWidth < 769 ? <img alt="logo" className="burse-logo" style={{ width: 90, height: 75, marginLeft: 15 }} src={FullLogo} onClick={() => { navigate("/") }} /> : null}
          </div>
          <div className="header-profile">
            <LanguageSwitcher />
            <img alt={"profile-pic"} className="user-avatar" src={authState?.userProfile?.photoUrl ? `${authState?.userProfile?.photoUrl}?m=${moment().valueOf()}` : DefaultProfilePic} onClick={avatarClick} />
          </div>
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppProtected;



import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_NEWS } from "./news.gql";
import { FormattedMessage } from "react-intl";
import { Carousel, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "./News.less";

const News = () => {
    const [newsList, setNewsList] = useState([]);
    const navigate = useNavigate();
    const { data, loading } = useQuery(GET_NEWS, { fetchPolicy: "cache-and-network" });

    useEffect(() => {
        if (data && data.news && data.news?.length > 0) {
            setNewsList(data.news);
        }
    }, [data]);

    const viewNews = (id: any) => {
        navigate(`/news-details/${id}`);
    };

    return (
        <>
            {
                <Spin spinning={loading}>
                    {
                        newsList && newsList.length > 0 ?
                            <div className="news-events-box">
                                <div className="news-title"><FormattedMessage id="NEWS" /></div>
                                <Carousel className="news-carousel" autoplay={true}>
                                    {
                                        newsList.map((news: any) => {
                                            return (
                                                <div key={news.id} onClick={() => viewNews(news.id)}>
                                                    <img alt="Banner" className="carousel-image" src={news.coverPictureUrl} />
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>
                            </div>
                            :
                            null
                    }
                </Spin>
            }
        </>
    );
};

export default News
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAuth } from "../../../hooks/auth/AuthContext";
import useNotification from "../../../hooks/layout/useNotification";
import { GET_OTP } from "../../../shared/gql/request-otp.gql";
import RequestOTP from "../request-otp/RequestOTP";

interface Props {
    title: string;
    content: string;
    okButton?: string;
    cancelButton?: string;
    visible: boolean;
    onComplete: (values: any) => void;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

const PhoneVerifyModal = ({ title, content, okButton, cancelButton, visible, setVisible, onComplete }: Props) => {
    const intl = useIntl();
    const { setErrorNotification } = useNotification();
    const { authState } = useAuth();
    const [otp, setOtp] = useState<string>('');
    const [getOTP, { data: dataOTP, error: errorOTP }] = useMutation(GET_OTP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [isOTPError, setOTPError] = useState(false);
    const [isResendDisabled, setResendDisabled] = useState(false);

    useEffect(() => {
        if (visible) {
            resendOTP();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    useEffect(() => {
        if (dataOTP && dataOTP.requestOTP && dataOTP.requestOTP.status === 'SUCCESS') {
            setResendDisabled(true);
            setTimeout(() => {
                setResendDisabled(false);
            }, 60000);
        } else if (errorOTP) {
            if (errorOTP.message?.indexOf('PHONE_EXIST') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'DUPLICATE_ACCOUNT_MESSAGE' }));
            } else if (errorOTP.message?.indexOf('ACCOUNT_NOT_EXISTED') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'ACCOUNT_NOT_EXISTED' }));
            } else {
                setErrorNotification(intl.formatMessage({ id: 'FREQUENT_ACTION_PHONE' }));
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOTP, errorOTP]);

    const handleAction = (isOk: boolean) => {
        if (!isOk) {
            setVisible(false);
        } else if(otp && otp.length === 6) {
            onComplete(otp);
            setVisible(false);
        }
    }

    const handleOTP = (otp: any) => {
        if (otp.length < 6) {
            setOTPError(false);
        }
        setOtp(otp);
    };

    const resendOTP = () => {
        if (authState?.userProfile?.phone) {
            getOTP(
                {
                    variables: {
                        phone: authState?.userProfile?.phone,
                        isPhoneCheck: false
                    },
                }
            );
        }
    };

    return (
        <Modal
            open={visible}
            title={title}
            onCancel={() => handleAction(false)}
            footer={
                <>
                    <Button key="btn-cancel-phone-verify" className="btn-cancel-verify" onClick={() => handleAction(false)}>
                        {cancelButton ? cancelButton : intl.formatMessage({ id: "BTN_CANCEL" })}
                    </Button>
                    <Button key="btn-submit-phone-verify" className="btn-submit-verify" type="primary" onClick={() => handleAction(true)}>
                        {okButton ? okButton : intl.formatMessage({ id: "BTN_SUBMIT" })}
                    </Button>
                </>
            }
        >
           <p>{content}</p>
           <RequestOTP handleOTP={handleOTP} resendOTP={resendOTP} OTP={otp} isOTPError={isOTPError}
             isResendDisabled={isResendDisabled} />
        </Modal>
    )
}

export default PhoneVerifyModal;
import { Form, Button, Checkbox } from "antd";
import { Dispatch, SetStateAction } from "react";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
    userKyc: any;
    editable: boolean;
    current: number;
    setCurrent: Dispatch<SetStateAction<any>>;
    setErrorNotification: any;
    submitKycLoading: any;
};

const KYCDisclaimer = ({ editable, current, setCurrent, submitKycLoading }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <>
            <Form
                name="kyc-disclaimer"
                form={form}
                layout={"vertical"}
            >
                <ol className="ol-decimal">
                    <li className="kyc-li"><FormattedMessage id="KYC_DISCLAIMER_CONTENT1" /></li>
                    <li className="kyc-li"><FormattedMessage id="KYC_DISCLAIMER_CONTENT2" /></li>
                </ol>
                <Form.Item name="disclaimer-rdb"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }) },
                    ]}
                >
                    <Checkbox.Group>
                        <Checkbox
                            disabled={!editable}
                            value="KYC_DISCLAIMER_RDB"
                        >
                            <FormattedMessage id="KYC_DISCLAIMER_RDB" />
                        </Checkbox>
                    </Checkbox.Group>

                </Form.Item>
                <Form.Item>
                    <Button disabled={!editable} className={"btn-back"} type={"default"}
                        onClick={() => setCurrent(current - 1)}
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="BTN_BACK" />
                    </Button>
                    <Button loading={submitKycLoading} disabled={!editable} className={"form-button"} type={"primary"} htmlType="submit"
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="SUBMIT_KYC" />
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default KYCDisclaimer
import { gql } from "@apollo/client";

export const GET_NEWS = gql`
  query News {
    news {
      id
      coverPictureUrl
    }
  }
`;

export const GET_NEWS_DTL = gql`
  query NewsDetails($newsId: String!) {
    newsDetails(newsId: $newsId) {
      id
      title
      content
      pictureUrl
      createdAt
    }
  }
`;

import { InfoCircleFilled } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import { encode } from "base-64";
import { Button, Card, Form, Input, Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";
// import "./Dialogs.less";

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    submitPassword: { callFunction: any, callError: ApolloError | undefined, callLoading: boolean }
}

const SetPasswordDialog = ({ modalVisible, submitPassword }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                const variables = { password: encode(values.password) }
                submitPassword.callFunction({ variables });
                modalVisible.setVisible(false);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });

    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 48 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 48 },
            sm: { span: 40 },
            md: { span: 40 },
            lg: { span: 40 },
        },
    };

    return (
        <Modal title={<FormattedMessage id="SET_PASSWORD" />} open={modalVisible.visible || false} onOk={handleOk} closable={false}
            footer={<Button key="submit" type="primary" loading={submitPassword?.callLoading} onClick={handleOk}>{intl.formatMessage({ id: "BTN_SAVE" })}</Button>}
            cancelText={intl.formatMessage({ id: "BTN_CANCEL" })}>
            <Form form={form} {...formItemLayout}>
                <Form.Item name="password" label={<><FormattedMessage id="NEW_PASSWORD" /></>} className="password-dialog-input" required
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'NEW_PASSWORD_REQUIRED' }) },
                        { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                        {
                            pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#():;"'<>?/~`.,^}{\-_=+])[A-Za-z\d@$!%*?&#():;"'<>?/~`.,^}{\-_=+]{8,}/,
                            message: intl.formatMessage({ id: 'INVALID_PWD' })
                        }
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item name="confirmPassword" label={<><FormattedMessage id="CONFIRM_PASSWORD" /></>}  dependencies={['password']}  className="password-dialog-input" required
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'CONFIRM_PASSWORD_REQUIRED' }) },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(intl.formatMessage({ id: 'PASSWORD_NOT_MATCH' })));
                            },
                        })
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Card className="rule-box">
                    <div className="rule-icon">
                      <InfoCircleFilled />
                    </div>
                    <div>
                      <FormattedMessage id="PWD_RULES" />
                    </div>
                </Card>
            </Form>
        </Modal>
    )
}
export default SetPasswordDialog;
import { Button, Form } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import Check from '../../assets/icons/CheckCircle.svg'


const ResetPasswordSuccess = () => {

    const navigate = useNavigate();
    const onFinish = () => {
        navigate('/auth');
    }

  return (
    <div className="auth-container">
    <div className="auth-title">
            <img src={Check} alt="check password recovery" className="check-icon"/>

            <FormattedMessage id="CHECK_EMAIL_TITLE" />
            <div className="sm-title">
                <FormattedMessage id="CHECK_EMAIL_SM_TITLE" />
            </div>
    </div>

    <Form
        name="reset_pwd"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
    >
        <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
                <FormattedMessage id="BACK_TO_LOGIN_BTN" />
            </Button>
        </Form.Item>

    </Form>
</div>
  )
}

export default ResetPasswordSuccess
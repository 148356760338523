import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl"
import { SIGN_UP } from "./Auth.gql";
import { GET_OTP, OTP_VERIFICATION } from "../../shared/gql/request-otp.gql";
import { Buffer } from 'buffer';
import useNotification from "../../hooks/layout/useNotification";
import ConfirmationDialog from "../../shared/components/confirmation-modal/ConfirmationModal";
import CountryCode from "../../shared/components/country-code/CountryCode";
import SignUpMobile from "./sign-up/SignUpMobile";
import SignUpOTP from "./sign-up/SignUpOTP";
import SignUpProfile from "./sign-up/SignUpProfile";
import SignUpPwd from "./sign-up/SignUpPwd";
import { SET_LOGIN_STATUS, useAuth } from "../../hooks/auth/AuthContext";
import { setTokenCookie } from "../../shared/helpers/set-token-cookie.helper";

const SignUpComponent = () => {
    const pwdRegex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#():;"'<>?/~`.,^}{\-_=+])[A-Za-z\d@$!%*?&#():;"'<>?/~`.,^}{\-_=+]{8,}/);
    const intl = useIntl();
    const { authDispatch } = useAuth();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [showModal, setShowModal] = useState(false);
    const [isOTPError, setOTPError] = useState(false);
    const [isResendDisabled, setResendDisabled] = useState(true);
    const [current, setCurrent] = useState(0);
    const [OTP, setOTP] = useState();
    const [profileData, setProfileData] = useState({ email: '', firstName: '', lastName: '' });
    const [registerPhoneNo, setRegisterPhoneNo] = useState();
    const [countryCode, setCountryCode] = useState('MY');
    const [dialCode, setDialCode] = useState('60');
    const [signUpBody, setSignUpBody] = useState<any>({});
    const [error, setError] = useState('');
    const [getOTP, { data: dataOTP, error: errorOTP }] = useMutation(GET_OTP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [otpVerification, { data: otpData, error: otpError }] = useMutation(OTP_VERIFICATION, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const [signUp, { data: signUpData, error: signUpError }] = useMutation(SIGN_UP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const handleOTP = (otp: any) => {
        if (otp.length === 6) {
            otpVerification({
                variables: {
                    otp: otp,
                    phone: `+${dialCode}${registerPhoneNo}`,
                    isLoggedIn: false
                }
            });
        } else {
            setOTPError(false);
        }
        setOTP(otp);
    };
    const resendOTP = () => {
        if (dialCode && registerPhoneNo) {
            getOTP(
                {
                    variables: {
                        phone: `+${dialCode}${registerPhoneNo}`,
                        isPhoneCheck: true
                    },
                }
            );
            setResendDisabled(true);
            setTimeout(() => {
                setResendDisabled(false);
            }, 60000);
        }
    };
    const onFinish = (values: any) => {
        if (current === 0) {
            if (dialCode && values.phone) {
                setRegisterPhoneNo(values.phone);
                getOTP(
                    {
                        variables: {
                            phone: `+${dialCode}${values.phone}`,
                            isPhoneCheck: true
                        },
                    }
                );
            }
        } else if (current === 2) {
            if (!!values) {
                setProfileData(values);
                setCurrent(current + 1);
            }
        } else if (current === 3) {
            const encodePwd = Buffer.from(values.password).toString('base64')
            const encodeConfirmPwd = Buffer.from(values.confirmPassword).toString('base64')
            if (encodePwd && encodeConfirmPwd && dialCode && registerPhoneNo && profileData) {
                setSignUpBody({
                    'email': profileData.email,
                    'firstName': profileData.firstName,
                    'lastName': profileData.lastName,
                    'password': encodePwd,
                    'confirmPassword': encodeConfirmPwd,
                    'dialCode': dialCode,
                    'phone': String(registerPhoneNo),
                    'countryCode': countryCode,
                    'phoneVerified': true
                });
                setShowModal(true);
            }
        }
    };
    useEffect(() => {
        if (dataOTP && dataOTP.requestOTP && dataOTP.requestOTP.status === 'SUCCESS') {
            if (current === 0) {
                setCurrent(current + 1);
            }
        } else if (errorOTP) {
            if (errorOTP && errorOTP.message) {
                if (errorOTP.message?.indexOf('PHONE_EXIST') > -1) {
                    setError('DUPLICATE_ACCOUNT_MESSAGE');
                } else if (errorOTP.message?.indexOf('FREQUENT_ACTION') > -1) {
                    setError('FREQUENT_ACTION_PHONE');
                } else {
                    setError('FAIL_TO_REQUEST_OTP');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOTP, errorOTP]);

    useEffect(() => {
        if (current === 1) {
            setTimeout(() => {
                setResendDisabled(false);
            }, 60000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    useEffect(() => {
        setTimeout(() => {
            setResendDisabled(false);
        }, 60000);
    }, []);

    useEffect(() => {
        if (otpData && otpData.otpVerification && otpData.otpVerification.status === 'SUCCESS') {
            // TODO: Pending loading and verified sign
            setOTPError(false);
            setCurrent(current + 1);
        } else if (otpError) {
            setOTPError(true);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otpData, otpError]);

    useEffect(() => {
        if (signUpData && signUpData.registerNewUser && signUpData.registerNewUser.status === 'SUCCESS') {
            const { a, r, sid } = signUpData.registerNewUser;
            setSuccessNotification(intl.formatMessage({ id: 'SIGN_UP_SUCCESSFUL' }));
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || "bf-at", a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || "bf-rt", r);
            setTokenCookie("bf-sid", sid);
            authDispatch({ type: SET_LOGIN_STATUS, payload: true });
        } else if (signUpError) {
            setErrorNotification(intl.formatMessage({ id: 'SIGN_UP_FAILED' }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpData, signUpError]);

    const backStep = (step: any) => {
        setCurrent(step);
    };

    const steps = [
        {
            title: 'Mobile',
            content: <SignUpMobile onFinish={onFinish} error={error} countryPrefix={CountryCode({ countryCode, setCountryCode, setDialCode })} ></SignUpMobile>,
        },
        {
            title: 'OTP',
            content: <SignUpOTP backStep={backStep} handleOTP={handleOTP} resendOTP={resendOTP} dialCode={dialCode} registerPhoneNo={registerPhoneNo} isOTPError={isOTPError} OTP={OTP} isResendDisabled={isResendDisabled}></SignUpOTP>,
        },
        {
            title: 'Profile',
            content: <SignUpProfile backStep={backStep} onFinish={onFinish}></SignUpProfile>
        },
        {
            title: 'Password',
            content: <SignUpPwd backStep={backStep} onFinish={onFinish} pwdRegex={pwdRegex}></SignUpPwd>,
        },
    ];
    return (
        <>
            <div>{steps[current]?.content}</div>
            <ConfirmationDialog showModal={showModal} title={intl.formatMessage({ id: 'MODAL_CONFIRM' })}
                confirmationMessage={intl.formatMessage({ id: 'SIGN_UP_SUBMIT' })}
                setShowModal={setShowModal}
                action={() =>
                    signUp(
                        {
                            variables: {
                                registerInput: signUpBody,
                            },
                        }
                    )
                } />
        </>
    )
}

export default SignUpComponent

import { FormattedMessage, useIntl } from "react-intl";
import "./Home.less";
import ProfileDashboard from "./profile-dashboard/ProfileDashboard";
import TopUpCashColor from '../../assets/icons/topup-cash-color.svg';
import TopUpVISAColor from '../../assets/icons/topup-visa-color.svg';
import TransferOut from '../../assets/icons/transfer-out.svg';
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import { Card, Tag } from "antd";
import { UPDATE_CHANGE_PASSWORD_STATUS, UPDATE_PHONE_VERIFY, useAuth } from "../../hooks/auth/AuthContext";
import SetPasswordDialog from "../auth/SetPasswordDialog";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { SET_PASSWORD } from "../auth/Auth.gql";
import { setTokenCookie } from "../../shared/helpers/set-token-cookie.helper";
import useNotification from "../../hooks/layout/useNotification";
import News from "../news/News";
import PhoneVerifyDialog from "../auth/PhoneVerifyDialog";
import { GET_OTP, OTP_VERIFICATION } from "../../shared/gql/request-otp.gql";
import MarqueeAnnouncements from "./announcements/MarqueeAnnouncements";
import PopUpAnnouncements from "./announcements/PopUpAnnouncements";
import { useCookies } from "react-cookie";
import { GET_ANNOUNCEMENT } from "./Home.gql";
import { useLayout } from "../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../shared/helpers/language.helper";
import Giftcards from "../giftcard/Giftcard";

const Home = () => {
  const { layoutState } = useLayout();
  const navigate = useNavigate();
  const { authState, authDispatch } = useAuth();
  const intl = useIntl();
  const { setSuccessNotification, setErrorNotification } = useNotification();
  const [requiredPasswordChange, setRequiredPasswordChange] = useState<boolean>(false);
  const [requiredPhoneVerify, setRequiredPhoneVerify] = useState<boolean>(false);
  const [setPassword, { data: setPasswordData, error: setPasswordError, loading: setPasswordLoading }] = useMutation(SET_PASSWORD);
  const [OTP, setOTP] = useState();
  const [isOTPError, setOTPError] = useState(false);
  const [registerPhoneNo, setRegisterPhoneNo] = useState<string>();
  const [isResendDisabled, setResendDisabled] = useState(true);
  const [hasUnseenAnnouncement, setHasUnseenAnnouncement] = useState<boolean>(false);
  const [announcementSeen, setAnnouncementSeen] = useState<string>('false');
  const [marqueeAnnouncementData, setMarqueeAnnouncementData] = useState<any>();
  const [popUpAnnouncementData, setPopUpAnnouncementData] = useState<any>();
  const { data: announcementData } = useQuery(GET_ANNOUNCEMENT, { fetchPolicy: "no-cache" });
  const [cookies] = useCookies();

  useEffect(() => {
    let hasAnnouncementSeen = cookies[`c-announcement-read`];
    setAnnouncementSeen(hasAnnouncementSeen ?? 'false');// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (announcementData && announcementData?.announcement && announcementData?.announcement.length > 0) {
      let marqueeAnnouncement = announcementData?.announcement.filter((x: any) => (x.displayType !== 'POP_UP'));
      let popUpAnnouncement = announcementData?.announcement.filter((x: any) => (x.displayType !== 'MARQUEE'));

      if (popUpAnnouncement) {
        if (announcementSeen === 'false' && popUpAnnouncement && popUpAnnouncement?.length > 0) {
          setHasUnseenAnnouncement(true);
        }
      }

      setPopUpAnnouncementData(popUpAnnouncement);
      setMarqueeAnnouncementData(marqueeAnnouncement);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementData])


  const [otpVerification, { data: otpData, error: otpError }] = useMutation(OTP_VERIFICATION, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [getOTP, { data: dataOTP, error: errorOTP }] = useMutation(GET_OTP, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const isRequiredPasswordChange = () => {
    return authState?.requiredPasswordChange;
  }

  const isRequiredPhoneVerify = () => {
    return !!!authState?.userProfile?.phoneVerified;
  }

  useEffect(() => {
    if (authState.requiredPasswordChange) {
      setRequiredPasswordChange(authState.requiredPasswordChange);
    }
    if (authState.userProfile?.phone) {
      setRegisterPhoneNo(authState.userProfile.phone);
    }
    if (!!!authState.userProfile?.phoneVerified && authState.userProfile?.phone) {
      setRequiredPhoneVerify(true);
      getOTP(
        {
          variables: {
            phone: `${authState.userProfile.phone}`,
            isPhoneCheck: false
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.requiredPasswordChange, authState.userProfile?.phone, authState.userProfile?.phoneVerified]);

  useEffect(() => {
    if (dataOTP && dataOTP.requestOTP && dataOTP.requestOTP.status === 'SUCCESS') {
      setTimeout(() => {
        setResendDisabled(false);
      }, 60000);
    }
    if (errorOTP && errorOTP.message) {
      if (errorOTP.message?.indexOf('PHONE_EXIST') > -1) {
        setErrorNotification(intl.formatMessage({ id: 'DUPLICATE_ACCOUNT_MESSAGE' }));
      } else if (errorOTP.message?.indexOf('FREQUENT_ACTION') > -1) {
        setErrorNotification(intl.formatMessage({ id: 'FREQUENT_ACTION_PHONE' }));
      } else {
        setErrorNotification(intl.formatMessage({ id: 'FAIL_TO_REQUEST_OTP' }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOTP, errorOTP]);

  useEffect(() => {
    if (otpData && otpData.otpVerification && otpData.otpVerification.status === 'SUCCESS') {
      setRequiredPhoneVerify(false);
      authDispatch({ type: UPDATE_PHONE_VERIFY, payload: true });
      setOTPError(false);
    } else if (otpError) {
      setOTPError(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpData, otpError]);

  useEffect(() => {
    if (setPasswordError) {
      setErrorNotification(intl.formatMessage({ id: "SET_PASSWORD_FAIL" }));
    } else if (setPasswordData) {
      setSuccessNotification(intl.formatMessage({ id: "SET_PASSWORD_SUCCESS" }));
      setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'bf-at', setPasswordData.setPassword?.id);
      authDispatch({ type: UPDATE_CHANGE_PASSWORD_STATUS, payload: false });
      setRequiredPasswordChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPasswordData, setTokenCookie]);

  const walletAction = (type: any) => {
    if (type === 'receive') {
      navigate("/e-wallet/deposit")
    } else if (type === 'reload') {
      navigate("/card/top-up")
    } else if (type === 'send') {
      navigate("/e-wallet/transfer-out")
    }
  };

  const verificationNav = (type: any) => {
    if (type === 'kyc') {
      navigate("/kyc")
    } else if (type === 'email') {
      navigate("/my-profile")
    }
  }

  const handleOTP = (otp: any) => {
    if (otp.length === 6) {
      otpVerification({
        variables: {
          otp: otp,
          phone: `${registerPhoneNo}`,
          isLoggedIn: true
        }
      });
    } else {
      setOTPError(false);
    }
    setOTP(otp);
  };

  const resendOTP = () => {
    if (registerPhoneNo) {
      getOTP(
        {
          variables: {
            phone: `${registerPhoneNo}`,
            isPhoneCheck: false
          },
        }
      );
      setResendDisabled(true);
    }
  };

  return (
    <div className="home-page-container">
      {(!isRequiredPhoneVerify()) && isRequiredPasswordChange() ? (
        <SetPasswordDialog
          modalVisible={{ visible: requiredPasswordChange, setVisible: setRequiredPasswordChange }}
          submitPassword={{
            callFunction: setPassword, callError: setPasswordError,
            callLoading: setPasswordLoading
          }}
        />
      ) : null}
      {isRequiredPhoneVerify() ? (
        <PhoneVerifyDialog
          modalVisible={{ visible: requiredPhoneVerify, setVisible: setRequiredPhoneVerify }} handleOTP={handleOTP} resendOTP={resendOTP} registerPhoneNo={registerPhoneNo} isOTPError={isOTPError} OTP={OTP} isResendDisabled={isResendDisabled} />
      ) : null}
      <ProfileDashboard />
      <div className="home-info-container">
        {marqueeAnnouncementData && marqueeAnnouncementData.length > 0 ? <MarqueeAnnouncements marqueeData={marqueeAnnouncementData} /> : null}
        <div className="padding-container">
          <div className="indicator-box">
            {
              authState.userProfile?.kycIndicator === true ?
                <Tag className="tag-style" icon={<ExclamationCircleOutlined />} color={"warning"} onClick={() => verificationNav('kyc')}>
                  <FormattedMessage id="WARN_KYC" />
                </Tag>
                :
                null
            }
            {
              authState.userProfile?.emailVerified === false ?
                <Tag className="tag-style" icon={<ExclamationCircleOutlined />} color={"warning"} onClick={() => verificationNav('email')}>
                  <FormattedMessage id="WARN_VERIFY_EMAIL" />
                </Tag>
                :
                null
            }
          </div>
          <div id="card-top-nav-scroll" className="cash-wallet-top-nav" style={{ marginTop: 0 }}>
            <Card hoverable bordered={false} onClick={() => { walletAction('reload') }} className="top-nav-card">
              <img alt="topup-visa-color" className="card-menu-button" src={TopUpVISAColor} />
              <div className="top-nav-content">
                <div><FormattedMessage id="RELOAD" /></div>
              </div>
            </Card>
            <Card hoverable bordered={false} onClick={() => { walletAction('send') }} className="top-nav-card">
              <img alt="topup-cash-color" className="card-menu-button" src={TransferOut} />
              <div className="top-nav-content">
                <div><FormattedMessage id="SEND" /></div>
              </div>
            </Card>
            <Card hoverable bordered={false} onClick={() => { walletAction('receive') }} className="top-nav-card">
              <img alt="topup-cash-color" className="card-menu-button" src={TopUpCashColor} />
              <div className="top-nav-content">
                <div><FormattedMessage id="RECEIVE" /></div>
              </div>
            </Card>
          </div>
        </div>
        <Giftcards></Giftcards>
        <News></News>
        {popUpAnnouncementData && popUpAnnouncementData.length > 0 &&
          <PopUpAnnouncements
            modalVisible={{ visible: hasUnseenAnnouncement, setVisible: setHasUnseenAnnouncement }}
            canClose={false}
            displayMsg={{
              title: intl.formatMessage({ id: "GENERAL_ANNOUNCEMENT" }),
              submitBtnTitle: intl.formatMessage({ id: "CLOSE" }),
              attachedText: popUpAnnouncementData[0]?.message[setDBLanguageToGet(layoutState.locale)],
              id: popUpAnnouncementData[0]?.id
            }}
          />
        }
      </div>
    </div>
  );
};

export default Home;


import { gql } from "@apollo/client";

export const LOCK_CARD = gql`
mutation LockCard($cardId: String!){
    lockCard(cardId:$cardId){
        status
    }
}`;

export const GET_2FA_CODE = gql`
mutation Get2FACode($cardId: String!){
    get2FACode(cardId:$cardId){
        status
    }
}`;

export const CHANGE_ATM_PIN = gql`
mutation ChangeATMPIN($cardId: String!, $atmPin: String!, $token: String! ){
    changeATMPIN(cardId:$cardId, atmPin:$atmPin, token:$token){
        status
    }
}`;
import { CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useIntl } from "react-intl";
import OtpInput from "react-otp-input";
import useNotification from "../../../hooks/layout/useNotification";
import { setGeneralCookie } from "../../../shared/helpers/set-general-cookie.helper";
import { CHANGE_ATM_PIN, GET_2FA_CODE } from "./CardActionButtons.gql";
import './CardActionButtons.less';

interface Props {
    cardAccount: any;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

const ChangeATMPINModal = ({ cardAccount, visible, setVisible }: Props) => {
    const intl = useIntl();
    const [ , , removeCookie] = useCookies();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [pin, setPin] = useState('');
    const [pinError, setPinError] = useState(false);
    const [confirmPin, setConfirmPin] = useState('');
    const [confirmPinError, setConfirmPinError] = useState(false);
    const [otp, setOTP] = useState('');
    const [otpError, setOTPError] = useState(false);
    const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(true);
    const [isConsecutive, setIsConsecutive] = useState(true);
    const [cooldown, setCooldown] = useState<number>(0);
    const [cooldownTimer, setCooldownTimer] = useState<number>();

    const [get2FACode, { data, error, loading }] = useMutation(GET_2FA_CODE, {
        errorPolicy: "all"
    });

    const [changeATMPIN, { data: changeData, error: changeError, loading: changeLoading }] = useMutation(CHANGE_ATM_PIN, {
        errorPolicy: "all"
    });

    useEffect(() => {
        if (error) {
            setErrorNotification(intl.formatMessage({ id: "FAIL_TO_REQUEST_VERIFICATION_CODE" }));
            removeTimer();
        } else if (data) {
            if (data.get2FACode && data.get2FACode.status === "SUCCESS") {
                setSuccessNotification(intl.formatMessage({ id: "VERIFICATION_CODE_SEND_TO_PHONE" }));
            } else {
                setErrorNotification(intl.formatMessage({ id: "FAIL_TO_REQUEST_VERIFICATION_CODE" }));
                removeTimer();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if (changeError) {
            setErrorNotification(intl.formatMessage({ id: "FAIL_TO_CHANGE_ATM_PIN" }));
        } else if (changeData) {
            if (changeData.changeATMPIN && changeData.changeATMPIN.status === "SUCCESS") {
                setSuccessNotification(intl.formatMessage({ id: "SUCCESSFUL_TO_CHANGE_ATM_PIN" }));
                closeModal();
            } else {
                setErrorNotification(intl.formatMessage({ id: "FAIL_TO_CHANGE_ATM_PIN" }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeData, changeError]);

    const handleAction = (isOk: boolean) => {
        if(!isOk) {
            closeModal();
        } else if(isOk && pin.length === 6 && confirmPin.length === 6 && pin === confirmPin && 
            otp.length === 6 && cardAccount && cardAccount.cardId && !isConsecutive) {
            changeATMPIN({
                variables: {
                    cardId: cardAccount.cardId,
                    atmPin: pin,
                    token: otp
                }
            })
        } else {
            setErrorNotification(intl.formatMessage({ id: "CHECK_ERROR_MESSAGE" }));
        }
    }

    const sendOTP = () => {
        if(cardAccount && cardAccount.cardId && visible) {
            get2FACode({
                variables: {
                    cardId: cardAccount.cardId
                }
            });
            if (cooldown === 0) {
                //1 minute cooldown
                setGeneralCookie(`c-cooldown-change-pin`, moment().unix().toString(), 119);
                setCooldown(59);
                if (cooldownTimer) {
                    clearInterval(cooldownTimer);
                }
                initiateTimer(59);
            }
        }
    }

    const handlePIN = (value: string) => {
        if (value.length === 6) {
            setPinError(false);
            checkConsecutive(value);
            if (confirmPin.length === 6 && confirmPin === value) {
                setIsSendCodeDisabled(false);
            } else {
                setConfirmPinError(true);
            }
        } else {
            setIsSendCodeDisabled(true);
            setPinError(true);
        }
        
        setPin(value);
    };

    const handleConfirmPIN = (value: string) => {
        if (value.length === 6 && value === pin) {
            setConfirmPinError(false);
            checkConsecutive(value);
            if (pin.length === 6 && pin === value) {
                setIsSendCodeDisabled(false);
            } else {
                setConfirmPinError(true);
            }
        } else {
            setIsSendCodeDisabled(true);
            setConfirmPinError(true);
        }
        setConfirmPin(value);
    };

    const handleOTP = (value: string) => {
        if (value.length === 6) {
            setOTPError(false);
        } else {
            setOTPError(true);
        }
        setOTP(value);
    };

    const initiateTimer = (amount: number) => {
        const cooldownInterval: number = window.setInterval(() => {
            if (amount > 0) {
                setCooldown(amount--);
            } else {
                setCooldown(0);
                clearInterval(cooldownInterval);
            }
        }, 1000);
        setCooldownTimer(cooldownInterval);
    }

    const removeTimer = () => {
        removeCookie(`c-cooldown-change-pin`);
        setCooldown(0);
        if (cooldownTimer) {
            clearInterval(cooldownTimer);
        }
    }

    const closeModal = () => {
        setPin('');
        setPinError(false);
        setConfirmPin('');
        setConfirmPinError(false);
        setOTP('');
        setOTPError(false);
        removeTimer();
        setVisible(false);
        setIsSendCodeDisabled(true);
        setIsConsecutive(true);
    }

    const checkConsecutive = (value: any) => {
        if (/(.)\1\1/.test(value)) {
            setIsConsecutive(true);
        } else {
            setIsConsecutive(false);
        }
    }

    return (
        <Modal
            open={visible}
            title={intl.formatMessage({ id: "CHANGE_ATM_PIN" })}
            onCancel={() => handleAction(false)}
            footer={
                <>
                    <Button key="btn-cancel-change-atm-pin" className="btn-cancel-verify" onClick={() => handleAction(false)} >
                        {intl.formatMessage({ id: "BTN_CANCEL" })}
                    </Button>
                    <Button key="btn-submit-change-atm-pin" className="btn-submit-verify" type="primary" loading={changeLoading} onClick={() => handleAction(true)} >
                        {intl.formatMessage({ id: "BTN_SUBMIT" })}
                    </Button>
                </>
            }
        >
           <span className="change-atm-pin-title">{intl.formatMessage({ id: "ENTER_SIX_DIGIT_ATM_PIN" })}</span>
           <div className="pin-box">
            <span className="pin-title">{intl.formatMessage({ id: "ENTER_NEW_ATM_PIN" })}</span>
            <OtpInput className="pin-input"
                    errorStyle={
                        {
                            borderColor: '#EE4B2B'
                        }
                    }
                    isDisabled={cooldown > 0}
                    hasErrored={pinError}
                    value={pin}
                    onChange={handlePIN}
                    numInputs={6}
                    separator={<span> </span>}
                    isInputNum={true}
                />
            </div>
            <div className="pin-box">
                <span className="pin-title">{intl.formatMessage({ id: "RE_ENTER_NEW_ATM_PIN" })}</span>
                <OtpInput className="pin-input"
                    errorStyle={
                        {
                            borderColor: '#EE4B2B'
                        }
                    }
                    isDisabled={cooldown > 0}
                    hasErrored={confirmPinError}
                    value={confirmPin}
                    onChange={handleConfirmPIN}
                    numInputs={6}
                    separator={<span> </span>}
                    isInputNum={true}
                />
            </div>
            <div className="checking-box">
                <span className={isConsecutive ? 'checking-box-error': 'checking-box-success'}>
                    {isConsecutive ? <CloseCircleFilled /> : <CheckCircleFilled />} {intl.formatMessage({ id: "NO_CONSECUTIVE_NUMBER"})}
                </span>
                <span className={pin && confirmPin && pin.length === 6 && confirmPin.length ===6 && pin === confirmPin ? 
                    'checking-box-success' : 'checking-box-error'}>
                    {pin && confirmPin && pin.length === 6 && confirmPin.length ===6 && pin === confirmPin ? 
                        <CheckCircleFilled /> : <CloseCircleFilled />} {intl.formatMessage({ id: "BOTH_PIN_MUST_SAME"})}
                </span>
            </div>
            <div className="btn-send-code-wrapper">
                <Button key="btn-send-code" size="small" loading={loading} className="btn-send-code" 
                    type="primary" onClick={sendOTP} disabled={cooldown > 0 || isSendCodeDisabled || isConsecutive}>
                    {intl.formatMessage({ id: "SEND_CODE" })} {cooldown > 0 ? `(${cooldown})` : ''} 
                </Button>
            </div>
            <div className="pin-box">
                <span>{intl.formatMessage({ id: "ENTER_PHONE_VERIFICATION_CODE" })}</span>
                <OtpInput className="pin-input"
                    errorStyle={
                        {
                            borderColor: '#EE4B2B'
                        }
                    }
                    hasErrored={otpError}
                    value={otp}
                    onChange={handleOTP}
                    numInputs={6}
                    separator={<span> </span>}
                    isInputNum={true}
                />
            </div>
        </Modal>
    )
}

export default ChangeATMPINModal;
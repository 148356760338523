import { gql } from "@apollo/client";

export const GET_WALLET_BALANCE = gql`
  query WalletBalance($id: String) {
    walletBalance(id: $id) {
      id
      userId
      balance
      usdtAddress
    }
  }
`;

export const GET_WALLET_TRANSACTIONS = gql`
  query WalletTransactions($walletId: String) {
    walletTransactions(walletId: $walletId, limit: true) {
        id
        walletId
        amount
        createdAt
        transactionType
    }
  }
`;

export const TRANSFER_OUT = gql`
  mutation TransferOut($transferOutInput: TransferOutInput) {
    transferOut(transferOutInput: $transferOutInput) {
      status
    }
  }
`


import { useMutation } from "@apollo/client";
import { Button, Input } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage, useIntl } from "react-intl";
import { GET_EMAIL_OTP } from "../../../shared/gql/request-otp.gql";
import { setGeneralCookie } from "../../../shared/helpers/set-general-cookie.helper";
import useNotification from "../../../hooks/layout/useNotification";

interface Props {
    module: string;
    otp: string;
    setOtp: (val: string) => void;
}

const RequestEmailOTP = ({ module, setOtp, otp }: Props) => {
    // eslint-disable-next-line
    const intl = useIntl();
    const { setErrorNotification } = useNotification();
    const [cookies, , removeCookie] = useCookies();
    const [cooldown, setCooldown] = useState<number>(0);
    const [cooldownTimer, setCooldownTimer] = useState<number>();
    const [getOTP, { error }] = useMutation(GET_EMAIL_OTP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    useEffect(() => {
        const cooldownFromCookie = cookies[`c-cooldown-${module}`];
        if (cooldownFromCookie) {
            const timePassed = moment().unix() - (+cooldownFromCookie);
            //due to latency, reduce 1 sec
            const remainingSeconds = timePassed > 179 ? 0 : (179 - timePassed);
            setCooldown(remainingSeconds);
            initiateTimer(remainingSeconds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const clearTimer = () => {
            removeCookie(`c-cooldown-${module}`);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }
        return () => clearTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cooldownTimer])

    useEffect(() => {
        if (error) {
            if (error.message && error.message?.indexOf('FREQUENT_ACTION') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'FREQUENT_ACTION_EMAIL' }).replace("{0}", "3"));
            }
            removeCookie(`c-cooldown-${module}`);
            setCooldown(0);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const initiateTimer = (amount: number) => {
        const cooldownInterval: number = window.setInterval(() => {
            if (amount > 0) {
                setCooldown(amount--);
            } else {
                setCooldown(0);
                clearInterval(cooldownInterval);
            }
        }, 1000);
        setCooldownTimer(cooldownInterval);
    }

    const requestCode = () => {
        if (cooldown === 0) {
            //1 minute cooldown
            setGeneralCookie(`c-cooldown-${module}`, moment().unix().toString(), 179);
            setCooldown(179);
            if (cooldownTimer) {
                clearInterval(cooldownTimer);
            }
            initiateTimer(179);
            getOTP();
        }
    }

    return (
        <div className="request-otp-panel">
            <Input
                placeholder={intl.formatMessage({ id: 'OTP' })}
                maxLength={6}
                minLength={6}
                required={true}
                value={otp}
                onChange={(e: any) => {
                    const val = e.target.value;
                    setOtp(val);
                }}
                style={{ borderRadius: " 8px 0 0 8px" }}
            />
            <Button style={{ width: 'auto', borderRadius: "0 8px 8px 0 " }} block disabled={cooldown > 0} onClick={requestCode}>
                {(!cooldown || cooldown === 0) ?
                    <FormattedMessage id="REQUEST_OTP" /> :
                    <FormattedMessage id="COOL_DOWN_SECOND" values={{ cooldown }} />
                }
            </Button>
        </div>
    );
}

export default RequestEmailOTP;
import { FormattedMessage, useIntl } from "react-intl";
import { Card, Input, QRCode, Tooltip, Button, Form, Upload, UploadFile, Skeleton } from 'antd';
import { CopyOutlined, UploadOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import PreviewImage from "../my-profile/PreviewImage";
import { handleImagePreview } from "../../../shared/helpers/handle-image-preview.helper";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_APPLICATION, GET_APPLICATION_INFO, GET_APPLICATION_WALLET_ADDRESS } from './KYC.gql';
import { SHOW_LOADING, useLayout } from "../../../hooks/layout/LayoutContext";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
    userKyc: any;
    setErrorNotification: any;
    setSuccessNotification: any;
};

const KYCStatusApplication = ({ userKyc, setErrorNotification, setSuccessNotification }: Props) => {
    const [walletAddress, setWalletAddress] = useState('');
    const { layoutDispatch } = useLayout();
    const intl = useIntl();
    const [form] = Form.useForm();
    const [paymentProofImg, setPaymentProofImg] = useState<UploadFile<any>[]>([]);
    const [application, setApplication] = useState();
    const [displayImg, setDisplayImg] = useState({
        previewVisible: false, previewImage: '', previewTitle: ''
    });

    const { data, loading, refetch } = useQuery(GET_APPLICATION_INFO, { fetchPolicy: "network-only" });
    const [getApplicationWalletAddress, { data: addressData }] = useLazyQuery(GET_APPLICATION_WALLET_ADDRESS, { fetchPolicy: "cache-and-network" });
    const [submitApplication, { loading: submitAppLoading, data: appRet, error }] = useMutation(CREATE_APPLICATION);
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (data && data.userApplication) {
            setApplication(data.userApplication);
        } else {
            getApplicationWalletAddress();
        }
        if (!loading) {
            layoutDispatch(SHOW_LOADING);
            setLoading(false);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading]);

    useEffect(() => {
        if (addressData && addressData.walletAddress && addressData.walletAddress.applicationWalletAddress) {
            setWalletAddress(addressData.walletAddress.applicationWalletAddress)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressData])

    const onFinish = (values: any) => {
        let kycInput: any = {}
        if (userKyc) {
            if (paymentProofImg.length > 0 && paymentProofImg[0].originFileObj) {
                kycInput = {
                    paymentProof: paymentProofImg[0].originFileObj
                }
            }

            submitApplication({
                variables: {
                    kycInput
                }
            })
        };
    };

    useEffect(() => {
        if (error) {
            setErrorNotification(intl.formatMessage({ id: `${error.message}` }))
        }
        else if (appRet) {
            if (appRet.userApplication.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: "SUCCESSFULLY_SUBMITTED_APPLICATION" }));
                refetch();
            } else if (appRet.userApplication.status === 'FAIL') {
                setErrorNotification(intl.formatMessage({ id: "FAIL_SUBMIT_APPLICATION" }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appRet, error]);

    const paymentProofFile = async (e: any) => {
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 768,
            useWebWorker: true
        }
        if (e.fileList.length > 0) {
            const compressed = await imageCompression(e.fileList[0].originFileObj, options);
            e.fileList[0].originFileObj = compressed;
        }
        setPaymentProofImg(e.fileList)
        return e && e.fileList;
    };

    const checkImgFileType = (e: any) => {
        if (e.type === 'image/jpeg' || e.type === 'image/png' || e.type === 'image/jpg') {
            return false;
        }
        setErrorNotification(intl.formatMessage({ id: "ACCEPTABLE_IMG_FILE_FORMAT" }));
        return Upload.LIST_IGNORE;
    };

    // Display image preview
    const handlePreview = async (file: any) => {
        file = await handleImagePreview(file);
        setDisplayImg({
            previewVisible: true,
            previewImage: file.url || file.preview,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        });
    };

    // Remove uploaded photo
    const handleRemove = () => {
        setPaymentProofImg([]);
    };

    const validatePaymentProofPhoto = (rule: any, value: any) => {
        return new Promise((resolve, reject) => {
            if (!value || value.length === 0) {
                if (paymentProofImg && paymentProofImg.length && paymentProofImg.length > 0) {
                    resolve(undefined);
                }
                reject(intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }));
            } else {
                Promise.resolve(value)
                    .then((resolvedValue) => {
                        if (resolvedValue.length === 0) {
                            reject(intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }));
                        } else {
                            resolve(resolvedValue);
                        }
                    })
                    .catch(() => {
                        reject(intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }));
                    });
            }
        });
    };


    return (
        <>
            <Skeleton active={isLoading} loading={isLoading} >
                <div className="form-box">
                    {userKyc.status === "PENDING" && !application &&
                        <>
                            <div>
                                <h3><FormattedMessage id="KYC_APPLICATION_FEE_PROOF_UPLOAD" /></h3>
                            </div>
                            <Card size="small" className="application-info-box">
                                <div>
                                    <div className="application-desc">
                                        <div><FormattedMessage id="KYC_APPLICATION_FEE_PROOF_MESSAGE" /></div>
                                        <div className="kyc-items">
                                            <Form
                                                name="application-submission"
                                                form={form}
                                                onFinish={onFinish}
                                                layout={"vertical"}
                                            >
                                                <Form.Item
                                                    key={JSON.stringify(paymentProofImg)}
                                                    getValueFromEvent={paymentProofFile}
                                                    name="paymentProofPhoto"
                                                    required
                                                    rules={[
                                                        { validator: validatePaymentProofPhoto }
                                                    ]}
                                                    label={intl.formatMessage({ id: "UPLOAD_BUTTON" })}
                                                >
                                                    <Upload fileList={paymentProofImg} name="logo" action="" listType={paymentProofImg.length === 1 ? "picture-card" : "picture"} maxCount={1} beforeUpload={checkImgFileType}
                                                        onPreview={handlePreview} showUploadList={{ showRemoveIcon: true }} onRemove={handleRemove}
                                                    >
                                                        {paymentProofImg.length === 1 ? null : <Button icon={<UploadOutlined />}> <FormattedMessage id="UPLOAD_BUTTON" /></Button>}
                                                    </Upload>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        loading={submitAppLoading}
                                                        className={"form-button"}
                                                        type={"primary"} htmlType="submit"
                                                    >
                                                        <FormattedMessage id="SUBMIT_KYC" />
                                                    </Button>
                                                </Form.Item>
                                                <PreviewImage displayImg={displayImg} setDisplayImg={setDisplayImg} />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <div>
                                <h3><FormattedMessage id="WALLET_ADDRESS" /></h3>
                            </div>
                            <Card size="small">
                                <Card.Grid className="grid-card">
                                    {walletAddress && <QRCode
                                        errorLevel="H"
                                        value={walletAddress}
                                    />}
                                </Card.Grid>
                                <Card.Grid className="grid-card">
                                    <Input.Group compact>
                                        <Input
                                            readOnly
                                            style={{ width: '85%' }}
                                            value={walletAddress}
                                        />
                                        <Tooltip title={<FormattedMessage id="COPY" />}>
                                            <CopyToClipboard text={walletAddress} >
                                                <Button icon={<CopyOutlined />} />
                                            </CopyToClipboard>
                                        </Tooltip>
                                    </Input.Group>
                                </Card.Grid>
                            </Card>
                        </>
                    }
                </div>
            </Skeleton>
        </>
    )
};

export default KYCStatusApplication
import { Button, Modal } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
    showModal: boolean;
    title: string;
    confirmationMessage: string;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    action: () => void;
    cancelText?: string;
    actionText?: string;
    cancelAction?: () => void;
    closable?: boolean;
    maskClosable?: boolean;
    disableCancel?: boolean;
    isContentCenter?: boolean;
    isModalCenter?: boolean;
}

const ConfirmationDialog = ({ showModal, title, confirmationMessage, setShowModal,
    action, cancelText, actionText, cancelAction, closable, maskClosable, disableCancel, isContentCenter, isModalCenter }: Props) => {
    const intl = useIntl();
    const [modalWidth, setModalWidth] = useState('520px'); // default width

    const hideModal = () => {
        setShowModal(false);
    };

    const contentStyle: CSSProperties = isContentCenter
        ? { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }
        : {};

    const titleStyle: CSSProperties = isContentCenter ? { textAlign: 'center' } : {};

    useEffect(() => {
        if (window.innerWidth < 576) {
            setModalWidth('250px'); // adjust width for small devices
        } else if (window.innerWidth < 770) {
            setModalWidth('330px'); // adjust width for medium devices
        } else {
            setModalWidth('520px'); // default width for larger devices
        }
    }, []);

    return (
        <Modal
            centered={isModalCenter ?? false}
            width={modalWidth}
            title={title ? (<div style={titleStyle}>{title}</div>) : (intl.formatMessage({ id: 'CONFIRMATION' }))}
            open={showModal}
            onOk={() => { action(); setShowModal(false); }}
            onCancel={cancelAction ?? hideModal}
            closable={closable ?? true}
            maskClosable={maskClosable ?? true}
            footer={
                <>
                    <div style={contentStyle}>
                        {disableCancel ? <></> :
                            <Button key="btn-cancel-confirm-dialog" className="btn-cancel-verify" onClick={cancelAction ?? hideModal} >
                                {cancelText ? cancelText : intl.formatMessage({ id: "BTN_CANCEL" })}
                            </Button>
                        }
                        <Button key="btn-submit-confirm-dialog" className="btn-submit-verify" type="primary" onClick={() => { action(); setShowModal(false); }} >
                            {actionText ? actionText : intl.formatMessage({ id: "BTN_SUBMIT" })}
                        </Button>
                    </div>
                </>
            }
        >
            <div style={contentStyle}>{confirmationMessage}</div>
        </Modal>
    )
}

export default ConfirmationDialog;
import { Button, Modal } from "antd";
import { Dispatch, SetStateAction } from "react";
import { setGeneralCookie } from "../../../shared/helpers/set-general-cookie.helper";

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    displayMsg: { title: string | undefined, submitBtnTitle: string | undefined, attachedText: string | undefined, id: string }
    canClose: boolean | undefined;
}

const PopUpAnnouncements = ({ displayMsg, modalVisible, canClose = true }: Props) => {

    const handleOk = async () => {
        setGeneralCookie(`c-announcement-read`, 'true', 1800);
		modalVisible.setVisible(false);
    };

    return (<>
        <Modal title={displayMsg.title} closable={canClose} visible={modalVisible.visible} onOk={handleOk}
            footer={[<Button key="submit" type="primary" onClick={handleOk}>{displayMsg.submitBtnTitle}</Button>]}>
            <p style={{whiteSpace: "pre-line"}}> {displayMsg.attachedText}</p>
        </Modal>
    </>)
}
export default PopUpAnnouncements;
import { FormattedMessage, useIntl } from "react-intl";
import "./TransferOut.less";
import { Alert, Button, Card, Form, Input } from 'antd';
import { NumberFormatter } from "../../../../shared/helpers/number-format.helper";
import { useLazyQuery, useQuery } from "@apollo/client";
import { SET_CURRENT_USER, useAuth } from "../../../../hooks/auth/AuthContext";
import { useEffect, useState } from "react";
import { GET_WALLET_BALANCE } from "../CashWallet.gql";
import { InfoCircleFilled, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import NumericInput from "../../../../shared/components/numeric-input";
import { useNavigate } from "react-router-dom";
import QRCodeScanner from "../../../../shared/components/qr-code-scanner/QRCodeScanner";
import useNotification from "../../../../hooks/layout/useNotification";
import PageHeader from "../../../../shared/components/page-header/PageHeader";
import { GET_CONFIGURATION } from "../../../../shared/gql/configuration.gql";
import { GET_USER_PROFILE } from "../../../../shared/gql/profile.gql";

const TransferOut = () => {
    const intl = useIntl();
    const { authState, authDispatch } = useAuth();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [walletBal, setWalletBal] = useState(0);
    const [walletId, setWalletId] = useState('');
    const [form] = Form.useForm();
    const [transferAmount, setTransferAmount] = useState<any>(0);
    const [visible, setVisible] = useState(false);
    const { data: walletBalanceData } = useQuery(GET_WALLET_BALANCE, {
        fetchPolicy: "cache-and-network", variables: {
            id: authState.userProfile?.id
        }
    });
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const [getUserProfile, { data, called, refetch }] = useLazyQuery(
        GET_USER_PROFILE, { fetchPolicy: "no-cache", errorPolicy: "all", }
      );
    const [errorMessage, setErrorMessage] = useState<string>();
    const minValue: number = 10;
    const navigate = useNavigate();
    const [exportAccMinTrxAmount, setExportAccMinTrxAmount] = useState(60);

    const { data: exportAccMinTrxData } = useQuery(GET_CONFIGURATION, {
        fetchPolicy: "cache-and-network", variables: {
            id: "burse_export_acc_min_trx"
        }
    });
    
    useEffect(() => {
        if (data) {
          authDispatch({ type: SET_CURRENT_USER, payload: data });
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data]);

    useEffect(() => {
        if (walletBalanceData && walletBalanceData.walletBalance) {
            setWalletBal(walletBalanceData?.walletBalance?.balance);
            setWalletId(walletBalanceData?.walletBalance?.id);
            form.setFieldValue('transferAmount', Number(minValue));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletBalanceData]);

    useEffect(() => {
        if (exportAccMinTrxData && exportAccMinTrxData.configuration && exportAccMinTrxData.configuration.value) {
            setExportAccMinTrxAmount(+exportAccMinTrxData.configuration.value);
        }
    }, [exportAccMinTrxData])

    const patchValue = (amount: any) => {
        let currentValue = form.getFieldValue('transferAmount');
        if (!currentValue) {
            currentValue = 0;
        }

        if (amount > minValue) {
            form.setFieldValue('transferAmount', Number(amount));
        }

        if (amount === minValue) {
            const patchValue = Number(currentValue) + minValue;
            form.setFieldValue('transferAmount', Number(patchValue));
        } else if (amount < minValue && currentValue > minValue) {
            const patchValue = Number(currentValue) - minValue;
            form.setFieldValue('transferAmount', Number(patchValue));
        }
        form.validateFields()
    }

    const onFinish = (values: any) => {
        if(authState && authState.userProfile?.isExported === true && authState.userProfile.trxBalance < exportAccMinTrxAmount){
            setErrorNotification(intl.formatMessage({id:'EXPORT_ACC_INSUFFICIENT_TRX'},{trxAmount:exportAccMinTrxAmount}))
        }else{
        if (values && values.transferAmount) {
            if (+values.transferAmount < minValue) {
                setErrorMessage(intl.formatMessage({ id: 'INVALID_AMOUNT' }, { minTopUpAmount: minValue }))
            } else {
                navigate("/e-wallet/confirmation", {
                    state: {
                        amount: +values.transferAmount,
                        cashWalletBal: walletBal,
                        trcAddress: values.address,
                        walletId
                    }
                });
            }
        } else {
            setErrorMessage(intl.formatMessage({ id: 'AMOUNT_REQUIRED' }))
        }
    }
    };

    const scanNow = () => {
        setVisible(true);
        form.setFieldValue('address', '');
    }

    const onComplete = (value: string) => {
        if (value) {
            form.setFieldValue('address', value);
            setSuccessNotification(intl.formatMessage({ id: "QR_CODE_SCAN_SUCCESSFUL" }));
        }
    }


    const breadcrumbs = [
        {
            text: <FormattedMessage id="E_WALLET" />,
            path: "/e-wallet/overview",
        },
        {
            text: <FormattedMessage id="SEND" />,
        },
    ];

    const reloadBalance = () => {
        setRefreshDisabled(true);
        if(called){ 
            refetch() 
        }
        else{
            getUserProfile();
        }
        setTimeout(() => {
            setRefreshDisabled(false);
        }, 5000);
    };

    return (
        <>
            <PageHeader breadcrumbs={breadcrumbs} ></PageHeader>
            <div className="content-container transfer-out-container">
                <Card className="rule-box disclaimer-box">
                    <div className="disclaimer-title">
                        <InfoCircleFilled className="rule-icon" /> <FormattedMessage id="DISCLAIMER" />
                    </div>
                    <div className="disclaimer-content">
                        <FormattedMessage id="TRANSFER_OUT_DISCLAIMER" />
                        {
                            authState && authState.userProfile?.isExported === true &&
                            <>
                            <br/>
                            <FormattedMessage id="EXPORT_ACC_MIN_TRX" values={{trxAmount:exportAccMinTrxAmount}}  />
                            </>
                        }
                    </div>
                </Card>
                <div className="form-box">
                    <div className="title-bar">
                        <h2><FormattedMessage id="SEND" /></h2>
                        <Button type="primary" onClick={reloadBalance} loading={refreshDisabled} style={{ backgroundColor: '#13C2C2' }}
                        disabled={refreshDisabled}><FormattedMessage id="REFRESH" /></Button>
                    </div>
                    <Card size="small" className="balance-info-box">
                        <div>
                            <h4><FormattedMessage id="FROM" /> <FormattedMessage id="E_WALLET" /></h4>
                            <div className="wallet-desc">
                                <span><FormattedMessage id="AVAILABLE_BAL" /></span>
                                <span>{NumberFormatter(walletBal, 'en-EN', 'USD')}</span>
                            </div>
                            {
                                authState && authState.userProfile?.isExported === true &&
                                <div className="wallet-desc">
                                    <span><FormattedMessage id="TRX_BALANCE" /></span>
                                    <span>{authState.userProfile.trxBalance + " TRX"}</span>
                                </div>
                            }
                        </div>
                    </Card>
                    <div>
                        <h2><FormattedMessage id="TRANSFER_TO" /></h2>
                    </div>
                    <div>
                        <Form
                            form={form}
                            className="transfer-out-input"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div className="address-input">
                                <Form.Item
                                    name={"address"}
                                    rules={[{ required: true, message: intl.formatMessage({ id: "ENTER_VALID_ADDRESS" }) }]}>
                                    <Input id="address" placeholder={intl.formatMessage({ id: "ADDRESS_PLACEHOLDER" })} addonAfter={
                                        <Button size="small" className="scan-button" onClick={scanNow}>
                                            <FormattedMessage id="SCAN_QR" />
                                        </Button>}>
                                    </Input>
                                </Form.Item>
                            </div>
                            {errorMessage && <Alert style={{ marginBottom: 15 }} message={errorMessage} type="error" showIcon />}
                            <div className="amount-input">
                                <Button onClick={() => patchValue(-minValue)} type="default" shape="circle" icon={<MinusOutlined />} />
                                <Form.Item
                                    name="transferAmount"
                                >
                                    <NumericInput style={{ textAlign: 'center', width: "80%" }} value={transferAmount} onChange={setTransferAmount} />
                                </Form.Item>
                                <Button onClick={() => patchValue(minValue)} type="default" shape="circle" icon={<PlusOutlined />} />
                            </div>
                            <Button className="action-button" type="primary" block htmlType="submit">
                                <div><FormattedMessage id="CONTINUE" /></div>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            {visible ? <QRCodeScanner title={intl.formatMessage({ id: "QR_CODE_SCANNER" })} visible={visible} setVisible={setVisible} onComplete={onComplete} /> : null}
        </>
    )
}

export default TransferOut;
import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
  query UserProfile {
    userProfile {
      id
      photoUrl
      firstName
      lastName
      name
      userName
      phone
      phoneVerified
      email
      emailVerified
      gender
      dateOfBirth
      address1
      address2
      state
      city
      postcode
      kycApproved
      isActive
      numberOfWallets
      cashWalletId
      trxWalletId
      requiredPasswordChange
      uplineName
      uplinePhone
      isExported
      trxBalance
      agent{
        id
        level{
          id
          name
          level
        }
        sharesPercentage
        applicationFeeCommission
      }
      kycIndicator
      phoneCountry{
        id 
        name
        dialCode
      }
      isVIP
      VipMember {
        excludeTopUpCharges,
        excludeOutwardCharges
      }
    }
  }
`;

export const GET_PLATFORM_PROFILE = gql`
  query PlatformProfile {
    platformProfile {
      totalCardBalance
      netAssetValue
      totalSpending
      cardAccount {
        id
        cardType
        cardName
        cardNumber
        cardId
        status
        statusText
        cardTemplateUrl
        balance
        validThru
        cvv
      }
    }
  }
`;

export const GET_WALLET_PROFILE = gql`
  query WalletProfile {
    walletProfile {
      totalWalletBalance
      walletAccount {
        walletId
        balance
        amount
        lastTxnType
        lastTxnDate
        usdtAddress
      }
    }
  }
`;

import { Input } from 'antd';

interface NumericInputProps { 
    style: React.CSSProperties;
    value:string;
    onChange:(value:string)=>void;
}

const NumericInput = (props: NumericInputProps) =>{
    const { onChange }= props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const { value: inputValue }= e.target;
        const numRegex = new RegExp(/^[0-9]*$/);

        if(numRegex.test(inputValue)){
            onChange(inputValue);
        }
    }

    return (
        <Input
            {...props}
            onChange={handleChange}
            maxLength={16}
        />
    )
}

export default NumericInput;
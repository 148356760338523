import { InfoCircleFilled, UploadOutlined } from "@ant-design/icons";
import { Form, Button, Card, Upload, UploadFile } from "antd";
import imageCompression from "browser-image-compression";
import passportSample from '../../../assets/images/id_sample.png';
import businessCardSample from '../../../assets/images/business_card_sample.png';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { handleImagePreview } from "../../../shared/helpers/handle-image-preview.helper";
import PreviewImage from "../my-profile/PreviewImage";

interface Props {
    userKyc: any;
    editable: boolean;
    current: number;
    setCurrent: Dispatch<SetStateAction<any>>;
    setErrorNotification: any;
    kycInfo: any;
    setKycInfo: Dispatch<SetStateAction<any>>;
};

const KYCDocument = ({ userKyc, editable, current, setCurrent, setErrorNotification, kycInfo, setKycInfo }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [passportImg, setPassportImg] = useState<UploadFile<any>[]>([]);
    const [assetImg, setAssetImg] = useState<UploadFile<any>[]>([]);
    const [selfieImg, setSelfieImg] = useState<UploadFile<any>[]>([]);
    const [displayImg, setDisplayImg] = useState({
        previewVisible: false, previewImage: '', previewTitle: ''
    });

    const onFinish = (values: any) => {
        setKycInfo({
            ...kycInfo,
            ...(passportImg.length > 0 && passportImg[0].originFileObj && {
                passportPhoto: passportImg[0].originFileObj,
                passportImg: passportImg,
            }),
            ...(selfieImg.length > 0 && selfieImg[0].originFileObj && {
                selfieWithPassportPhoto: selfieImg[0].originFileObj,
                selfieImg: selfieImg,
            }),
            ...(assetImg.length > 0 && assetImg[0].originFileObj && {
                assetProof: assetImg[0].originFileObj,
                assetImg: assetImg,
            }),
            ...(values.passportPhoto && values.passportPhoto.length > 0 && values.passportPhoto[0].originFileObj && {
                passportPhoto: values.passportPhoto[0].originFileObj
            }),
            ...(values.selfiePhoto && values.selfiePhoto.length > 0 && values.selfiePhoto[0].originFileObj && {
                selfieWithPassportPhoto: values.selfiePhoto[0].originFileObj
            }),
            ...(values.assetProof && values.assetProof.length > 0 && values.assetProof[0].originFileObj && {
                assetProof: values.assetProof[0].originFileObj
            }),
        });

        if (values.buttonClicked === 'back') {
            setCurrent(current - 1);
        } else if (values.buttonClicked === 'next') {
            form.validateFields().then(() => {
                setCurrent(current + 1);
            }).catch((errorInfo: any) => {
            });
        }
    }

    useEffect(() => {
        if (userKyc) {
            if (userKyc?.passportUrl && !kycInfo.passportImg) {
                setPassportImg([{
                    uid: '-1',
                    name: 'Passport Photo',
                    status: 'done',
                    url: userKyc?.passportUrl,
                }]);
            }
            if (userKyc?.faceWithPassportUrl && !kycInfo.selfieImg) {
                setSelfieImg([{
                    uid: '-1',
                    name: 'Selfie with Passport Photo',
                    status: 'done',
                    url: userKyc?.faceWithPassportUrl,
                }]);
            }
            if (userKyc?.documentUrl && !kycInfo.assetImg) {
                setAssetImg([{
                    uid: '-1',
                    name: 'Asset Proof',
                    status: 'done',
                    url: userKyc?.documentUrl,
                }]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userKyc]);

    useEffect(() => {
        if (current === 2 && kycInfo) {
            if (kycInfo.passportImg) {
                setPassportImg(kycInfo.passportImg)
            }
            if (kycInfo.selfieImg) {
                setSelfieImg(kycInfo.selfieImg)
            }
            if (kycInfo.assetImg) {
                setAssetImg(kycInfo.assetImg)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, kycInfo]);

    // Check invalid image file type during upload
    const checkImgFileType = (e: any) => {
        if (e.type === 'image/jpeg' || e.type === 'image/png' || e.type === 'image/jpg') {
            return false;
        }
        setErrorNotification(intl.formatMessage({ id: "ACCEPTABLE_IMG_FILE_FORMAT" }));
        return Upload.LIST_IGNORE;
    };

    const checkImgPdfFileType = (e: any) => {
        if (e.type === 'image/jpeg' || e.type === 'image/png' || e.type === 'image/jpg' || e.type === 'application/pdf') {
            if (e.type === 'application/pdf' && e.size > 10000000) {
                setErrorNotification(intl.formatMessage({ id: 'KYC_UPLOAD_PDF_MORE_THAN_10MB' }));
                return Upload.LIST_IGNORE;
            }
            return false;
        }
        setErrorNotification(intl.formatMessage({ id: "ACCEPTABLE_IMG_PDF_FILE_FORMAT" }));
        return Upload.LIST_IGNORE;
    };

    const passportFile = async (e: any) => {
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 768,
            useWebWorker: true
        }
        if (e.fileList.length > 0) {
            const compressed = await imageCompression(e.fileList[0].originFileObj, options);
            e.fileList[0].originFileObj = compressed;
        }
        setPassportImg(e.fileList)
        return e && e.fileList;
    };
    const selfieFile = async (e: any) => {
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 768,
            useWebWorker: true
        }
        if (e.fileList.length > 0) {
            const compressed = await imageCompression(e.fileList[0].originFileObj, options);
            e.fileList[0].originFileObj = compressed;
        }
        setSelfieImg(e.fileList)
        return e && e.fileList;
    };
    const assetFile = async (e: any) => {
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 768,
            useWebWorker: true
        }

        if (e.fileList.length > 0 && e.file && e.file.type &&
            e.file.type !== 'application/pdf') {
            const compressed = await imageCompression(e.fileList[0].originFileObj, options);
            e.fileList[0].originFileObj = compressed;
        }
        setAssetImg(e.fileList)
        return e && e.fileList;
    };

    // Display image preview
    const handlePreview = async (file: any) => {
        file = await handleImagePreview(file);
        setDisplayImg({
            previewVisible: true,
            previewImage: file.url || file.preview,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        });
    };

    const generateKYCFormItem = (file: any, name: string, ruleMessage: any, label: any, fileList: any, samplePic?: any, infoMessage?: string) => {
        return (
            <div className="kyc-items">
                <Form.Item getValueFromEvent={file} name={name}
                    tooltip={infoMessage ?? false}
                    rules={fileList.length < 1 ? [{ required: true, message: ruleMessage }] : []}
                    label={label}>
                    <Upload disabled={!editable} fileList={fileList} name="logo" action="" listType={fileList.length === 1 ? "picture-card" : "picture"} maxCount={1} beforeUpload={name && name === "assetProof" ? checkImgPdfFileType : checkImgFileType}
                        onPreview={handlePreview} showUploadList={{ showRemoveIcon: editable }}
                    >
                        {fileList.length === 1 ? null : <Button icon={<UploadOutlined />}> <FormattedMessage id="UPLOAD_BUTTON" /></Button>}
                    </Upload>
                </Form.Item>
                {samplePic && <img alt={"sample"} className="sample-img" src={samplePic} />}
            </div>
        )
    }

    return (
        <>
            <Form
                onFinish={onFinish}
                name="kyc-document"
                form={form}
                layout={"vertical"}
            >
                <Card className="rule-box">
                    <div className="rule-icon">
                        <InfoCircleFilled />
                    </div>
                    <div>
                        <FormattedMessage id="KYC_RULES" /> <br />
                        <FormattedMessage id="ASSET_PROOF_INFO" /> <br />
                        <FormattedMessage id="DOCUMENT_REMINDER" />
                    </div>
                </Card>
                <div>
                    <div>
                        {generateKYCFormItem(passportFile, "passportPhoto", intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }), [intl.formatMessage({ id: "KYC_PASSPORT_LABEL" })], passportImg, passportSample)}
                        {generateKYCFormItem(selfieFile, "selfiePhoto", intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }), [intl.formatMessage({ id: "KYC_BUSINESS_CARD_LABEL" })], selfieImg, businessCardSample)}
                    </div>
                    {generateKYCFormItem(assetFile, "assetProof", intl.formatMessage({ id: 'KYC_FIELD_REQUIRED' }),
                        (<div dangerouslySetInnerHTML={{
                            __html: `${intl.formatMessage({ id: "KYC_ASSET_PROOF_LABEL" })} ${intl.formatMessage({ id: "ASSET_PROOF" })}`
                        }} />
                        ), assetImg, null)}
                </div>
                <Form.Item>
                    <Button disabled={!editable} className={"btn-back"} type={"default"}
                        onClick={() => onFinish({ ...form.getFieldsValue(), buttonClicked: 'back' })}
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="BTN_BACK" />
                    </Button>
                    <Button disabled={!editable} className={"form-button"} type={"primary"}
                        onClick={() => onFinish({ ...form.getFieldsValue(), buttonClicked: 'next' })}
                        style={{ margin: 4, width: "47%" }}
                    >
                        <FormattedMessage id="BTN_NEXT" />
                    </Button>
                </Form.Item>
            </Form>
            <PreviewImage displayImg={displayImg} setDisplayImg={setDisplayImg} />
        </>
    )
};

export default KYCDocument
import { InfoCircleFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Card, Form, Input } from "antd";
import { encode } from "base-64";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useNotification from "../../../hooks/layout/useNotification";
import { CHANGE_PASSWORD } from "./ChangePassword.gql";
import "./ChangePassword.less";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const pwdRegex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#():;"'<>?/~`.,^}{\-_=+])[A-Za-z\d@$!%*?&#():;"'<>?/~`.,^}{\-_=+]{8,}/);
  const intl = useIntl();
  const [form] = Form.useForm();
  const { setSuccessNotification, setErrorNotification } = useNotification();
  const [changePassword, { data, error, loading }] = useMutation(CHANGE_PASSWORD, {
    errorPolicy: "all",
  });
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    changePassword({
      variables: {
        currentPassword: encode(values.currentPassword),
        newPassword: encode(values.newPassword),
        confirmNewPassword: encode(values.confirmNewPassword),
      },
    });
  };

  useEffect(() => {
    if (error || (data && data.changePassword.status !== "SUCCESS")) {
      if (error && error.message && error.message?.indexOf('INVALID_LOGIN') > -1) {
        setErrorNotification(intl.formatMessage({ id: "CURRENT_PASSWORD_INCORRECT" }))
      } else {
        setErrorNotification(intl.formatMessage({ id: "CHANGE_PASSWORD_FAILED" }));
      }
    } else if (data && data.changePassword.status === "SUCCESS") {
      setSuccessNotification(intl.formatMessage({ id: "CHANGE_PASSWORD_SUCCESSFUL" }));
      form.resetFields();
      navigate("/", { replace: true })
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <>
      <div className="outlet-wrap">
        <div className="title-header-container">
          <div className="title"><FormattedMessage id="CHANGE_PASSWORD" /></div>
        </div>
        <div className="content-container">
          <div className="change-pwd-container">
            <div className="form-box-large">
              <div>
                <h2><FormattedMessage id="CHANGE_PASSWORD" /></h2>
              </div>
              <div className="subtitle">
                <FormattedMessage id="CHANGE_NEW_PASSWORD" />
              </div>
              <div className="change-password-container">
                <Form
                  form={form}
                  layout="vertical"
                  // labelCol={{ span: 12 }}
                  // wrapperCol={{ span: 12 }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="currentPassword"
                    rules={[
                      {
                        pattern: pwdRegex, message: intl.formatMessage({ id: 'INVALID_PWD' })
                      },
                      { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                      { max: 100, message: intl.formatMessage({ id: 'MAX_PASSWORD_LENGTH' }) },
                      {
                        required: true,
                        message: intl.formatMessage({ id: "CURRENT_PASSWORD_REQUIRED" }),
                      },
                    ]}
                    label={intl.formatMessage({ id: "CURRENT_PASSWORD" })}
                    hasFeedback
                  >
                    <Input.Password
                      autoComplete="false"
                      type="password"
                      placeholder={intl.formatMessage({ id: "CURRENT_PASSWORD_PLACEHOLDER" })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="newPassword"
                    rules={[
                      {
                        pattern: pwdRegex, message: intl.formatMessage({ id: 'INVALID_PWD' })
                      },
                      { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                      { max: 100, message: intl.formatMessage({ id: 'MAX_PASSWORD_LENGTH' }) },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && getFieldValue('currentPassword') === value) {
                            return Promise.reject(new Error(intl.formatMessage({ id: "CANNOT_SAME_PASSWORD" })));

                          }
                          return Promise.resolve();
                        },
                      }),
                      {
                        required: true,
                        message: intl.formatMessage({ id: "NEW_PASSWORD_REQUIRED" }),
                      },
                    ]}
                    label={intl.formatMessage({ id: "NEW_PASSWORD" })}
                    hasFeedback
                  >
                    <Input.Password
                      autoComplete="false"
                      type="password"
                      placeholder={intl.formatMessage({ id: "NEW_PASSWORD_PLACEHOLDER" })}
                    />
                  </Form.Item>
                  <Form.Item
                    dependencies={['password']}
                    hasFeedback
                    name="confirmNewPassword"
                    label={intl.formatMessage({ id: "CONFIRM_NEW_PASSWORD" })}
                    rules={[
                      {
                        pattern: pwdRegex, message: intl.formatMessage({ id: 'INVALID_PWD' })
                      },
                      { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                      { max: 100, message: intl.formatMessage({ id: 'MAX_PASSWORD_LENGTH' }) },
                      {
                        required: true,
                        message: intl.formatMessage({ id: "CONFIRM_NEW_PASSWORD_REQUIRED" }),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(intl.formatMessage({ id: "PASSWORD_NOT_MATCH" })));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      autoComplete="false"
                      type="password"
                      placeholder={intl.formatMessage({ id: "CONFIRM_NEW_PASSWORD_PLACEHOLDER" })}
                    />
                  </Form.Item>
                  <Card className="rule-box">
                    <div className="rule-icon">
                      <InfoCircleFilled />
                    </div>
                    <div>
                      <FormattedMessage id="PWD_RULES" />
                    </div>
                  </Card>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      className="form-button"
                    >
                      <FormattedMessage id="CHANGE_PASSWORD" />
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>


  );
};

export default ChangePassword;

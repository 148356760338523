import { Breadcrumb } from 'antd';
import { ReactElement } from 'react';
import { useNavigate } from "react-router-dom";
import './PageHeader.less';
interface Props {
	title?: ReactElement | string;
	breadcrumbs?: any[];
	additionalElements?: ReactElement;
};

const PageHeader = ({ breadcrumbs, additionalElements }: Props) => {
	const navigate = useNavigate();

	const nav = (path: any) => {
		navigate(path);
	};
	const renderBreadcrumb = (
		<Breadcrumb>
			{
				breadcrumbs?.map(val => {
					return val.path ?
						<Breadcrumb.Item key={val.text}>
							<span className='breadcrumb-span' onClick={() => nav(val.path)}>{val.text}</span>
						</Breadcrumb.Item> :
						<Breadcrumb.Item key={val.text}>
							{val.text}
						</Breadcrumb.Item>
				})
			}
		</Breadcrumb>
	)

	return (
		<div className="page-header-wrapper">
			{renderBreadcrumb}
			{additionalElements}
		</div>
	)
}

export default PageHeader;
import { Button, Popover, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import WalletCard from "../../../shared/components/wallet-card/WalletCard";
import { NumberFormatter } from "../../../shared/helpers/number-format.helper";
import "./Card.less";
import { UPDATE_CARD_ACCS, useAuth } from "../../../hooks/auth/AuthContext";
import { useQuery } from "@apollo/client";
import { Outlet } from "react-router-dom";
import Slider from "@ant-design/react-slick";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { EyeOutlined, EyeInvisibleOutlined, ExclamationOutlined } from "@ant-design/icons"
import CardActionButtons from "../../layouts/card-action-buttons/CardActionButtons";
import React from "react";
import { GET_PLATFORM_PROFILE } from "../../../shared/gql/profile.gql";

const Card = () => {
    const { authState, authDispatch } = useAuth();
    const { layoutState } = useLayout();
    const [cardAccountList, setCardAccountList] = useState<any>();
    const { data: platformData, loading: platformLoading } = useQuery(GET_PLATFORM_PROFILE, { fetchPolicy: "cache-and-network" });
    const [isNumberShow, setNumberShow] = useState<boolean>(false);
    const [isLockCard, setIsLockCard] = useState<boolean>(false);
    const [isCardLoad, setCardLoad] = useState<boolean>(true);

    useEffect(() => {
        if (platformData && platformData.length !== 0) {
            authDispatch({
                type: UPDATE_CARD_ACCS,
                payload: {
                    cardAccount: { ...authState.cardAccount, netAssetValue: platformData?.platformProfile?.netAssetValue, totalSpending: platformData?.platformProfile?.totalSpending, totalCardBalance: platformData?.platformProfile?.totalCardBalance, cards: platformData?.platformProfile?.cardAccount }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformData]);

    useEffect(() => {
        if (authState?.cardAccount && authState?.cardAccount?.cards && authState?.cardAccount?.cards?.length > 0) {
            const list = authState?.cardAccount?.cards.map((val: any) => {
                return {
                    ...val,
                    key: val.id,
                };
            });
            setCardAccountList(list);
        } else {
            setCardAccountList([]);
        }
    }, [authState.cardAccount]);

    useEffect(() => {
        setCardLoad(authState.cardAccount?.isLoading!)
    }, [authState.cardAccount?.isLoading]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <>
            <div className="card-wallet-view">
                <div className="card-container">
                    <div className="card-info-container" style={{ marginBottom: 10 }}>
                        <div style={{ color: 'white' }}>
                            {/* <Spin spinning={platformLoading}> */}
                            <div className="balance-justify-wrap">
                                <h2><FormattedMessage id="CARD" /></h2>
                            </div>
                            <div className="balance-wrap non-align">
                                <div>
                                    <div><FormattedMessage id="BALANCE" /></div>
                                    <div className="balance-wording">
                                        <Skeleton loading={platformLoading} active={platformLoading} paragraph={false} style={{ minWidth: 200 }} className="skeleton-style">
                                            {isNumberShow === true ? NumberFormatter(authState?.cardAccount?.totalCardBalance ?? 0, 'en-EN', 'USD') : '$ *****.**'}
                                        </Skeleton>
                                    </div>
                                </div>
                                <div className="reveal-button">
                                    <Button onClick={() => { setNumberShow(!isNumberShow) }} type="primary" className="default-btn-class" shape="circle" icon={isNumberShow === true ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                                </div>
                                <div className="reveal-button-2" style={{ marginTop: 20 }}>
                                    <Button onClick={() => { setNumberShow(!isNumberShow) }} type="primary" className="default-btn-class" shape="circle" icon={isNumberShow === true ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="balance-padding">
                                    <div className="balance-subtitle"><FormattedMessage id="NET_ASSET_VALUE" />
                                        <Popover className="remark-popover" content={<FormattedMessage id="NET_ASSET_REMARK" />} trigger="click">
                                            <Button type="primary" style={{ "marginLeft": 15 }} className="default-btn-class" size="small" shape="circle" icon={<ExclamationOutlined />} />
                                        </Popover>
                                    </div>
                                    <Skeleton loading={platformLoading} active={platformLoading} paragraph={false} className="skeleton-style skeleton-small">
                                        <div className="balance-number">{isNumberShow === true ? NumberFormatter(authState?.cardAccount?.netAssetValue ?? 0, 'en-EN', 'USD') : '$ *****.**'}</div>
                                    </Skeleton>
                                </div>
                                <div className="balance-padding">
                                    <div className="balance-subtitle"><FormattedMessage id="TOTAL_WEEK_SPENDING" /></div>
                                    <Skeleton loading={platformLoading} active={platformLoading} paragraph={false} className="skeleton-style skeleton-small">
                                        <div className="balance-number">{isNumberShow === true ? NumberFormatter(authState?.cardAccount?.totalSpending ?? 0, 'en-EN', 'USD') : '$ *****.**'}</div>
                                    </Skeleton>
                                </div>
                            </div>
                            {/* </Spin> */}
                        </div>
                        <div className="info-button-container" style={{ justifyContent: 'flex-start' }}>
                            <div className="reveal-button" style={{ marginTop: 20 }}>
                                <Button onClick={() => { setNumberShow(!isNumberShow) }} type="primary" className="default-btn-class" shape="circle" icon={isNumberShow === true ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                            </div>
                        </div>
                    </div>
                    {
                        layoutState.dimension?.width <= 768 ?
                            <div className="card-carousel">
                                {
                                    isCardLoad === true ?
                                        <Slider {...settings}>
                                            {
                                                <WalletCard isSkeleton={true} type={'empty'}></WalletCard>
                                            }
                                        </Slider>
                                        :
                                        <>
                                            <Slider {...settings}>
                                                {
                                                    cardAccountList && cardAccountList.length > 0 ?
                                                        cardAccountList.map((card: any) => {
                                                            return (
                                                                <React.Fragment key={card.key}>
                                                                    <WalletCard key={card['id']} type={'card'} cardData={card} isLockCard={isLockCard}></WalletCard>
                                                                    <CardActionButtons setIsLockCard={setIsLockCard} />
                                                                </React.Fragment>
                                                            )
                                                        }) :
                                                        <WalletCard isSkeleton={false} type={'empty'}></WalletCard>
                                                }
                                            </Slider>
                                        </>
                                }

                            </div>
                            :
                            <div id="card-list-scroll" className="card-list-container">
                                {
                                    isCardLoad === true ?
                                        <WalletCard isSkeleton={true} type={'empty'}></WalletCard>
                                        :
                                        <>
                                            {
                                                cardAccountList && cardAccountList.length > 0 ?
                                                    cardAccountList.map((card: any) => {
                                                        return (
                                                            <React.Fragment key={card.key}>
                                                                <WalletCard key={card['id']} type={'card'} cardData={card} isLockCard={isLockCard}></WalletCard>
                                                                <CardActionButtons setIsLockCard={setIsLockCard} />
                                                            </React.Fragment>
                                                        )
                                                    }) :
                                                    <WalletCard isSkeleton={false} type={'empty'}></WalletCard>
                                            }
                                        </>
                                }

                            </div>
                    }

                </div>
                <div style={{ height: '100%', width: '100%' }}>
                    <div className="title-header-container">
                        <div className="title"><FormattedMessage id="CARD" /></div>
                    </div>
                    {/* <PageHeader title={<FormattedMessage id="CARD" />} /> */}
                    <div className="content-container">
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Card
import { useLazyQuery } from '@apollo/client/react/hooks';
import { Button, Col, Modal, Row } from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLayout } from '../../../hooks/layout/LayoutContext';
import { GET_WALLET_TRANSACTION_BY_ID } from '../../../pages/wallets/full-transactions/FullTransactions.gql';
import { NumberFormatter } from '../../helpers/number-format.helper';
import "./TransactionsDtlsModal.less";
import { useAuth } from '../../../hooks/auth/AuthContext';

interface Props {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    txn: any;
}

const TransactionsDtlsModal = ({ showModal, setShowModal, txn }: Props) => {
    const intl = useIntl();
    const { authState } = useAuth();
    const { layoutState } = useLayout();
    const [displayData, setDisplayData] = useState<any>();
    const [getTxnById, { data }] = useLazyQuery(GET_WALLET_TRANSACTION_BY_ID, { fetchPolicy: "cache-and-network", errorPolicy: "all" });
    const labelSpan = layoutState.dimension.width > 576 ? 8 : 24;
    const valueSpan = layoutState.dimension.width > 576 ? 16 : 24;
    useEffect(() => {
        if (!!txn) {
            getTxnById({ variables: { id: txn.id ?? null, type: txn.type ?? null } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [txn]);

    useEffect(() => {
        if (!!data && data.getWalletTransactionbyId !== null) {
            setDisplayData(data.getWalletTransactionbyId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const hideModal = () => {
        setShowModal(false);
    };

    const navigateTronscan = () => {
        if (displayData && displayData?.externalURL !== null) {
            window.open(displayData.externalURL);
        }
    };

    return (
        <Modal
            width={650}
            title={intl.formatMessage({ id: 'TRANSACTION_DTLS' })}
            open={showModal}
            onCancel={hideModal}
            footer={
                <>
                    {
                        txn && (txn.type === 'DEPOSIT' || txn.type === 'TRANSFER_OUT') &&
                        <Button key="btn-cancel-confirm-dialog" className="btn-detail" onClick={navigateTronscan}>
                            {intl.formatMessage({ id: "MORE_DETAILS" })}
                        </Button>
                    }
                    <Button type="primary" key="btn-close-confirm-dialog" className="btn-close-verify" onClick={hideModal} >
                        {intl.formatMessage({ id: "BTN_CLOSE" })}
                    </Button>
                </>
            }
        >
            <Row gutter={8}>
                <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'TRANSACTION_TYPE' })}</Col>
                <Col className='value-col' span={valueSpan}>{displayData?.txnType ? (intl.formatMessage({ id: `${displayData['txnType']}_STATUS` })) : '-'}</Col>
            </Row>
            {
                txn && (txn.type === 'DEPOSIT' || txn.type === 'TRANSFER_OUT') &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'TRANSACTION_ID' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.txnId ? displayData?.txnId : '-'}</Col>
                </Row>
            }
            {
                txn && (txn.type !== 'TRANSFER_OUT_FEE' && txn.type !== 'PROCESSING_FEE') &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'FROM' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.fromAddress ? displayData?.fromAddress : '-'}</Col>
                </Row>
            }
            {
                txn && (txn.type !== 'TRANSFER_OUT_FEE' && txn.type !== 'PROCESSING_FEE') &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'TO' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.fromAddress ? displayData?.toAddress : '-'}</Col>
                </Row>
            }
            <Row gutter={8}>
                <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'AMOUNT' })}</Col>
                <Col className='value-col' span={valueSpan}>{displayData?.amount ? NumberFormatter(displayData?.amount, 'en-EN', "USD") : '-'}</Col>
            </Row>
            {
                txn && (txn.type === 'TOP_UP' || txn.type === 'TRANSFER_OUT') &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'NET_AMOUNT' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.netAmount ? NumberFormatter(displayData?.netAmount, 'en-EN', "USD") : '-'}</Col>
                </Row>
            }
            {
                txn && (txn.type === 'TRANSFER_OUT') &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'TRANSFER_CHARGES' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.transferCharges ? NumberFormatter(displayData?.transferCharges, 'en-EN', "USD") : '-'}</Col>
                </Row>
            }
            {
                txn && (txn.type === 'TOP_UP') &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'SERVICE_CHARGES' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.serviceCharges ? NumberFormatter(displayData?.serviceCharges, 'en-EN', "USD") : '-'}</Col>
                </Row>
            }
            {
                authState && authState.userProfile?.isExported === true && displayData?.status && displayData?.status === "SUCCESS" && displayData?.trxGasFee !== null &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'GAS_FEE' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.trxGasFee > 0 ? displayData?.trxGasFee+" TRX": '-'}</Col>
                </Row>
            }
            {
                txn && (txn.type === 'PROCESSING_FEE' || txn.type === 'TRANSFER_OUT_FEE') &&
                <Row gutter={8}>
                    <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'FEE_TYPE' })}</Col>
                    <Col className='value-col' span={valueSpan}>{displayData?.feeType ? (intl.formatMessage({ id: (txn.type === 'PROCESSING_FEE' ? "SERVICE_CHARGES" : "TRANSFER_CHARGES") })) : '-'}</Col>
                </Row>
            }
            <Row gutter={8}>
                <Col className='label-col' span={labelSpan}>{intl.formatMessage({ id: 'TRANSACTION_DATE' })}</Col>
                <Col className='value-col' span={valueSpan}>{displayData?.createdAt ? format(new Date(displayData?.createdAt), "yyyy-MM-dd, h:mm a") : '-'}</Col>
            </Row>
        </Modal>
    )
}

export default TransactionsDtlsModal;
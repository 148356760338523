import SideNavContent from "./side-nav-content/SideNavContent";
import "./SideNav.less"
import { CloseOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import FullLogo from '../../../assets/logo/logo_frame.svg';
import CollapsedLogo from '../../../assets/icons/collapsed_logo.svg';

const { Sider } = Layout;
interface Props {
    collapsed: boolean;
    setCollapsed: any;
};

const SideNav = ({ collapsed, setCollapsed }: Props) => {
    const toggleSideNav = () => {
        setCollapsed(!collapsed);
      };

    return (
        <Sider
        width={window.innerWidth < 769 ? window.innerWidth : 330}
        collapsible
        collapsed={collapsed}
        collapsedWidth={window.innerWidth < 769 ? 0 : 64}
        trigger={null}
      >
        <div className="side-nav-header">
            <img alt="logo" className={window.innerWidth < 769 ? "full-logo-style" : collapsed ? "collapsed-logo-style": "full-logo-style"} src={window.innerWidth < 769 ? FullLogo : collapsed ? CollapsedLogo: FullLogo} onClick={toggleSideNav}/>
            { window.innerWidth < 769 && <CloseOutlined style={{fontSize:30}} onClick={toggleSideNav}/>}
        </div>
        <SideNavContent collapsed={collapsed} setCollapsed={setCollapsed}/> 
      </Sider>
    );
}

export default SideNav;
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import { LayoutProvider } from './hooks/layout/LayoutContext';
import { AuthProvider } from './hooks/auth/AuthContext';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo-client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <LayoutProvider>
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
      </ApolloProvider>
    </LayoutProvider>
  </CookiesProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

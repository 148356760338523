import { Outlet, useNavigate } from "react-router-dom";
import "./Auth.less";
import { Content } from "antd/lib/layout/layout";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import Logo from "../../assets/logo/logo_frame.svg";
import { useLayout } from "../../hooks/layout/LayoutContext";
import LanguageSwitcher from "../../shared/widget/language-switcher/LanguageSwitcher";

const Auth = () => {
    const navigate = useNavigate();
    const { layoutState } = useLayout();

    const handleChange = () => {
        if (window.location.pathname === '/auth/login') {
            navigate("/auth/sign-up")
        } else {
            navigate("/auth/login")
        }
    };
    return (
        <Content className="auth-content">
            <div className="auth-landing">
                { layoutState.dimension?.width <= 768 ?
                    <div className="language-switcher-box">
                        <LanguageSwitcher/>
                    </div>
                 : null }
                <div className="auth-landing-box">
                    <div className="logoBox">
                        <img src={Logo} className="logoClass" alt="burse-logo" />
                        <FormattedMessage id="LANDING_TAGLINE"></FormattedMessage>
                    </div>
                    {
                        layoutState.dimension?.width <= 768 ?
                            <div className="auth-box">
                                <Outlet></Outlet>
                            </div>
                            :
                            null
                    }

                    <div className="auth-line">
                        {
                            window.location.pathname === '/auth/sign-up' ?
                                <>
                                    <div><FormattedMessage id="ALREADY_HAVE_ACC"></FormattedMessage>
                                    </div>
                                    <div>
                                        <Button onClick={handleChange} type="link" style={{ color: "#13C2C2" }}>
                                            <FormattedMessage id="BACK_TO_LOGIN"></FormattedMessage>
                                        </Button>
                                    </div>
                                </> :
                                <>
                                    <div><FormattedMessage id="REGISTER_HELP"></FormattedMessage>
                                    </div>
                                    <div>
                                        <Button onClick={handleChange} type="link" style={{ color: "#13C2C2" }}>
                                            <FormattedMessage id="REGISTER"></FormattedMessage>
                                        </Button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
            {
                layoutState.dimension?.width > 768 ?
                     <div className="auth-form">
                        <div className="auth-language-switcher">
                            <LanguageSwitcher/>
                        </div>
                        <div className="outlet-container">
                        <Outlet></Outlet>
                        </div>
                    </div>
                :
                null
            }

        </Content>
    );
};

export default Auth;

import { useEffect } from "react";
import { useNavigate} from "react-router-dom"
import { useAuth } from './hooks/auth/AuthContext';

const AgentProtectedRoute = ({children}:any) => {
    const { authState } = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        if(authState.agent && !!!authState.agent?.id){
            navigate("/", { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[authState.agent])

    return children

};

export default AgentProtectedRoute;
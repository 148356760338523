import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Skeleton, Steps } from 'antd';
import useNotification from "../../../hooks/layout/useNotification";
import { CREATE_KYC, GET_KYC_INFO, GET_APPLICATION_INFO } from "./KYC.gql";
import "./KYC.less";
import dayjs from "dayjs";
import { SHOW_LOADING, useLayout } from '../../../hooks/layout/LayoutContext';
import { UPDATE_CURRENT_USER_PROFILE, useAuth } from "../../../hooks/auth/AuthContext";
import KYCPersonalParticulars from "./KYCPersonalParticulars";
import KYCDocument from "./KYCDocument";
import KYCDisclaimer from "./KYCDisclaimer";
import KYCEmploymentDetails from "./KYCEmploymentDetails";
import { FormProvider } from "rc-field-form";
import KYCStatusApplicationModal from "./modals/KYCStatusApplicationModal";
import KYCEmailVerificationModal from "./modals/KYCEmailVerificationModal";
import { useNavigate } from "react-router-dom";
import KYCStatusApplication from "./KYCStatusApplication";

const KYC = () => {
    const { layoutDispatch } = useLayout();
    const { authState, authDispatch } = useAuth();
    const [userKyc, setUserKyc] = useState<any>();
    const [editable, setEditable] = useState<boolean>(true);
    const [application, setApplication] = useState();
    const [visible, setVisible] = useState<boolean>(false);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [confirmation, setConfirmation] = useState({ title: '', message: '', buttonText: '', action: () => { } });

    const [kycInfo, setKycInfo] = useState({
        name: '',
        passportNo: '',
        kycCategory: '',
        dateOfBirth: null,
        address1: '',
        address2: '',
        state: '',
        city: '',
        postcode: '',
        employVisible: true,
        employNext: true,
        employmentStatus: '',
        companyName: '',
        jobTitle: '',
        natureOfBusiness: '',
        annualIncome: '',
        passportPhoto: null,
        selfieWithPassportPhoto: null,
        assetProof: null,
        passportImg: null,
        selfieImg: null,
        assetImg: null,
    });

    const { data, loading, refetch } = useQuery(GET_KYC_INFO, { fetchPolicy: "network-only" });
    const [submitKyc, { loading: submitKycLoading, data: kycRet, error }] = useMutation(CREATE_KYC, { refetchQueries: [{ query: GET_KYC_INFO, }] });
    const [getApplicationInfo, { data: appData, loading: appLoading }] = useLazyQuery(GET_APPLICATION_INFO, { fetchPolicy: "cache-and-network" });
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const intl = useIntl();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (authState.userProfile?.emailVerified === false) {
            setConfirmationInfo();
        } else {
            if (data && data.userKyc) {
                setUserKyc(data.userKyc);
                const userKycData = data.userKyc;
                setEditable(userKycData.status === "REJECTED" ? true : false);
                setIsNewUser(false);
                getApplicationInfo();

                if (data.userKyc.employmentStatus) {
                    if (data.userKyc.employmentStatus === 'SELF_EMPLOYED') {
                        setKycInfo({ ...kycInfo, employVisible: false })
                    } else {
                        setKycInfo({ ...kycInfo, employVisible: true })
                    }
                }
            } else {
                setIsNewUser(true);
            }
            if (!loading) {
                layoutDispatch(SHOW_LOADING);
            } 
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, authState.userProfile]);

    useEffect(() => {
        if (appData && appData.userApplication) {
            setApplication(appData.userApplication);
            setIsNewUser(false);
        } else {
            setIsNewUser(true);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appData])

    useEffect(() => {
        if (!loading && !appLoading) {
            setLoading(false);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, appLoading])

    useEffect(() => {
        if (error) {
            setErrorNotification(intl.formatMessage({ id: `${error.message}` }))
        }
        else if (kycRet && kycRet.userKYC.status === 'SUCCESS') {
            authDispatch({
                type: UPDATE_CURRENT_USER_PROFILE,
                payload: {
                    userProfile: { ...authState.userProfile, kycIndicator: false }
                }
            });
            setSuccessNotification(intl.formatMessage({ id: "SUCCESSFULLY_SUBMITTED_KYC" }))
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycRet, error]);

    const onFinish = (value: any) => {
        let kycInput: any = {}

        if (current === 3) {
            if (authState.userProfile?.emailVerified === false) {
                setConfirmationInfo();
            } else {
                if (kycInfo) {
                    kycInput = {
                        name: kycInfo.name,
                        passportNo: kycInfo.passportNo,
                        kycCategory: kycInfo.kycCategory,
                        dateOfBirth: new Date(dayjs(kycInfo.dateOfBirth).startOf('day').toString()),
                        address1: kycInfo.address1,
                        address2: kycInfo.address2,
                        state: kycInfo.state,
                        city: kycInfo.city,
                        postcode: kycInfo.postcode,
                        employmentStatus: kycInfo.employmentStatus,
                        companyName: kycInfo.companyName,
                        jobTitle: kycInfo.jobTitle,
                        natureOfBusiness: kycInfo.natureOfBusiness,
                        annualIncome: kycInfo.annualIncome,
                        passportPhoto: kycInfo.passportPhoto,
                        selfieWithPassportPhoto: kycInfo.selfieWithPassportPhoto,
                        assetProof: kycInfo.assetProof,
                    }
                }

                submitKyc({
                    variables: {
                        kycInput
                    }
                })
            }
        }
    };

    const renderTitle = () => {
        let title = "";
        if (current === 0) {
            title = "KYC_PERSONAL_PARTICULARS"
        } else if (current === 1) {
            title = "KYC_EMPLOYMENT_DETAILS"
        } else if (current === 2) {
            title = "KYC_YOUR_DOCUMENT"
        } else if (current === 3) {
            title = "KYC_DISCLAIMER"
        }
        return title;
    }

    const renderStepper = () => {
        return (
            <Steps
                current={current}
                items={[
                    {
                        title: intl.formatMessage({ id: 'STEP_1' }),
                        description: intl.formatMessage({ id: 'KYC_PERSONAL_PARTICULARS' }),
                    },
                    {
                        title: intl.formatMessage({ id: 'STEP_2' }),
                        description: intl.formatMessage({ id: 'KYC_EMPLOYMENT_DETAILS' }),
                    },
                    {
                        title: intl.formatMessage({ id: 'STEP_3' }),
                        description: intl.formatMessage({ id: 'KYC_YOUR_DOCUMENT' }),
                    },
                    {
                        title: intl.formatMessage({ id: 'STEP_4' }),
                        description: intl.formatMessage({ id: 'KYC_DISCLAIMER' }),
                    },
                ]}
            />
        );
    };

    const getAlertDescription = () => {
        if (userKyc.status === "APPROVED") {
            return intl.formatMessage({ id: "KYC_APPROVED_MESSAGE" })
        } else {
            if (application) {
                return intl.formatMessage({ id: "KYC_PENDING_MESSAGE" })
            } else {
                return intl.formatMessage({ id: "KYC_PENDING_APPLICATION_PAYMENT_MESSAGE" })
            }
        }
    }

    const setConfirmationInfo = () => {
        setShowModal(true);
        setConfirmation({
            title: intl.formatMessage({ id: "VERIFY_EMAIL" }),
            message: intl.formatMessage({ id: "WARN_VERIFY_EMAIL" }),
            buttonText: intl.formatMessage({ id: "GO_TO_MY_PROFILE" }),
            action: () => navigate("/my-profile", { state: { showVerifyModal: true } })
        });
    }

    const steps = [
        {
            title: 'Personal Particulars',
            content: <KYCPersonalParticulars userKyc={userKyc} editable={editable} current={current} setCurrent={setCurrent} kycInfo={kycInfo} setKycInfo={setKycInfo}></KYCPersonalParticulars>,
        },
        {
            title: 'Employment Details',
            content: <KYCEmploymentDetails userKyc={userKyc} editable={editable} current={current} setCurrent={setCurrent} kycInfo={kycInfo} setKycInfo={setKycInfo}></KYCEmploymentDetails>,
        },
        {
            title: 'Document',
            content: <KYCDocument userKyc={userKyc} editable={editable} current={current} setCurrent={setCurrent} setErrorNotification={setErrorNotification}
                kycInfo={kycInfo} setKycInfo={setKycInfo}></KYCDocument>,
        },
        {
            title: 'Disclaimer',
            content: <KYCDisclaimer userKyc={userKyc} editable={editable} current={current} setCurrent={setCurrent} setErrorNotification={setErrorNotification}
                submitKycLoading={submitKycLoading}></KYCDisclaimer>,
        },
    ]

    return (
        <>
            <div className="outlet-wrap">
                <div className="title-header-container">
                    <div className="title"><FormattedMessage id="KYC" /></div>
                </div>
                <div className="content-container">
                    <div className="kyc-container page-scroll">
                        <div className="form-box-large">
                            <div>
                                <h2><FormattedMessage id="KYC" /></h2>
                            </div>
                            <Skeleton active={isLoading} loading={isLoading} >
                                {isNewUser ? <></>
                                    : (((userKyc && application && userKyc.status !== "APPROVED") &&
                                        (userKyc && userKyc.status === "REJECTED_PAYMENT_SLIP")) ||
                                        (userKyc && userKyc.status === "REJECTED")
                                    ) ?
                                        <div style={{ marginBottom: 16 }}>
                                            <Button className={"form-button"} type="primary" onClick={() => setVisible(true)}
                                            >
                                                {intl.formatMessage({ id: "KYC_UPLOAD_APPLICATION_FEE_PROOF_UPLOAD" })}
                                            </Button>
                                        </div>
                                        : <></>
                                }
                                {!userKyc || (userKyc && userKyc.status && userKyc.status === "REJECTED") ?
                                    <div className="subtitle">
                                        <FormattedMessage id="KYC_SUBTITLE" />
                                    </div>
                                    : <></>
                                }
                                <div className="kyc-content-container">
                                    {/* {userKyc && <Tag color={userKyc.status === "PENDING" ? "orange" : userKyc.status === "REJECTED" ? "red" : "green"} >{intl.formatMessage({ id: `${userKyc.status}` })}</Tag>} */}
                                    {
                                        userKyc && (userKyc.status === "REJECTED" || userKyc.status === "REJECTED_PAYMENT_SLIP") &&
                                        <div className="kyc-alert-container">
                                            <Alert
                                                message={intl.formatMessage({ id: "KYC_REJECTED_MESSAGE" })}
                                                description={userKyc.remarks ?? null}
                                                type="error"
                                                showIcon
                                            />
                                        </div>
                                    }
                                    {!userKyc || (userKyc && userKyc.status === "REJECTED") ?
                                        <>
                                            {renderStepper()}
                                            <div>
                                                <h2><FormattedMessage id={renderTitle()} /></h2>
                                            </div>
                                            <FormProvider
                                                onFormFinish={onFinish}>
                                                <div>{steps[current]?.content}</div>
                                            </FormProvider>
                                        </> : userKyc && userKyc.status && (userKyc.status === "APPROVED" || userKyc.status === "PENDING") ?
                                            <>
                                                <Alert
                                                    message={userKyc.status === "PENDING" ? intl.formatMessage({ id: "PENDING" }) : intl.formatMessage({ id: "APPROVED" })}
                                                    description={getAlertDescription()}
                                                    type={userKyc.status === "PENDING" ? "warning" : "success"}
                                                    showIcon
                                                />
                                                <KYCStatusApplication userKyc={userKyc} setErrorNotification={setErrorNotification} setSuccessNotification={setSuccessNotification}></KYCStatusApplication>
                                            </> : null}
                                    <KYCStatusApplicationModal userKyc={userKyc} visible={visible} setVisible={setVisible} refetchKyc={refetch}
                                        setErrorNotification={setErrorNotification} setSuccessNotification={setSuccessNotification}></KYCStatusApplicationModal>
                                </div>
                            </Skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <KYCEmailVerificationModal showModal={showModal} title={confirmation.title}
                confirmationMessage={confirmation.message}
                setShowModal={setShowModal}
                action={confirmation.action} actionText={confirmation.buttonText}
                closable={false} maskClosable={false} disableCancel={true}
                isContentCenter={true} isModalCenter={true}
            />
        </>
    );
}

export default KYC;
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import tngPng from "../../assets/images/giftcard/dummy_tng.png"
import maxisPng from "../../assets/images/giftcard/dummy_maxis.png"
import celcomPng from "../../assets/images/giftcard/dummy_celcom.png"
import digiPng from "../../assets/images/giftcard/dummy_digi.png"
import grabPng from "../../assets/images/giftcard/dummy_grab.png"
import viewMorePng from "../../assets/images/giftcard/dummy_viewmore.png"
import "./Giftcard.less";
import Slider from "@ant-design/react-slick";
import React from "react";
// import { useLayout } from "../../hooks/layout/LayoutContext";

const Giftcards = () => {
    const dummyData = [
        {
            id: 1,
            isFeatured: true,
            order: 1,
            title: "Touch 'n Go eWallet Reload Gift Card RM50",
            imgUrl: tngPng
        },
        {
            id: 2,
            isFeatured: true,
            order: 3,
            title: "Maxis Reload Gift Card RM50",
            imgUrl: maxisPng
        },
        {
            id: 3,
            isFeatured: true,
            order: 2,
            title: "Celcom Reload Gift Card RM50",
            imgUrl: celcomPng
        },
        {
            id: 4,
            isFeatured: true,
            order: 5,
            title: "Digi Reload Gift Card RM50",
            imgUrl: digiPng
        },
        {
            id: 5,
            isFeatured: true,
            order: 4,
            title: "Grab Reload Gift Card RM50",
            imgUrl: grabPng
        },
        {
            id: 9999,
            isFeatured: true,
            order: 6,
            title: 'View More',
            imgUrl: viewMorePng
        }
    ]
    const [giftcardList, setGiftcardList] = useState<any>([]);
    const navigate = useNavigate();
    // const { layoutState } = useLayout();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        rows: 1,
    };

    useEffect(() => {
        setGiftcardList(dummyData.sort((a, b) => a.order - b.order));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const viewGiftcard = (id: any) => {
        if (id !== 9999) {
            navigate(`/giftcard-details/${id}`);
        }
    };

    return (
        <>
            {
                giftcardList && giftcardList.length > 0 ?
                    <div className="giftcard-box">
                        <div className="giftcard-title"><FormattedMessage id="GIFTCARD" /></div>
                        <Slider {...settings}>
                            {
                                giftcardList.map((card: any) => {
                                    return (
                                        <React.Fragment key={card.id}>
                                            <Card
                                                onClick={() => viewGiftcard(card.id)}
                                                className="giftcard-card"
                                                style={{ backgroundImage: `url(${card.imgUrl})` }}>
                                            </Card>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Slider>

                    </div>
                    :
                    null
            }
        </>
    );
};

export default Giftcards
import { useQuery } from "@apollo/client";
import { Select } from "antd"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GET_ALL_COUNTRIES } from "../../gql/countries.gql";
import "./CountryCode.less";

interface Props {
    countryCode: String;
    setCountryCode: Dispatch<SetStateAction<string>>;
    setDialCode: Dispatch<SetStateAction<string>>;
}
const CountryCode = ({ countryCode, setCountryCode, setDialCode }: Props) => {
    const { Option } = Select;
    const [countryList, setCountryList] = useState([]);
    const urlAccName = process.env.REACT_APP_STORAGE_ACCOUNTNAME ? process.env.REACT_APP_STORAGE_ACCOUNTNAME : '';
    const { data: countryData } = useQuery(GET_ALL_COUNTRIES, {
        fetchPolicy: "cache-and-network", variables: {
            queryInput: {
                openForSignUp: true
            }
        }
    });
    const onCountryCodeChange = (values: any) => {
        setCountryCode(values);
        const selectedCountry = countryList.find((country: any) => {
            return country.id === values
        });

        if (!!selectedCountry) {
            setDialCode(selectedCountry['dialCode']);
        }
    };

    useEffect(() => {
        const countries = countryData && countryData.countries && countryData.countries.length > 0 ? countryData.countries : [];
        setCountryList(countries);
    }, [countryData]);
    return (
            <Select
                onChange={onCountryCodeChange}
                dropdownStyle={{ textAlign: 'left' }}
                value={countryCode}
                className="country-selector"
            >
                {
                            countryList.map((item: any) => {
                                return (<Option key={item.id} value={item.id}>
                                    <div className="country-dropdown">
                                        <div>
                                            <img alt={"country"} className="country-flag" src={`https://${urlAccName}.blob.core.windows.net/flags/${item.id}.svg`} />
                                        </div>
                                        <div className="country-dialcode">
                                            +{item.dialCode}
                                        </div>
                                    </div>
                                </Option>)
                            })
                        }
            </Select>
    )

}

export default CountryCode;
import { useMutation } from "@apollo/client";
import { Form, InputNumber, Input, Button, Alert } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "react-router-dom";
import { setTokenCookie } from "../../shared/helpers/set-token-cookie.helper";
import { CAPTCHA_CODE, SIGN_IN } from "./Auth.gql";
import { SET_LOGIN_STATUS, useAuth } from "../../hooks/auth/AuthContext";
import { Buffer } from 'buffer';
import CountryCode from "../../shared/components/country-code/CountryCode";
import Captcha from "./Captcha";
import cuid from "cuid";

const LoginComponent = () => {
    const intl = useIntl();
    const { authDispatch } = useAuth();
    const [error, setError] = useState<string>();
    const [countryCode, setCountryCode] = useState('MY');
    const [dialCode, setDialCode] = useState('60');
    const [signIn, { data, loading, error: signInError }] = useMutation(SIGN_IN, {
        errorPolicy: "all",
        fetchPolicy: "network-only"
    });
    const [getCaptchaCode, { data: getCaptchaCodeData }] = useMutation(CAPTCHA_CODE, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [sessionId, setSessionId] = useState<string>('sessionId');
    const [captcha, setCaptcha] = useState<string>('8888');

    useEffect(() => {
        generateCaptcha();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getCaptchaCodeData && getCaptchaCodeData.captchaCode) {
            if (getCaptchaCodeData.captchaCode.status === 'SUCCESS') {
                setCaptcha(getCaptchaCodeData.captchaCode.id);
            } else {
                setError('GENERAL_ERROR');
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCaptchaCodeData])

    const onFinish = (values: any) => {
        if (sessionId && values.captcha) {
            signIn({
                variables: {
                    signInInput: {
                        userName: `+${dialCode}${values.phone}`,
                        password: Buffer.from(values.password).toString('base64'),
                        sessionId,
                        authCode: values.captcha
                    }
                },
            });
        } else {
            setError('INVALID_CAPTCHA');
        }
    };

    useEffect(() => {
        if (data && data.signIn) {
            const { a, r, sid } = data.signIn;
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || "bf-at", a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || "bf-rt", r);
            setTokenCookie("bf-sid", sid);
            authDispatch({ type: SET_LOGIN_STATUS, payload: true });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (signInError && signInError.message) {
            setError(signInError.message);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError]);

    const generateCaptcha = () => {
        const sessionId: any = cuid();
        setSessionId(sessionId);
        getCaptchaCode({
            variables: {
                sessionId
            }
        });
    }

    return (
        <>
            <div className="auth-container">
                <div className="auth-title">
                    <FormattedMessage id="LOGIN_TITLE" />
                    <div className="sm-title">
                        <FormattedMessage id="LOGIN_SUBTITLE" />
                    </div>
                </div>
                <Form
                    name="login_form"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    {error ? (
                        <Alert
                            className="login-error"
                            message={intl.formatMessage({ id: `${error}` })}
                            type="error"
                        ></Alert>
                    ) : null}
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: "VALIDATION_REQUIRED" }),
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder={intl.formatMessage({ id: "PHONE" })} addonBefore={CountryCode({ countryCode, setCountryCode, setDialCode })} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: "PASSWORD_REQUIRED" }),
                            },
                        ]}
                    >
                        <Input.Password
                            autoComplete="false"
                            type="password"
                            placeholder={intl.formatMessage({ id: "PASSWORD_TITLE" })}
                        />
                    </Form.Item>
                    <Captcha value={captcha} refresh={generateCaptcha} />
                    <Form.Item>
                        <Link className="login-form-forgot" to="/auth/forgot-password">
                            <FormattedMessage id="FORGOT_PASSWORD" />
                        </Link>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={loading}
                        >
                            <FormattedMessage id="LOGIN_BTN" />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default LoginComponent
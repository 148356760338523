import { useAuth } from "../../../hooks/auth/AuthContext";
import "./ProfileDashboard.less";
import { FormattedMessage } from "react-intl";
import { Divider, Spin, Button, Skeleton } from 'antd';
import { NumberFormatter } from "../../../shared/helpers/number-format.helper";
import { useEffect, useState } from "react";
import WalletCard from "../../../shared/components/wallet-card/WalletCard";
import Slider from "@ant-design/react-slick";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"
import CardActionButtons from "../../layouts/card-action-buttons/CardActionButtons";

const ProfileDashboard = () => {
    const { authState } = useAuth();
    const { layoutState } = useLayout();
    const [walletList, setWalletList] = useState<any>([]);
    const [cardList, setCardList] = useState<any>([]);
    const navigate = useNavigate();
    const [cardLoading, setCardLoading] = useState<boolean>(true);
    const [walletLoading, setWalletLoading] = useState<boolean>(true);
    const [isNumberShow, setNumberShow] = useState<boolean>(false);
    const [isLockCard, setIsLockCard] = useState<boolean>(false);
    const [isCardLoad, setCardLoad] = useState<boolean>(true);

    const eWalletClick = () => {
        navigate("/e-wallet/overview");
    }

    useEffect(() => {
        setCardLoad(authState.cardAccount?.isLoading!)
    }, [authState.cardAccount?.isLoading]);

    useEffect(() => {
        setWalletLoading(authState.walletAccount?.isLoading!)
    }, [authState.walletAccount?.isLoading]);

    useEffect(() => {
        if (authState?.cardAccount && authState?.cardAccount?.cards) {
            const list = authState?.cardAccount?.cards.map((val: any) => {
                return {
                    ...val,
                    key: val.id
                };
            });
            setCardList(list.length > 0 ? list : []);
        }
        setCardLoading(false);

    }, [authState?.cardAccount]);

    useEffect(() => {
        if (authState?.walletAccount && authState?.walletAccount?.wallets) {
            const list = authState?.walletAccount?.wallets.map((val: any) => {
                return {
                    ...val,
                    key: val.walletId
                };
            });
            setWalletList(list.length > 0 ? list : []);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.walletAccount]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };
    return (
        <>
            <div className="card-container">
                <div className="card-info-container" style={{ flexDirection: 'column' }}>
                    {/* Temp commented */}
                    {/* <div className="icons-row">
                        <BellOutlined className="icon" />
                        <RedEnvelopeFilled className="icon" />
                    </div> */}
                    <div className="user-information">
                        <div className="balance-info-box" onClick={eWalletClick}>
                            <FormattedMessage id="WALLET_BALANCE" />
                            {/* <Spin spinning={walletLoading}> */}
                            {
                                isNumberShow === true ?
                                    walletList && walletList.length > 0 ?
                                        <Skeleton loading={walletLoading} active={walletLoading} paragraph={false} style={{ minWidth: 200 }} className="skeleton-style">
                                            {
                                                walletList.map((w: any, index: any) => {
                                                    return (
                                                        <div key={index} className="bold-text">
                                                            {w.balance ? NumberFormatter(w.balance, 'en-EN', 'USD') : NumberFormatter(0, 'en-EN', 'USD')}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Skeleton>
                                        :
                                        <div className="bold-text">
                                            {NumberFormatter(0, 'en-EN', 'USD')}
                                        </div>
                                    :
                                    <div className="bold-text">
                                        $ *****.**
                                    </div>

                            }
                            {/* </Spin> */}
                        </div>
                        <div className="reveal-button home-reveal">
                            <Button onClick={() => { setNumberShow(!isNumberShow) }} type="primary" className="default-btn-class" shape="circle" icon={isNumberShow === true ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                        </div>
                    </div>
                    <Divider className="divider" />
                    {
                        layoutState.dimension?.width <= 768 ?
                            <div className="card-carousel">
                                {
                                    isCardLoad === true ?
                                        <Slider {...settings}>
                                            {
                                                <WalletCard isSkeleton={true} type={'empty'}></WalletCard>

                                            }
                                        </Slider>
                                        :
                                        <Slider {...settings}>
                                            {
                                                cardList && cardList.length > 0 ?
                                                    cardList.map((card: any) => {
                                                        return (
                                                            <React.Fragment key={card.key}>
                                                                <div className="wallet-balance-box">
                                                                    <FormattedMessage id="CARD_BALANCE" />
                                                                    {
                                                                        isNumberShow === true ?
                                                                            <div className="balance-text">
                                                                                {card.balance ? NumberFormatter(card.balance, 'en-EN', 'USD') : NumberFormatter(0)}
                                                                            </div>
                                                                            :
                                                                            <div className="balance-text">
                                                                                $ *****.**
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <WalletCard key={card.key} type={'card'} cardData={card} isLockCard={isLockCard}></WalletCard>
                                                                <CardActionButtons setIsLockCard={setIsLockCard} />
                                                            </React.Fragment>
                                                        )
                                                    }) :
                                                    <WalletCard isSkeleton={false} type={'empty'}></WalletCard>
                                            }
                                        </Slider>
                                }
                            </div>
                            :
                            <Spin spinning={cardLoading}>
                                <div id="card-list-scroll" className="card-list-container">
                                    {
                                        isCardLoad === true ?
                                            <WalletCard isSkeleton={true} type={'empty'}></WalletCard>
                                            :
                                            <>
                                                {
                                                    cardList && cardList.length > 0 ?
                                                        cardList.map((card: any) => {
                                                            return (
                                                                <React.Fragment key={card.key}>
                                                                    <div className="wallet-balance-box">
                                                                        <FormattedMessage id="CARD_BALANCE" />
                                                                        {
                                                                            isNumberShow === true ?
                                                                                <div className="balance-text">
                                                                                    {card.balance ? NumberFormatter(card.balance, 'en-EN', 'USD') : NumberFormatter(0)}
                                                                                </div>
                                                                                :
                                                                                <div className="balance-text">
                                                                                    $ *****.**
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <WalletCard key={card.key} type={'card'} cardData={card} isLockCard={isLockCard}></WalletCard>
                                                                    <CardActionButtons setIsLockCard={setIsLockCard} />
                                                                </React.Fragment>
                                                            )
                                                        }) :
                                                        <WalletCard isSkeleton={false} type={'empty'}></WalletCard>
                                                    }
                                            </>
                                    }
                                </div>
                            </Spin>
                    }
                </div>
            </div>
        </>
    );
};

export default ProfileDashboard;
import { Button, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import WalletCard from "../../../shared/components/wallet-card/WalletCard";
import { NumberFormatter } from "../../../shared/helpers/number-format.helper";
import "./CashWallet.less";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Slider from "@ant-design/react-slick";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"

const CashWallet = () => {
    const { authState } = useAuth();
    const { layoutState } = useLayout();
    const [walletList, setWalletList] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isNumberShow, setNumberShow] = useState<boolean>(false);

    useEffect(() => {
        if (authState?.walletAccount && authState?.walletAccount?.wallets) {
            const list = authState?.walletAccount?.wallets.map((val: any) => {
                return {
                    ...val,
                    key: val.walletId
                };
            });

            setWalletList(list.length > 0 ? list : []);
        }
    }, [authState?.walletAccount]);

    useEffect(() => {
        setLoading(authState.walletAccount?.isLoading!)
    }, [authState.walletAccount?.isLoading]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <>
            <div className="cash-wallet-view page-scroll">
                <div className="card-container">
                    <div className="card-info-container card-wrap">
                        <div>
                            <div className="balance-justify-wrap">
                                <h2><FormattedMessage id="E_WALLET" /></h2>
                            </div>
                                <div className="balance-wrap">
                                    <div>
                                        <div><FormattedMessage id="BALANCE" /></div>
                                        <div className="balance-wording">
                                            <Skeleton loading={isLoading} active={isLoading} paragraph={false} style={{ minWidth: 200 }} className="skeleton-style">
                                                {isNumberShow === true ? NumberFormatter(authState?.walletAccount?.totalWalletBalance ?? 0, 'en-EN', 'USD') : '$ *****.**'}
                                            </Skeleton>
                                        </div>
                                    </div>
                                    <div className="reveal-button">
                                        <Button onClick={() => { setNumberShow(!isNumberShow) }} type="primary" className="default-btn-class" shape="circle" icon={isNumberShow === true ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                                    </div>
                                    <div className="reveal-button-2" style={{ marginTop: 20 }}>
                                        <Button onClick={() => { setNumberShow(!isNumberShow) }} type="primary" className="default-btn-class" shape="circle" icon={isNumberShow === true ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                                    </div>
                                </div>
                        </div>
                        <div className="info-button-container">
                            <div className="reveal-button">
                                <Button onClick={() => { setNumberShow(!isNumberShow) }} type="primary" className="default-btn-class" shape="circle" icon={isNumberShow === true ? <EyeInvisibleOutlined /> : <EyeOutlined />} />
                            </div>
                        </div>
                    </div>
                    {
                        layoutState.dimension?.width <= 768 ?
                            <div className="card-carousel">
                                {
                                    isLoading === true ?
                                        <Slider {...settings}>
                                            {
                                                <WalletCard isSkeleton={true} type={'empty'}></WalletCard>
                                            }
                                        </Slider>
                                        :
                                        <Slider {...settings}>
                                            {
                                                walletList && walletList.length > 0 ?
                                                    walletList.map((card: any) => {
                                                        return <WalletCard key={card.key} type={'cash'} cardData={card}></WalletCard>
                                                    }) :
                                                    null
                                            }
                                        </Slider>
                                }
                            </div>
                            :
                            <div id="card-list-scroll" className="card-list-container">
                                {
                                    isLoading === true ?
                                        <WalletCard isSkeleton={true} type={'empty'}></WalletCard>
                                        :
                                        walletList && walletList.length > 0 ?
                                            walletList.map((card: any) => {
                                                return <WalletCard key={card.key} type={'cash'} cardData={card}></WalletCard>
                                            }) :
                                            null

                                }
                            </div>
                    }

                </div>
                <div className="page-content">
                    <div className="title-header-container">
                        <div className="title"><FormattedMessage id="E_WALLET" /></div>
                    </div>
                    <div className="content-container">
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CashWallet
import { InfoCircleFilled } from "@ant-design/icons";
import { Form, Button, Input, Card } from "antd";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
    onFinish: (values: any) => void;
    backStep: (step: any) => void;
    pwdRegex: any;
};

const SignUpPwd = ({ onFinish, pwdRegex, backStep }: Props) => {
    const intl = useIntl();

    return (
        <>
            <div className="auth-container auth-signup">
                <div className="auth-title">
                    <FormattedMessage id="PASSWORD_TITLE" />
                    <div className="sm-title">
                        <FormattedMessage id="PASSWORD_SUBTITLE" />
                    </div>
                </div>
                <Form
                    layout="vertical"
                    name="password-form"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                pattern: pwdRegex, message: intl.formatMessage({ id: 'INVALID_PWD' })
                            },
                            { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                            {
                                required: true,
                                message: intl.formatMessage({ id: "PASSWORD_REQUIRED" }),
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            autoComplete="false"
                            type="password"
                            placeholder={intl.formatMessage({ id: "PASSWORD_TITLE" })}
                        />
                    </Form.Item>
                    <Form.Item
                        dependencies={['password']}
                        hasFeedback
                        name="confirmPassword"
                        rules={[
                            {
                                pattern: pwdRegex, message: intl.formatMessage({ id: 'INVALID_PWD' })
                            },
                            { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                            {
                                required: true,
                                message: intl.formatMessage({ id: "CONFIRM_PASSWORD_REQUIRED" }),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(intl.formatMessage({ id: "PASSWORD_NOT_MATCH" })));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            autoComplete="false"
                            type="password"
                            placeholder={intl.formatMessage({ id: "CONFIRM_PASSWORD_PLACEHOLDER" })}
                        />
                    </Form.Item>
                    <Card className="rule-box">
                        <div style={{ paddingRight: 5, color: '#1890FF' }}>
                            <InfoCircleFilled />
                        </div>
                        <div>
                            <FormattedMessage id="PWD_RULES" />
                        </div>
                    </Card>
                    <Form.Item>
                        <div className="info-form-button-wrap">
                            <Button
                                type="primary"
                                className="info-form-cancel-button"
                                onClick={() => backStep(0)}
                            >
                                <FormattedMessage id="BTN_CANCEL" />
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                <FormattedMessage id="SIGN_UP_BTN" />
                            </Button>

                        </div>
                    </Form.Item>
                </Form>

            </div>
        </>
    )
};

export default SignUpPwd
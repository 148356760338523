import { gql } from "@apollo/client";

export const GET_FULL_WALLET_TRANSACTIONS = gql`
  query FullWalletTransactions($pageNo: Int, $transactionsQueryInput: TransactionsQueryInput) {
    fullWalletTransactions(pageNo: $pageNo, transactionsQueryInput: $transactionsQueryInput) {
      total
      txnList {
        id
        description
        date
        amount
        currency
        status
        foreignAmount
        foreignCurrency
      }
    }
  }
`;

export const GET_WALLET_TRANSACTION_BY_ID = gql`
  query WalletTransactionById($id: String!, $type: String){
    getWalletTransactionbyId(
      id: $id, type: $type
    ) {
      txnType
      txnId
      createdAt
      amount
      netAmount
      serviceCharges
      transferCharges
      fromAddress
      toAddress
      externalURL
      feeType
      trxGasFee
      status
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { Button, Card, Empty, List, Skeleton, Tag, Spin } from "antd";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UPDATE_WALLET_ACCS, useAuth } from "../../../../hooks/auth/AuthContext";
import { NumberFormatter } from "../../../../shared/helpers/number-format.helper";
import DepositIcon from '../../../../assets/icons/deposit_icon.svg';
import TopUpCashColor from '../../../../assets/icons/topup-cash-color.svg';
import "./CashWalletTransactions.less";
import { CHECK_DEPOSIT } from "../../../../shared/gql/check-deposit.gql";
import TopUpVISAColor from '../../../../assets/icons/topup-visa-color.svg';
import TransferOut from '../../../../assets/icons/transfer-out.svg';
import { useNavigate } from "react-router-dom";
import { GET_WALLET_TRANSACTIONS } from "../CashWallet.gql";
import { GET_WALLET_PROFILE } from "../../../../shared/gql/profile.gql";
import TransactionsDtlsModal from "../../../../shared/components/txn-details-modal/TransactionsDtlsModal";

const CashWalletTransactions = () => {
    const intl = useIntl();
    const { authState, authDispatch } = useAuth();
    const navigate = useNavigate();
    const [transactionList, setTransactionList] = useState([]);
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const [isEmpty, setIsEmpty] = useState(Boolean);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [selectedTxn, setSelectedTxn] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [getWalletProfile, { data: walletProfileData }] = useLazyQuery(
        GET_WALLET_PROFILE, { fetchPolicy: "no-cache", errorPolicy: "all", }
    );
    const [getWalletData, { data: walletData, refetch, loading, called }] = useLazyQuery(
        GET_WALLET_TRANSACTIONS,
        {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        }
    );
    const [checkDeposit, { data: checkDepositData, loading: depositLoading }] = useLazyQuery(CHECK_DEPOSIT,
        {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        }
    );

    useEffect(() => {
        if (loading || depositLoading)
            setLoading(true);
        else
            setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading || depositLoading]);

    useEffect(() => {
        if (authState.userProfile?.cashWalletId) {
            getWalletData({ variables: { walletId: authState.userProfile?.cashWalletId } });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.userProfile?.cashWalletId]);

    useEffect(() => {
        if (walletData && walletData.walletTransactions) {
            const list = walletData.walletTransactions.map((val: any) => {
                return {
                    ...val,
                    key: val.id
                };
            });
            setTransactionList(list)
            if (list.length > 0) {
                setIsEmpty(false);
            } else {
                setIsEmpty(true);
            }
        }
    }, [walletData]);

    useEffect(() => {
        if (walletProfileData && walletProfileData.length !== 0) {
            authDispatch({
                type: UPDATE_WALLET_ACCS,
                payload: {
                    walletAccount: { ...authState.walletAccount, totalWalletBalance: walletProfileData?.walletProfile?.totalWalletBalance, wallets: walletProfileData?.walletProfile?.walletAccount }
                }
            });
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletProfileData]);

    useEffect(() => {
        refetch();
        getWalletProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkDepositData]);

    const refreshList = () => {
        setRefreshDisabled(true);
        checkDeposit();
        setTimeout(() => {
            setRefreshDisabled(false);
        }, 5000);
    };

    const walletAction = (type: any) => {
        if (type === 'receive') {
            navigate("/e-wallet/deposit")
        } else if (type === 'reload') {
            navigate("/card/top-up")
        } else if (type === 'send') {
            navigate("/e-wallet/transfer-out")
        }
    };

    const viewDetails = (item: any) => {
        setSelectedTxn({id: item.id, type: item.transactionType})
        setShowModal(true);
    };


    const loadMore =
        <div className="load-more-style">
            <Button onClick={() => navigate("/e-wallet-transaction-history")}><FormattedMessage id="FULL_HISTORY" /></Button>
        </div>;

    return (
        <>
            <div id="card-top-nav-scroll" className="cash-wallet-top-nav">
                <Card hoverable bordered={false} onClick={() => { walletAction('reload') }} className="top-nav-card">
                    <img alt="topup-visa-color" className="card-menu-button" src={TopUpVISAColor} />
                    <div className="top-nav-content">
                        <div><FormattedMessage id="RELOAD" /></div>
                    </div>
                </Card>
                <Card hoverable bordered={false} onClick={() => { walletAction('send') }} className="top-nav-card">
                    <img alt="topup-cash-color" className="card-menu-button" src={TransferOut} />
                    <div className="top-nav-content">
                        <div><FormattedMessage id="SEND" /></div>
                    </div>
                </Card>
                <Card hoverable bordered={false} onClick={() => { walletAction('receive') }} className="top-nav-card">
                    <img alt="topup-cash-color" className="card-menu-button" src={TopUpCashColor} />
                    <div className="top-nav-content">
                        <div><FormattedMessage id="RECEIVE" /></div>
                    </div>
                </Card>
            </div>
            <div className="transaction-box">
                <div className="title-bar">
                    <h2><FormattedMessage id="RECENT_TRANSACTION" /></h2>
                    <Button type="primary" onClick={refreshList} loading={refreshDisabled} className="default-btn-class"
                        disabled={refreshDisabled}><FormattedMessage id="REFRESH" /></Button>
                </div>
                <Card size="small" className="card-list">
                    <div className="deposit-list">
                        {
                            transactionList && transactionList.length > 0 ?
                                <List
                                    itemLayout="horizontal"
                                    loadMore={loadMore}
                                    dataSource={transactionList}
                                    renderItem={(item: any) => (
                                        <Skeleton avatar title={false} loading={called ? isLoading : true} active className="list-skele-large">
                                            <List.Item className="txn-item"  onClick={() => viewDetails(item)}>
                                                <List.Item.Meta
                                                    avatar={<img alt="depositIcon" className="icon-style" src={DepositIcon} />}
                                                    title={item['transactionType'] ? intl.formatMessage({ id: `${item['transactionType']}_STATUS` }) : '-'}
                                                    description={
                                                        <>
                                                            <Tag color={'green'}><FormattedMessage id="SUCCESS" /></Tag>
                                                            <span className="txn-desc">{format(new Date(item['createdAt']), "yyyy-MM-dd, h:mm a")}</span>
                                                        </>
                                                    }

                                                />
                                                <hr className="list-hr" />
                                                <div className="info-span">
                                                    <span className={item['transactionType'] && item['transactionType'].includes('DEPOSIT') ? "txn-currency-deposit" : "txn-currency-transfer"}>
                                                        <span className={item['transactionType'] && item['transactionType'].includes('DEPOSIT') ? "txn-currency-deposit-sign" : "txn-currency-transfer-sign"}>
                                                            {item['transactionType'] && item['transactionType'].includes('DEPOSIT') ? "+" : "-"}
                                                        </span>
                                                        {NumberFormatter(item['amount'], 'en-EN', 'USD')}
                                                    </span>
                                                </div>
                                            </List.Item>
                                        </Skeleton>
                                    )}
                                />
                                :
                                <div className="empty-box">
                                    {
                                        isEmpty === true ?
                                            <Empty />
                                            :
                                            <Spin spinning={true}></Spin>

                                    }
                                </div>
                        }
                    </div>
                </Card>
            </div>
            <TransactionsDtlsModal showModal={showModal} setShowModal={setShowModal} txn={selectedTxn ?? null} />
        </>
    )

};

export default CashWalletTransactions;
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import OtpInput from "react-otp-input";
interface Props {
    handleOTP: (otp: any) => void;
    backStep: (step: any) => void;
    resendOTP: () => void;
    dialCode: any;
    registerPhoneNo: any;
    isOTPError: any;
    OTP: any;
    isResendDisabled: any;
};

const SignUpOTP = ({ handleOTP, resendOTP, backStep, dialCode, registerPhoneNo, isOTPError, OTP, isResendDisabled }: Props) => {
    return (
        <>
            <div className="auth-container auth-signup">
                <div className="auth-title">
                    <FormattedMessage id="VERIFICATION" />
                    <div className="sm-title">
                        <FormattedMessage id="VERIFICATION_SUBTITLE" /> +{dialCode}-{registerPhoneNo}
                    </div>
                </div>
                <div className="otp-box">
                    <OtpInput className="otp-input"
                        errorStyle={
                            {
                                borderColor: '#EE4B2B'
                            }
                        }
                        hasErrored={isOTPError}
                        value={OTP}
                        onChange={handleOTP}
                        numInputs={6}
                        separator={<span>-</span>}
                        isInputNum={true}
                    />
                    <div className="otp-help-box">
                        <div><FormattedMessage id="OTP_HELP"></FormattedMessage>
                        </div>
                        <div>
                            <Button onClick={resendOTP} disabled={isResendDisabled} type="link">
                                <FormattedMessage id="OTP_RESEND"></FormattedMessage>
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            className="form-button"
                            onClick={() => backStep(0)}
                        >
                            <FormattedMessage id="BTN_BACK" />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default SignUpOTP
import { FormattedMessage, useIntl } from "react-intl";
import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "./Auth.gql";
import { useEffect, useState } from "react";
import CountryCode from "../../shared/components/country-code/CountryCode";
import { GET_OTP, OTP_VERIFICATION } from "../../shared/gql/request-otp.gql";
import OtpInput from "react-otp-input";
import ConfirmationDialog from "../../shared/components/confirmation-modal/ConfirmationModal";
import { Buffer } from 'buffer';
import useNotification from "../../hooks/layout/useNotification";

const ForgotPassword = () => {
    const pwdRegex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#():;"'<>?/~`.,^}{\-_=+])[A-Za-z\d@$!%*?&#():;"'<>?/~`.,^}{\-_=+]{8,}/);
    const intl = useIntl();
    const navigate = useNavigate();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [current, setCurrent] = useState(0);
    const [countryCode, setCountryCode] = useState('MY');
    const [dialCode, setDialCode] = useState('60');
    const [registerPhoneNo, setRegisterPhoneNo] = useState();
    const [showModal, setShowModal] = useState(false);
    const [isOTPError, setOTPError] = useState(false);
    const [isResendDisabled, setResendDisabled] = useState(true);
    const [OTP, setOTP] = useState();
    const [resetPasswordBody, setResetPasswordBody] = useState<any>({});
    const [getOTP, { data: dataOTP, error: errorOTP }] = useMutation(GET_OTP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [otpVerification, { data: otpData, error: otpError }] = useMutation(OTP_VERIFICATION, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [resetPassword, { data: resetPasswordData, error: resetPasswordError }] =
        useMutation(RESET_PASSWORD, {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        });
    const onFinish = (values: any) => {
        if (current === 0) {
            if (dialCode && values.phone) {
                setRegisterPhoneNo(values.phone);
                getOTP(
                    {
                        variables: {
                            phone: `+${dialCode}${values.phone}`,
                            isPhoneCheck: false
                        },
                    }
                );
            }
        } else if (current === 2) {
            const encodePwd = Buffer.from(values.password).toString('base64')
            const encodeConfirmPwd = Buffer.from(values.confirmPassword).toString('base64')
            if (encodePwd && encodeConfirmPwd && dialCode && registerPhoneNo) {
                setResetPasswordBody({
                    'newPassword': encodePwd,
                    'confirmNewPassword': encodeConfirmPwd,
                    'userName': `+${dialCode}${registerPhoneNo}`
                });
                setShowModal(true);
            }
        }
    };
    const handleOTP = (otp: any) => {
        if (otp.length === 6) {
            otpVerification({
                variables: {
                    otp: otp,
                    phone: `+${dialCode}${registerPhoneNo}`,
                    isLoggedIn: false
                }
            });
        } else {
            setOTPError(false);
        }
        setOTP(otp);
    };
    const resendOTP = () => {
        if (dialCode && registerPhoneNo) {
            getOTP(
                {
                    variables: {
                        phone: `+${dialCode}${registerPhoneNo}`,
                        isPhoneCheck: false
                    },
                }
            );
            setResendDisabled(true);
            setTimeout(() => {
                setResendDisabled(false);
            }, 60000);
        }
    };

    useEffect(() => {
        if (dataOTP && dataOTP.requestOTP && dataOTP.requestOTP.status === 'SUCCESS') {
            if (current === 0) {
                setCurrent(current + 1);
            }
        } else if (errorOTP) {
            if (errorOTP.message?.indexOf('PHONE_EXIST') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'DUPLICATE_ACCOUNT_MESSAGE' }));
            } else if (errorOTP.message?.indexOf('ACCOUNT_NOT_EXISTED') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'ACCOUNT_NOT_EXISTED' }));
            } else {
                setErrorNotification(intl.formatMessage({ id: 'FREQUENT_ACTION_PHONE' }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOTP, errorOTP]);

    useEffect(() => {
        if (current === 1) {
            setTimeout(() => {
                setResendDisabled(false);
            }, 60000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    useEffect(() => {
        if (resetPasswordData && resetPasswordData.resetPassword && resetPasswordData.resetPassword.status === 'SUCCESS') {
            setSuccessNotification(intl.formatMessage({ id: 'PASSWORD_RESET_SUCCESSFUL' }));
            navigate("/auth/login")
        } else if (resetPasswordError) {
            setErrorNotification(intl.formatMessage({ id: 'PASSWORD_RESET_FAILED' }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetPasswordData, resetPasswordError]);

    useEffect(() => {
        if (otpData && otpData.otpVerification && otpData.otpVerification.status === 'SUCCESS') {
            // TODO: Pending loading and verified sign
            setOTPError(false);
            setCurrent(current + 1);
        } else if (otpError) {
            setOTPError(true);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otpData, otpError]);

    const steps = [
        {
            title: 'Mobile',
            content: <>
                <div className="auth-container  auth-signup">
                    <div className="auth-title">
                        <FormattedMessage id="PASSWORD_RESET" />
                        <div className="sm-title">
                            <FormattedMessage id="PASSWORD_RESET_VERIFICATION" />
                        </div>
                    </div>

                    <Form
                        name="forgot_pwd"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "VALIDATION_REQUIRED" }),
                                },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder={intl.formatMessage({ id: "PHONE" })} addonBefore={CountryCode({ countryCode, setCountryCode, setDialCode })} />
                        </Form.Item>
                        <Form.Item>
                            <div className="reset-button-bar">
                                <div style={{ width: '100%', marginRight: 10 }}>
                                    <Button
                                        type="default"
                                        className="pwd-cancel-button"
                                        onClick={() => navigate('/auth/login')}
                                    >
                                        <FormattedMessage id="BTN_CANCEL" />
                                    </Button>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="pwd-submit-button"
                                    >
                                        <FormattedMessage id="BTN_SUBMIT" />
                                    </Button>
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </>,
        },
        {
            title: 'OTP',
            content: <>
                <div className="auth-container auth-signup">
                    <div className="auth-title">
                        <FormattedMessage id="VERIFICATION" />
                        <div className="sm-title">
                            <FormattedMessage id="VERIFICATION_SUBTITLE" /> +{dialCode}-{registerPhoneNo}
                        </div>
                    </div>
                    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <OtpInput
                            containerStyle={
                                {
                                justifyContent: "center"
                                }
                            }
                            inputStyle={
                                {
                                    width: '2.5rem',
                                    height: '3rem',
                                    margin: '5px',
                                    fontSize: '1.5rem',
                                }
                            }
                            errorStyle={
                                {
                                    borderColor: '#EE4B2B'
                                }
                            }
                            hasErrored={isOTPError}
                            value={OTP}
                            onChange={handleOTP}
                            numInputs={6}
                            separator={<span>-</span>}
                            isInputNum={true}
                        />
                        <div style={{ paddingTop: 15, paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
                            <div><FormattedMessage id="OTP_HELP"></FormattedMessage>
                            </div>
                            <div>
                                <Button onClick={resendOTP} disabled={isResendDisabled} type="link">
                                    <FormattedMessage id="OTP_RESEND"></FormattedMessage>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>,
        },
        {
            title: 'Password',
            content: <>
                <div className="auth-container auth-signup">
                    <div className="auth-title">
                        <FormattedMessage id="CHANGE_PASSWORD_TITLE" />
                        <div className="sm-title">
                            <FormattedMessage id="CHANGE_PASSWORD_SUBTITLE" />
                        </div>
                    </div>
                    <Form
                        layout="vertical"
                        name="password-form"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    pattern: pwdRegex, message: intl.formatMessage({ id: 'INVALID_PWD' })
                                },
                                { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                                { max: 100, message: intl.formatMessage({ id: 'MAX_PASSWORD_LENGTH' }) },
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "PASSWORD_REQUIRED" }),
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password
                                autoComplete="false"
                                type="password"
                                placeholder={intl.formatMessage({ id: "NEW_PASSWORD_TITLE" })}
                            />
                        </Form.Item>
                        <Form.Item
                            dependencies={['password']}
                            hasFeedback
                            name="confirmPassword"
                            rules={[
                                {
                                    pattern: pwdRegex, message: intl.formatMessage({ id: 'INVALID_PWD' })
                                },
                                { min: 8, message: intl.formatMessage({ id: 'MIN_LENGTH' }) },
                                { max: 100, message: intl.formatMessage({ id: 'MAX_PASSWORD_LENGTH' }) },
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "CONFIRM_PASSWORD_REQUIRED" }),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(intl.formatMessage({ id: "PASSWORD_NOT_MATCH" })));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                autoComplete="false"
                                type="password"
                                placeholder={intl.formatMessage({ id: "NEW_CONFIRM_PASSWORD_PLACEHOLDER" })}
                            />
                        </Form.Item>
                        <Card className="rule-box">
                            <div style={{ paddingRight: 5, color: '#1890FF' }}>
                                <InfoCircleFilled />
                            </div>
                            <div>
                                <FormattedMessage id="PWD_RULES" />
                            </div>
                        </Card>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                <FormattedMessage id="BTN_SUBMIT" />
                            </Button>
                        </Form.Item>
                    </Form>

                </div>
            </>,
        },
    ];

    return (
        <>
            <div>{steps[current]?.content}</div>
            <ConfirmationDialog showModal={showModal} title={intl.formatMessage({ id: 'MODAL_CONFIRM' })}
                confirmationMessage={intl.formatMessage({ id: 'PASSWORD_RESET_SUBMIT' })}
                setShowModal={setShowModal}
                action={() =>
                    resetPassword(
                        {
                            variables: resetPasswordBody,
                        }
                    )
                } />
        </>
    );
};

export default ForgotPassword;

import { gql } from "@apollo/client";

export const GET_CARD_BALANCE = gql`
  query CardBalance {
    cardBalance {
      id
      cardBalance
      netAssetValue
      totalSpending
    }
  }
`;

export const GET_WALLETS_BALANCE = gql`
  query WalletsBalance($id: String!) {
    walletsBalance(id: $id) {
      cardWallet {
        userId
        balance
        usdtAddress
      }
      cashWallet {
        userId
        balance
        usdtAddress
      }
    }
  }
`;

export const GET_CARD_TRANSACTIONS = gql`
  query CardTransactions($pageSize: Int) {
    cardTransactions(pageSize: $pageSize) {
      total
      txnList{
        id
        description
        date
        amount
        currency
        status
        accountType
        foreignAmount
        foreignCurrency
      }
    }
  }
`;

export const CARD_TOP_UP = gql`
  mutation CardTopUp($source: String!, $amount: Float!) {
    cardTopUp(source: $source, amount: $amount) {
      status
    }
  }
`;

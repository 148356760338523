import { Divider, Menu, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./SideNavContent.less";
import { ApartmentOutlined, BarcodeOutlined, HomeOutlined, LogoutOutlined, UserOutlined, WalletOutlined, MailOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { clearAllTokens } from "../../../../shared/helpers/set-token-cookie.helper";
import { CLEAR_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from "../../../../hooks/auth/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import { LOGOUT } from "../../../auth/Auth.gql";
import { client } from "../../../../apollo-client";
import packageJson from '../../../../../package.json';
import { GET_CONTACT_US } from "./SideNavContent.gql";
import { find } from "lodash";
type MenuItem = Required<MenuProps>['items'][number];

interface Props {
    collapsed: boolean;
    setCollapsed: any;
}

const SideNavContent = ({ collapsed, setCollapsed }: Props) => {
    const location: any = useLocation();
    const navigate = useNavigate();
    const { pathname } = location || { pathname: '/' };
    const [selectedKeys, setSelectedKeys] = useState<string[]>(['/']);
    const [subMenuKey, setSubMenuKey] = useState<string[]>();
    const { authState, authDispatch } = useAuth();
    const [logout, { data, error }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "ignore" });
    const { data: contactUsData } = useQuery(GET_CONTACT_US, { fetchPolicy: "cache-and-network" });
    const [supportPhone, setSupportPhone] = useState('');
    const [supportEmail, setSupportEmail] = useState('');

    const screenWidth = window.outerWidth;
    const logOut = () => {
        logout();
    }

    useEffect(() => {
        if (data || error) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS });
            if (client) {
                client.cache.reset();
            }
            navigate("/auth");
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error])

    useEffect(() => {
        if (contactUsData && contactUsData.contactUs) {
            const _supportEmail = find(contactUsData.contactUs, { id: 'burse_support_email' });
            const _supportPhone = find(contactUsData.contactUs, { id: 'burse_support_phone' });
            setSupportEmail(_supportEmail && _supportEmail.value ? _supportEmail.value : '-');
            setSupportPhone(_supportPhone && _supportPhone.value ? _supportPhone.value : '-');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactUsData])

    useEffect(() => {
        let keys: string[] = location.pathname.split("/");
        keys = keys.length >= 3 ? [`/${keys[1]}`, `/${keys[1]}/${keys[2]}`] : [`/${keys[1]}`]
        setSelectedKeys(keys);
        if (screenWidth > 768) { setSubMenuKey([keys[0]]); }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        onClick?: any,
        children?: MenuItem[],
        disabled: boolean = false,
        type?: 'group',

    ): MenuItem => {
        return {
            key,
            icon,
            children,
            onClick,
            label,
            type,
            disabled
        } as MenuItem;
    }
    const items: MenuProps['items'] = [
        getItem(<Link to="/"><FormattedMessage id="HOME" /></Link>, 'HOME', <HomeOutlined />),
        getItem(<FormattedMessage id="MY_WALLETS" />, 'MY_WALLETS', <WalletOutlined />, null, [
            getItem(<Link to={"/e-wallet/overview"}><FormattedMessage id="E_WALLET" /></Link>, 'E_WALLET'),
            getItem(<Link to={"/card/overview"}><FormattedMessage id="CARD" /></Link>, 'CARD'),
        ]),
        getItem(<FormattedMessage id="HISTORY" />, 'HISTORY', <BarcodeOutlined />, null, [
            getItem(<Link to={"/e-wallet-transaction-history"}><FormattedMessage id="CASH_WALLET_TRX_HISTORY" /></Link>, 'CASH_WALLET_TRX_HISTORY'),
            getItem(<Link to={"/card-transaction-history"}><FormattedMessage id="CARD_TRX_HISTORY" /></Link>, 'CARD_TRX_HISTORY'),
            getItem(<Link to={"/transfer-transaction-history"}><FormattedMessage id="TRANSFER_TRX_HISTORY" /></Link>, 'TRANSFER_TRX_HISTORY'),
        ]),
        authState && authState.agent && authState.agent?.id ? getItem(<FormattedMessage id="AGENT" />, 'AGENT', <ApartmentOutlined />, null, [
            getItem(<Link to={"/agent"}><FormattedMessage id="AGENT_DOWNLINES" /></Link>, 'AGENT_DOWNLINES'),
            getItem(<Link to={"/agent-commissions"}><FormattedMessage id="AGENT_COMMISSIONS" /></Link>, 'AGENT_COMMISSIONS'),
            getItem(<Link to={"/agent-payout"}><FormattedMessage id="AGENT_PAYOUT" /></Link>, 'AGENT_PAYOUT'),
        ]):null,
        getItem(<FormattedMessage id="MY_ACCOUNT" />, 'MY_ACCOUNT', <UserOutlined />, null, [
            getItem(<Link to={"/kyc"}><FormattedMessage id="KYC" /></Link>, 'KYC'),
            getItem(<Link to={"/my-profile"}><FormattedMessage id="MY_PROFILE" /></Link>, 'MY_PROFILE'),
            getItem(<Link to={"/export-account"}><FormattedMessage id="EXPORT_ACCOUNT" /></Link>, 'EXPORT_ACCOUNT'),
            getItem(<Link to={"/change-password"}><FormattedMessage id="CHANGE_PASSWORD" /></Link>, 'CHANGE_PASSWORD'),
        ]),
        getItem(
            <FormattedMessage id="LOG_OUT" />, 'LOG_OUT', <LogoutOutlined />, logOut),
    ];

    const handleSubMenuClick = (e: any) => {
        if (e && e.length > 1) {
            setSubMenuKey([e[1]]);
        } else if (e && e.length > 0) {
            setSubMenuKey([e[0]]);
        } else if (screenWidth <= 768 && e && e.length === 0) {
            setSubMenuKey(['/']);
        } else if (e.length === 0) {
            setSubMenuKey(['/']);
        }
    };

    const handleMenuItemClick = () => {
        // if (screenWidth <= 768) {
        setCollapsed(true);
        //}
    }

    const renderCustomerService = () => {
        return (
            <div className={collapsed ? "contact-us-container-collapsed" : "contact-us-container"}>
                <Divider />
                {collapsed ? (screenWidth < 769 ? null : (<div className="contact-us-collapsed">
                    <MailOutlined className="contact-us-collapsed-icon"
                        onClick={(e) => {
                            window.location.href = `mailto:${supportEmail}`;
                            e.preventDefault();
                        }} />
                    <WhatsAppOutlined className="contact-us-collapsed-icon" onClick={(e) => {
                        window.location.href = `https://wa.me/${supportPhone ? supportPhone.replace('+', '').replace('-', '') : ''}`;
                        e.preventDefault();
                    }} />
                    <div className="version">
                        v {packageJson.version}
                    </div>
                </div>)) : (
                    <div className="contact-us-wrapper">
                        <div className="contact-us-title">
                            <FormattedMessage id="CONTACT_US" />
                        </div>
                        <div><MailOutlined className="contact-us-icon" />
                            <a href={`mailto:${supportEmail}`}>
                                {supportEmail}
                            </a>
                        </div>
                        <div><WhatsAppOutlined className="contact-us-icon" />
                            <a href={`https://wa.me/${supportPhone ? supportPhone.replace('+', '').replace('-', '') : ''}`}>
                                {supportPhone}
                            </a>
                        </div>
                        <div className="version">
                            v {packageJson.version}
                        </div>
                    </div>

                )}
            </div>
        );
    }

    return (
        <>
            <Menu
                className="side-menu-theme"
                mode="inline"
                selectedKeys={selectedKeys}
                openKeys={collapsed ? undefined : subMenuKey}
                onOpenChange={handleSubMenuClick}
                defaultSelectedKeys={['/']}
                // inlineCollapsed={screenWidth < 768 ? false : true}
                onClick={handleMenuItemClick}
                items={items}
                theme={"dark"}
            /> {renderCustomerService()}
        </>
    );
}

export default SideNavContent;
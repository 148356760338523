import { CheckCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/auth/AuthContext';
import useNotification from '../../../../hooks/layout/useNotification';
import { GET_CONFIGURATION } from '../../../../shared/gql/configuration.gql';
import { NumberFormatter } from '../../../../shared/helpers/number-format.helper';
import PhoneVerifyModal from '../../../layouts/phone-verify-modal/PhoneVerifyModal';
import { TRANSFER_OUT } from '../CashWallet.gql';
import './TransferOutConfirmation.less';
import { GET_ESTIMATED_GAS_FEE } from '../../../../shared/gql/estimated-gas-fee.gql';

const TransferOutConfirmation = () => {
    const intl = useIntl();
    const { setErrorNotification, setSuccessNotification } = useNotification();
    const { authState } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [visible, setVisible] = useState(false);
    const [transferOutFlag, setTransferOutFlag] = useState<boolean>(false);
    const { state }: any = location;
    const { data } = useQuery(GET_CONFIGURATION, {
        fetchPolicy: "cache-and-network", variables: {
            id: "burse_transfer_charges"
        }
    });
    const [getEstimatedGasFee, { data:estimatedGasFeeData, loading:estimatedGasFeeLoading }]= useLazyQuery(GET_ESTIMATED_GAS_FEE, { fetchPolicy: "no-cache", errorPolicy: "all", });
    const [transferOutData, setTransferOutData] = useState<any>();
    const [transferOut, { data: transferData, error: transferError, loading: transferLoading }] = useMutation(TRANSFER_OUT, {
        errorPolicy: "all"
    });
    const [transferCharges, setTransferCharges]= useState<number>(0);
    const excludeOutwardCharges = authState && authState.userProfile && authState.userProfile?.VipMember && authState.userProfile.VipMember?.excludeOutwardCharges;
    const [estimatedGasFee, setEstimatedGasFee]= useState<number>();

    useEffect(() => {
        if (data && data.configuration && data.configuration.value) {
            setTransferCharges(+data.configuration.value);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (transferData && transferData.transferOut && transferData.transferOut.status &&
            transferData.transferOut.status === 'SUCCESS') {
            setTransferOutFlag(true);
            setSuccessNotification(intl.formatMessage({ id: 'TRANSFER_OUT_SUCCESSFUL' }, {address: transferOutData.trcAddress}));
        } else if (transferError) {
            if (transferError.message?.indexOf('INVALID_ADDRESS') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'INVALID_ADDRESS' }));
            } else if (transferError.message?.indexOf('ACCOUNT_NOT_EXISTED') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'ACCOUNT_NOT_EXISTED' }));
            } else if (transferError.message?.indexOf('INVALID_OTP') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'INVALID_OTP_TOKEN_TRANSFER' }));
            } else if (transferError.message?.indexOf('INSUFFICIENT_AMOUNT') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'INSUFFICIENT_AMOUNT' }));
            } else if (transferError.message?.indexOf('FREQUENT_ACTION') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'FREQUENT_ACTION_TRANSFER' }));
            } else if (transferError.message?.indexOf('DATA_EXISTED') > -1) {
                setErrorNotification(intl.formatMessage({ id: 'DATA_EXISTED_TRANSFER' }));
            } else {
                setErrorNotification(intl.formatMessage({ id: 'TRANSFER_OUT_FAILURE' }));
            }
            setTransferOutFlag(false);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferData, transferError]);

    useEffect(() => {
        if (state) {
            setTransferOutData(state);
            getEstimatedGasFee({
                variables: {
                    queryInput:{
                        toAddress:state.trcAddress,
                        amount:+state?.amount 
                    }
                }
            })
        } else {
            navigate("/e-wallet/transfer-out");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(()=>{
        if(estimatedGasFeeData && estimatedGasFeeData.estimatedGasFee && estimatedGasFeeData.estimatedGasFee.estimatedGasFee){
            setEstimatedGasFee(+estimatedGasFeeData.estimatedGasFee.estimatedGasFee);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    },[estimatedGasFeeData]);

    const navigateFunc = ()=>{
        navigate("/e-wallet/overview");
    }

    const confirmation =(otp: string) => {
        if (otp && transferOutData && +transferOutData.amount && 
            transferOutData.trcAddress && transferOutData.walletId ) {
            transferOut({
                variables: {
                    transferOutInput: {
                        otp,
                        walletId: transferOutData.walletId,
                        trcAddress: transferOutData.trcAddress,
                        amount: +transferOutData.amount
                    }
                }
            });
        }
    }

    const actions = () => {
        setVisible(true);
    };

    return (
        <>
            <div className="transfer-out-confirmation-container">
                <div className="form-box">
                    <div className="top-up-title">
                        <h2><FormattedMessage id= {transferOutFlag  ? "SUCCESSFUL":"CONFIRMATION" }/> </h2>
                        {transferOutFlag && <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor="#52c41a"/>}
                    </div>
                    <div>
                        <h4><FormattedMessage id="DETAILS" /></h4>
                        <Statistic title={<FormattedMessage id="TRANSFER_AMT" />} style={{fontWeight:'bold',margin:0}} value={
                            transferOutData && transferOutData.amount ? NumberFormatter(transferOutData.amount, 'en-EN', 'USD') : '0.00'}/>
                        <Statistic title={<FormattedMessage id="OUTWARDS_TRANSFERRING_CHARGES" />} value={
                            transferOutData && transferOutData.amount ? NumberFormatter(excludeOutwardCharges ? 0 : transferCharges, 'en-EN', 'USD') : '0.00'} />
                        <Statistic title={<FormattedMessage id="NETT_AMOUNT" />} value={
                            transferOutData && transferOutData.amount ? NumberFormatter(excludeOutwardCharges ? transferOutData.amount : transferOutData.amount - transferCharges, 'en-EN', 'USD') : '0.00'} />
                        { authState && authState.userProfile?.isExported && <Statistic title={<FormattedMessage id="ESTIMATED_GAS_FEE" />} value={estimatedGasFee} suffix={"TRX"} precision={6} loading={estimatedGasFeeLoading} className="trx-statistic"/>}
                    </div>
                    <Divider dashed style={{ borderColor: '#D3D3D3', borderWidth: '2px 0 0' }} />
                    <div className="transfer-info-wrap">
                        <span>{!transferOutFlag && <FormattedMessage id="FROM" />}</span>
                        <div className="transfer-info-right">
                            <span><FormattedMessage id="E_WALLET" /></span>
                            <span style={{ marginTop: 10 }}><FormattedMessage id="BALANCE" />: {
                                transferOutData && transferOutData.amount ? NumberFormatter(
                                    transferOutFlag ? +transferOutData.cashWalletBal - (transferOutData.amount): transferOutData.cashWalletBal, 'en-EN', 'USD') : '0.00'}</span>
                        </div>
                    </div>
                    <Divider dashed style={{ borderColor: '#D3D3D3', borderWidth: '2px 0 0' }} />
                    {!transferOutFlag &&  <h4 style={{marginBottom:15}}><InfoCircleOutlined /> <FormattedMessage id="TRANSFER_CHARGE_INFO" values={{transferFee: transferCharges.toFixed(2)}}/></h4>}
                    <Button loading={transferLoading} className="action-button" type="primary" block onClick={transferOutFlag ? navigateFunc: actions}>
                        <div><FormattedMessage id={transferOutFlag ? "BTN_DONE": "BTN_CONFIRM"} /></div>
                    </Button>

                </div>
            </div>
            <PhoneVerifyModal title={intl.formatMessage({ id: "VERIFICATION" })}
                content={`${intl.formatMessage({ id: "VERIFICATION_SUBTITLE" })} ${authState?.userProfile?.phone}`} 
                visible={visible} setVisible={setVisible}
                onComplete={confirmation}/>
        </>
    )
}

export default TransferOutConfirmation;
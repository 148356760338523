import { useLazyQuery } from "@apollo/client";
import { Button, Card, Empty, List, Skeleton, Tag, Spin } from "antd";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { UPDATE_CARD_ACCS, useAuth } from "../../../../hooks/auth/AuthContext";
import { NumberFormatter } from "../../../../shared/helpers/number-format.helper";
import DepositIcon from '../../../../assets/icons/deposit_icon.svg';
import CreditIcon from '../../../../assets/icons/credit_icon.svg';
import { GET_CARD_TRANSACTIONS } from "../Card.gql";
import { useNavigate } from "react-router-dom";
import "./CardTransactions.less";
import { GET_PLATFORM_PROFILE } from "../../../../shared/gql/profile.gql";
import TopUpCashColor from '../../../../assets/icons/topup-cash-color.svg';
import TopUpVISAColor from '../../../../assets/icons/topup-visa-color.svg';
import TransferOut from '../../../../assets/icons/transfer-out.svg';

const CardTransactions = () => {
    const { authState, authDispatch } = useAuth();
    const navigate = useNavigate();
    const [transactionList, setTransactionList] = useState([]);
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const [isEmpty, setIsEmpty] = useState(Boolean);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [getPlatformProfile, { data: platformData }] = useLazyQuery(
        GET_PLATFORM_PROFILE, { fetchPolicy: "no-cache", errorPolicy: "all", }
    );
    const [getCardData, { data, loading, called, refetch }] = useLazyQuery(
        GET_CARD_TRANSACTIONS,
        {
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
        }
    );

    useEffect(() => {
        if (authState.cardAccount && authState.cardAccount?.cards && authState.cardAccount?.cards?.length > 0 && (called === false)) {
            getCardData(
                {
                    variables: {
                        pageSize: 1
                    }
                }
            );
        } else if (authState.cardAccount && (authState.cardAccount?.isLoading === false) && (!authState.cardAccount?.cards) && (called === false)) {
            setIsEmpty(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.cardAccount]);

    useEffect(() => {
        if (loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (data && data.cardTransactions && data.cardTransactions?.txnList.length !== 0) {
            const list = data.cardTransactions?.txnList.map((val: any) => {
                return {
                    ...val,
                    key: val.id
                };
            });
            setTransactionList(list);
            if (list.length > 0) {
                setIsEmpty(false);
            } else {
                setIsEmpty(true);
            }
        }
    }, [data]);

    useEffect(() => {
        if (platformData && platformData.length !== 0) {
            authDispatch({
                type: UPDATE_CARD_ACCS,
                payload: {
                    cardAccount: { ...authState.cardAccount, netAssetValue: platformData?.platformProfile?.netAssetValue, totalSpending: platformData?.platformProfile?.totalSpending, totalCardBalance: platformData?.platformProfile?.totalCardBalance, cards: platformData?.platformProfile?.cardAccount }
                }
            });
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformData]);

    const walletAction = (type: any) => {
        if (type === 'receive') {
          navigate("/e-wallet/deposit")
        } else if (type === 'reload') {
          navigate("/card/top-up")
        } else if (type === 'send') {
          navigate("/e-wallet/transfer-out")
        } 
    };

    const refreshList = () => {
        refetch();
        setRefreshDisabled(true);
        getPlatformProfile();
        setTimeout(() => {
            setRefreshDisabled(false);
        }, 5000);
    };

    const loadMore =
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button onClick={() => navigate("/card-transaction-history")}><FormattedMessage id="FULL_HISTORY" /></Button>
        </div>;

    return (
        <>
            <div id="card-top-nav-scroll" className="cash-wallet-top-nav">
                <Card hoverable bordered={false} onClick={() => { walletAction('reload') }} className="top-nav-card">
                <img alt="topup-visa-color" className="card-menu-button" src={TopUpVISAColor} />
                <div className="top-nav-content">
                    <div><FormattedMessage id="RELOAD" /></div>
                </div>
                </Card>
                <Card hoverable bordered={false} onClick={() => { walletAction('send') }} className="top-nav-card">
                    <img alt="topup-cash-color" className="card-menu-button" src={TransferOut} />
                    <div className="top-nav-content">
                        <div><FormattedMessage id="SEND" /></div>
                    </div>
                </Card>
                <Card hoverable bordered={false} onClick={() => { walletAction('receive') }} className="top-nav-card">
                <img alt="topup-cash-color" className="card-menu-button" src={TopUpCashColor} />
                <div className="top-nav-content">
                    <div><FormattedMessage id="RECEIVE" /></div>
                </div>
                </Card>
            </div>
            <div className="transaction-box">
                <div className="title-bar">
                    <h2><FormattedMessage id="RECENT_TRANSACTION" /></h2>
                    <Button type="primary" onClick={refreshList} loading={refreshDisabled} style={{ backgroundColor: '#13C2C2' }}
                        disabled={refreshDisabled}><FormattedMessage id="REFRESH" /></Button>
                </div>
                <Card size="small">
                    <div className="txn-list">
                        {
                            transactionList && transactionList.length > 0 ?
                                <List
                                    itemLayout="horizontal"
                                    loadMore={loadMore}
                                    dataSource={transactionList}
                                    renderItem={(item: any) => (
                                        <Skeleton avatar title={false} loading={called ? isLoading : true} active className="list-skele-large">
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<img alt="depositIcon" className="icon-style" src={item["accountType"] !== "DEBIT" ? CreditIcon : DepositIcon} />}
                                                    title={<><span className="credit-label">{item["accountType"] !== "DEBIT" && "[CR] "}</span>{item.description}</>}
                                                    description={
                                                        <>
                                                            <Tag color={item['status'] === 'SUCCESS' ? 'green' : 'blue'}><FormattedMessage id={item['status']} /></Tag>
                                                            <span className="txn-desc">{format(new Date(item['date']), "yyyy-MM-dd, h:mm a")}</span>
                                                        </>
                                                    }
                                                />
                                                <hr className="list-hr" />
                                                <div className="info-span">
                                                <span className={item["accountType"] === "DEBIT" ? "txn-currency-debit":"txn-currency-credit"}>{ item["accountType"] === "DEBIT" ? "" : "+"}{NumberFormatter(item['amount'], 'en-EN', item['currency'])}</span>
                                                    { item["accountType"] === "DEBIT" && item["foreignCurrency"] && <span className="txn-2nd-currency">{NumberFormatter(item['foreignAmount'], 'en-EN', item['foreignCurrency'])}</span> }
                                                </div>
                                            </List.Item>
                                        </Skeleton>
                                    )}
                                />
                                :
                                <div className="empty-box">
                                    {
                                        isEmpty === true ?
                                            <Empty />
                                            :
                                            <Spin spinning={true}></Spin>
                                    }
                                </div>
                        }
                    </div>
                </Card>
            </div>
        </>
    )

};

export default CardTransactions;
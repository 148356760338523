import { Form, Button, Input } from "antd";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
    onFinish: (values: any) => void;
    backStep: (step: any) => void;
};

const SignUpProfile = ({ onFinish, backStep }: Props) => {
    const intl = useIntl();

    return (
        <>
            <div className="auth-container auth-signup">
                <div className="auth-title">
                    <FormattedMessage id="SIGNUP_PROFILE_TITLE" />
                    <div className="sm-title">
                        <FormattedMessage id="SIGNUP_PROFILE_SUBTITLE" />
                    </div>
                </div>
                <Form
                    name="personal-info"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="firstName"
                        label={<FormattedMessage id="FIRST_NAME" />}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'VALIDATION_REQUIRED' }) },
                        ]}
                    >
                        <Input maxLength={255} placeholder={intl.formatMessage({ id: 'FIRST_NAME' })} />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label={<FormattedMessage id="LAST_NAME" />}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'VALIDATION_REQUIRED' }) },
                        ]}
                    >
                        <Input maxLength={255} placeholder={intl.formatMessage({ id: 'LAST_NAME' })} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={<FormattedMessage id="EMAIL" />}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: "VALIDATION_REQUIRED" }),
                            },
                            { type: "email", message: intl.formatMessage({ id: "VALIDATION_EMAIL" }) }
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: 'EMAIL' })}></Input>
                    </Form.Item>
                    <Form.Item >
                        <div className="info-form-button-wrap">
                            <Button
                                type="primary"
                                className="info-form-cancel-button"
                                onClick={() => backStep(0)}
                            >
                                <FormattedMessage id="BTN_CANCEL" />
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="info-form-button"
                            >
                                <FormattedMessage id="BTN_NEXT" />
                            </Button>
                        </div>
                    </Form.Item>

                </Form>
            </div>
        </>
    )
};

export default SignUpProfile
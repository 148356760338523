import { Dropdown, MenuProps, Select } from "antd";
import { SET_LANGUAGE, useLayout } from "../../../hooks/layout/LayoutContext";
import './LanguageSwitcher.less';
import { GlobalOutlined } from "@ant-design/icons";

const LanguageSwitcher = () => {
    const { layoutDispatch } = useLayout();

    const setLocale = (selectedValue: string, option: any) => {
        localStorage.setItem('pfxl', option.value);
        layoutDispatch({ type: SET_LANGUAGE, payload: option.value });
    }

    const selectionItems = [
        {
            label: 'English',
            key: 'en',
        },
        {
            label: '简体中文',
            key: 'zh-CN',
        }
    ]

    const items: MenuProps['items'] = selectionItems;

    const handleMenuClick: MenuProps['onClick'] = (e: any) => {
        setLocale(e.key, { value: e.key });
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <> 
            {window.innerWidth > 768 ?
                <div className="language-switcher-container">
                    <Select
                        className="language-dropdown"
                        popupClassName="language-select-dropdown"
                        defaultValue={localStorage.getItem('pfxl') ? localStorage.getItem('pfxl')?.toString() : "en"}
                        onChange={setLocale}>
                        { selectionItems.map((val: any) => {
                            return (
                                <Select.Option value={val?.key} key={val?.key}>
                                    { val?.label }
                                </Select.Option>
                            )
                        })}
                    </Select>
                </div> :
                <div>
                    <Dropdown className="language-dropdown-selection" menu={menuProps}>
                        <GlobalOutlined />
                    </Dropdown>
                </div>   
            }
        </>
    )
}

export default LanguageSwitcher;
import { gql } from "@apollo/client";

export const GET_KYC_INFO = gql`
query getKycInfo {
    userKyc {
        name,
        dateOfBirth,
        kycCategory,
        passportNo,
        address1,
        address2,
        city,
        postcode,
        state,
        annualIncome,
        companyName,
        employmentStatus,
        jobTitle,
        natureOfBusiness,
        passportUrl,
        faceWithPassportUrl,
        documentUrl,
        remarks,
        status,
    }
}`

export const CREATE_KYC = gql`
mutation createKYC( $kycInput: UserKycInput!){
    userKYC( kycInput:$kycInput ){ 
        status
        id
    }
}`

export const CREATE_APPLICATION = gql`
mutation createApplication( $kycInput: UserKycApplicationInput!){
    userApplication( kycInput:$kycInput ){ 
        status
        id
        message
    }
}`

export const GET_APPLICATION_INFO = gql`
query getApplicationInfo {
    userApplication {
        id,
        userId,
        fee,
        documentUrl,
        remarks,
        createdAt,
        usdtMainWalletAddress
    }
}`

export const GET_APPLICATION_WALLET_ADDRESS = gql`
query getWalletAddress {
    walletAddress {
        applicationWalletAddress
    }
}`